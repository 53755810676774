import React from "react";
import { Link } from "react-router-dom";
import { Frame36 } from "../../components/Frame36";
import { Grayline } from "../../components/Grayline";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Polygon } from "../../components/Polygon";
import "./style.css";

export const RecruitScreen = () => {
  return (
    <div className="recruit-screen">
      <div className="div-24">
        <div className="logo-5">
          <div className="frame-327">
            <div className="group-52" />
          </div>
          <img className="menu-14" alt="Menu" src="/img/menu-12.svg" />
        </div>
        <div className="midashi-9">
          <div className="component-2">
            <div className="text-wrapper-428">採用情報</div>
            <div className="TEXTEXT-2">RECRUIT</div>
          </div>
        </div>
        <div className="frame-328">
          <div className="group-53">
            <Link className="text-wrapper-429" to="/home-744">
              TOP
            </Link>
            <ImgWrapper className="component-10930-5" img="/img/10.svg" />
            <div className="text-wrapper-430">ブログ</div>
          </div>
        </div>
      </div>
      <div className="view-6">
        <div className="frame-329">
          <div className="frame-330">
            <div className="frame-331">
              <div className="frame-332">
                <div className="group-54">
                  <div className="text-wrapper-431">2024/00/00</div>
                </div>
                <div className="frame-333">
                  <div className="text-wrapper-432">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                  <div className="text-wrapper-433">♯タグ</div>
                </div>
              </div>
              <p className="div-25">
                <span className="text-wrapper-434">見出し</span>
                <span className="text-wrapper-435">
                  見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し
                </span>
              </p>
              <Grayline className="grayline-6" />
              <div className="frame-334">
                <div className="frame-335">
                  <NoimageWrapper className="noimage-8" noimage="/img/noimage-33.svg" />
                </div>
                <div className="frame-336">
                  <NoimageWrapper className="noimage-8" noimage="/img/noimage-34.svg" />
                </div>
              </div>
              <div className="text-wrapper-436">
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </div>
            </div>
            <div className="frame-337">
              <div className="group-55">
                <div className="text-wrapper-437">前の記事へ</div>
                <Polygon className="polygon-5" element="/img/polygon-2-15.svg" one="one" />
              </div>
              <Frame36 className="frame-338" divClassName="frame-339" one="one" />
              <div className="group-56">
                <div className="text-wrapper-438">次の記事へ</div>
                <Polygon className="polygon-6" element="/img/polygon-2-16.svg" one="one" />
              </div>
            </div>
          </div>
          <div className="div-24">
            <div className="frame-340">
              <div className="text-wrapper-439">カテゴリ</div>
            </div>
            <div className="frame-341">
              <div className="frame-342">
                <div className="text-wrapper-440">カテゴリ名</div>
                <Polygon className="polygon-7" element="/img/polygon-2-17.svg" one="one" />
              </div>
              <img className="line-18" alt="Line" src="/img/line-18-1.svg" />
              <div className="frame-342">
                <div className="text-wrapper-440">カテゴリ名</div>
                <Polygon className="polygon-7" element="/img/polygon-2-17.svg" one="one" />
              </div>
              <img className="line-18" alt="Line" src="/img/line-18-1.svg" />
              <div className="frame-342">
                <div className="text-wrapper-440">カテゴリ名</div>
                <Polygon className="polygon-7" element="/img/polygon-2-17.svg" one="one" />
              </div>
              <img className="line-18" alt="Line" src="/img/line-18-1.svg" />
              <div className="frame-342">
                <div className="text-wrapper-440">カテゴリ名</div>
                <Polygon className="polygon-7" element="/img/polygon-2-17.svg" one="one" />
              </div>
              <img className="line-18" alt="Line" src="/img/line-18-1.svg" />
              <div className="frame-343">
                <div className="text-wrapper-440">カテゴリ名</div>
                <Polygon className="polygon-7" element="/img/polygon-2-17.svg" one="one" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-344">
        <div className="group-57">
          <div className="frame-345">
            <div className="group-58">
              <div className="text-wrapper-441">お問い合わせ</div>
              <div className="text-wrapper-442">CONTACT</div>
            </div>
            <div className="group-59">
              <div className="flexcontainer-45">
                <p className="text-31">
                  <span className="text-wrapper-443">
                    制作のご依頼・ご相談・ご質問など
                    <br />
                  </span>
                </p>
                <p className="text-31">
                  <span className="text-wrapper-443">お気軽にお問い合わせください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-15">
        <div className="frame-346">
          <div className="frame-347">
            <LogoWhite logoWhite="/img/logo-white-11.png" logoWhiteClassName="logo-white-10" />
            <div className="flexcontainer-46">
              <p className="text-i-47">
                <span className="text-wrapper-444">
                  千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                </span>
              </p>
              <p className="text-i-47">
                <span className="text-wrapper-444">幕張ベイパーク　クロスレジデンスＳ２－１</span>
              </p>
            </div>
          </div>
          <div className="frame-348">
            <div className="frame-349">
              <div className="frame-350">
                <div className="text-wrapper-445">理念</div>
                <div className="text-wrapper-446">- 企業理念</div>
                <div className="text-wrapper-446"> - 経営理念</div>
                <div className="text-wrapper-446"> - 社訓</div>
              </div>
              <div className="frame-350">
                <div className="text-wrapper-445">会社概要</div>
                <div className="text-wrapper-446">- 代表挨拶</div>
                <div className="text-wrapper-446">- 概要</div>
              </div>
              <div className="frame-350">
                <div className="text-wrapper-445">制作について</div>
                <div className="text-wrapper-446">- 業務内容</div>
                <div className="text-wrapper-446">- ご相談の流れ</div>
              </div>
            </div>
            <div className="frame-349">
              <div className="text-wrapper-445">ブログ</div>
              <div className="text-wrapper-446">採用情報</div>
              <Link className="text-wrapper-447" to="/contact-1200">
                よくある質問
              </Link>
              <div className="text-wrapper-446">情報セキュリティ基本方針</div>
              <Link className="text-wrapper-447" to="/privacypolicy-1200">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </div>
        <div className="text-wrapper-448">©︎ Digital Puzzle Inc</div>
      </footer>
    </div>
  );
};
