/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWhite7 = ({ className }) => {
  return (
    <svg
      className={`logo-white-7 ${className}`}
      fill="none"
      height="56"
      viewBox="0 0 164 56"
      width="164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_399_2108)">
        <path
          className="path"
          d="M26.0641 32.5378C28.2283 32.5378 29.9827 30.9708 29.9827 29.0378C29.9827 27.1048 28.2283 25.5378 26.0641 25.5378C23.8999 25.5378 22.1455 27.1048 22.1455 29.0378C22.1455 30.9708 23.8999 32.5378 26.0641 32.5378Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0647 19.3479C27.8517 19.3479 29.3004 18.054 29.3004 16.4579C29.3004 14.8618 27.8517 13.5679 26.0647 13.5679C24.2778 13.5679 22.8291 14.8618 22.8291 16.4579C22.8291 18.054 24.2778 19.3479 26.0647 19.3479Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2454 25.7878C40.0324 25.7878 41.481 24.4939 41.481 22.8978C41.481 21.3017 40.0324 20.0078 38.2454 20.0078C36.4584 20.0078 35.0098 21.3017 35.0098 22.8978C35.0098 24.4939 36.4584 25.7878 38.2454 25.7878Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2454 38.3779C40.0324 38.3779 41.481 37.084 41.481 35.4879C41.481 33.8918 40.0324 32.5979 38.2454 32.5979C36.4584 32.5979 35.0098 33.8918 35.0098 35.4879C35.0098 37.084 36.4584 38.3779 38.2454 38.3779Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0647 44.5178C27.8517 44.5178 29.3004 43.2239 29.3004 41.6278C29.3004 40.0317 27.8517 38.7378 26.0647 38.7378C24.2778 38.7378 22.8291 40.0317 22.8291 41.6278C22.8291 43.2239 24.2778 44.5178 26.0647 44.5178Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.8831 25.7878C15.6701 25.7878 17.1187 24.4939 17.1187 22.8978C17.1187 21.3017 15.6701 20.0078 13.8831 20.0078C12.0961 20.0078 10.6475 21.3017 10.6475 22.8978C10.6475 24.4939 12.0961 25.7878 13.8831 25.7878Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.2864 38.3779C16.0734 38.3779 17.5221 37.084 17.5221 35.4879C17.5221 33.8918 16.0734 32.5979 14.2864 32.5979C12.4994 32.5979 11.0508 33.8918 11.0508 35.4879C11.0508 37.084 12.4994 38.3779 14.2864 38.3779Z"
          fill="white"
        />
        <path
          className="path"
          d="M25.8856 5.53786C26.9615 5.53786 27.8337 4.75884 27.8337 3.79786C27.8337 2.83689 26.9615 2.05786 25.8856 2.05786C24.8097 2.05786 23.9375 2.83689 23.9375 3.79786C23.9375 4.75884 24.8097 5.53786 25.8856 5.53786Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0643 55.1779C27.1402 55.1779 28.0124 54.3989 28.0124 53.4379C28.0124 52.4769 27.1402 51.6979 26.0643 51.6979C24.9884 51.6979 24.1162 52.4769 24.1162 53.4379C24.1162 54.3989 24.9884 55.1779 26.0643 55.1779Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 18.1979C51.0885 18.1979 51.9607 17.4189 51.9607 16.4579C51.9607 15.4969 51.0885 14.7179 50.0126 14.7179C48.9366 14.7179 48.0645 15.4969 48.0645 16.4579C48.0645 17.4189 48.9366 18.1979 50.0126 18.1979Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 43.2178C51.0885 43.2178 51.9607 42.4388 51.9607 41.4778C51.9607 40.5168 51.0885 39.7378 50.0126 39.7378C48.9366 39.7378 48.0645 40.5168 48.0645 41.4778C48.0645 42.4388 48.9366 43.2178 50.0126 43.2178Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.25084 18.1979C3.32675 18.1979 4.19894 17.4189 4.19894 16.4579C4.19894 15.4969 3.32675 14.7179 2.25084 14.7179C1.17493 14.7179 0.302734 15.4969 0.302734 16.4579C0.302734 17.4189 1.17493 18.1979 2.25084 18.1979Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.38463 43.3678C3.46054 43.3678 4.33273 42.5888 4.33273 41.6278C4.33273 40.6668 3.46054 39.8878 2.38463 39.8878C1.30872 39.8878 0.436523 40.6668 0.436523 41.6278C0.436523 42.5888 1.30872 43.3678 2.38463 43.3678Z"
          fill="white"
        />
        <path
          className="path"
          d="M49.8783 30.8579C51.1212 30.8579 52.1287 29.958 52.1287 28.8479C52.1287 27.7378 51.1212 26.8379 49.8783 26.8379C48.6355 26.8379 47.6279 27.7378 47.6279 28.8479C47.6279 29.958 48.6355 30.8579 49.8783 30.8579Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2455 49.2079C39.4884 49.2079 40.4959 48.308 40.4959 47.1979C40.4959 46.0878 39.4884 45.1879 38.2455 45.1879C37.0027 45.1879 35.9951 46.0878 35.9951 47.1979C35.9951 48.308 37.0027 49.2079 38.2455 49.2079Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.2865 49.2079C15.5294 49.2079 16.5369 48.308 16.5369 47.1979C16.5369 46.0878 15.5294 45.1879 14.2865 45.1879C13.0437 45.1879 12.0361 46.0878 12.0361 47.1979C12.0361 48.308 13.0437 49.2079 14.2865 49.2079Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.8832 12.3078C15.1261 12.3078 16.1336 11.4079 16.1336 10.2978C16.1336 9.18775 15.1261 8.28784 13.8832 8.28784C12.6403 8.28784 11.6328 9.18775 11.6328 10.2978C11.6328 11.4079 12.6403 12.3078 13.8832 12.3078Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.2504 30.8579C3.49326 30.8579 4.50079 29.958 4.50079 28.8479C4.50079 27.7378 3.49326 26.8379 2.2504 26.8379C1.00754 26.8379 0 27.7378 0 28.8479C0 29.958 1.00754 30.8579 2.2504 30.8579Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2455 12.3078C39.4884 12.3078 40.4959 11.4079 40.4959 10.2978C40.4959 9.18775 39.4884 8.28784 38.2455 8.28784C37.0027 8.28784 35.9951 9.18775 35.9951 10.2978C35.9951 11.4079 37.0027 12.3078 38.2455 12.3078Z"
          fill="white"
        />
        <path
          className="path"
          d="M27.4303 31.3978C29.5945 31.3978 31.3489 29.8308 31.3489 27.8978C31.3489 25.9648 29.5945 24.3978 27.4303 24.3978C25.2661 24.3978 23.5117 25.9648 23.5117 27.8978C23.5117 29.8308 25.2661 31.3978 27.4303 31.3978Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.43 18.1978C29.217 18.1978 30.6656 16.9039 30.6656 15.3078C30.6656 13.7117 29.217 12.4178 27.43 12.4178C25.643 12.4178 24.1943 13.7117 24.1943 15.3078C24.1943 16.9039 25.643 18.1978 27.43 18.1978Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6116 24.6478C41.3986 24.6478 42.8473 23.3539 42.8473 21.7578C42.8473 20.1617 41.3986 18.8678 39.6116 18.8678C37.8246 18.8678 36.376 20.1617 36.376 21.7578C36.376 23.3539 37.8246 24.6478 39.6116 24.6478Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6116 37.2379C41.3986 37.2379 42.8473 35.944 42.8473 34.3479C42.8473 32.7518 41.3986 31.4579 39.6116 31.4579C37.8246 31.4579 36.376 32.7518 36.376 34.3479C36.376 35.944 37.8246 37.2379 39.6116 37.2379Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.43 43.3779C29.217 43.3779 30.6656 42.084 30.6656 40.4879C30.6656 38.8918 29.217 37.5979 27.43 37.5979C25.643 37.5979 24.1943 38.8918 24.1943 40.4879C24.1943 42.084 25.643 43.3779 27.43 43.3779Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2493 24.6478C17.0363 24.6478 18.485 23.3539 18.485 21.7578C18.485 20.1617 17.0363 18.8678 15.2493 18.8678C13.4623 18.8678 12.0137 20.1617 12.0137 21.7578C12.0137 23.3539 13.4623 24.6478 15.2493 24.6478Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.6517 37.2379C17.4387 37.2379 18.8873 35.944 18.8873 34.3479C18.8873 32.7518 17.4387 31.4579 15.6517 31.4579C13.8647 31.4579 12.416 32.7518 12.416 34.3479C12.416 35.944 13.8647 37.2379 15.6517 37.2379Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.2508 4.39785C28.3267 4.39785 29.1989 3.61882 29.1989 2.65785C29.1989 1.69687 28.3267 0.917847 27.2508 0.917847C26.1749 0.917847 25.3027 1.69687 25.3027 2.65785C25.3027 3.61882 26.1749 4.39785 27.2508 4.39785Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.4305 54.0379C28.5064 54.0379 29.3786 53.2588 29.3786 52.2979C29.3786 51.3369 28.5064 50.5579 27.4305 50.5579C26.3546 50.5579 25.4824 51.3369 25.4824 52.2979C25.4824 53.2588 26.3546 54.0379 27.4305 54.0379Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3788 17.0479C52.4547 17.0479 53.3269 16.2688 53.3269 15.3079C53.3269 14.3469 52.4547 13.5679 51.3788 13.5679C50.3029 13.5679 49.4307 14.3469 49.4307 15.3079C49.4307 16.2688 50.3029 17.0479 51.3788 17.0479Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3788 42.0779C52.4547 42.0779 53.3269 41.2989 53.3269 40.3379C53.3269 39.3769 52.4547 38.5979 51.3788 38.5979C50.3029 38.5979 49.4307 39.3769 49.4307 40.3379C49.4307 41.2989 50.3029 42.0779 51.3788 42.0779Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62779 17.0479C4.7037 17.0479 5.5759 16.2688 5.5759 15.3079C5.5759 14.3469 4.7037 13.5679 3.62779 13.5679C2.55188 13.5679 1.67969 14.3469 1.67969 15.3079C1.67969 16.2688 2.55188 17.0479 3.62779 17.0479Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.76158 42.2278C4.83749 42.2278 5.70968 41.4488 5.70968 40.4878C5.70968 39.5268 4.83749 38.7478 3.76158 38.7478C2.68567 38.7478 1.81348 39.5268 1.81348 40.4878C1.81348 41.4488 2.68567 42.2278 3.76158 42.2278Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.2445 29.7179C52.4874 29.7179 53.4949 28.818 53.4949 27.7079C53.4949 26.5978 52.4874 25.6979 51.2445 25.6979C50.0017 25.6979 48.9941 26.5978 48.9941 27.7079C48.9941 28.818 50.0017 29.7179 51.2445 29.7179Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6117 48.0679C40.8546 48.0679 41.8621 47.1679 41.8621 46.0578C41.8621 44.9478 40.8546 44.0479 39.6117 44.0479C38.3689 44.0479 37.3613 44.9478 37.3613 46.0578C37.3613 47.1679 38.3689 48.0679 39.6117 48.0679Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.6518 48.0679C16.8946 48.0679 17.9022 47.1679 17.9022 46.0578C17.9022 44.9478 16.8946 44.0479 15.6518 44.0479C14.4089 44.0479 13.4014 44.9478 13.4014 46.0578C13.4014 47.1679 14.4089 48.0679 15.6518 48.0679Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2494 11.1578C16.4923 11.1578 17.4998 10.2579 17.4998 9.14782C17.4998 8.03772 16.4923 7.13782 15.2494 7.13782C14.0066 7.13782 12.999 8.03772 12.999 9.14782C12.999 10.2579 14.0066 11.1578 15.2494 11.1578Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62735 29.7179C4.87021 29.7179 5.87774 28.818 5.87774 27.7079C5.87774 26.5978 4.87021 25.6979 3.62735 25.6979C2.38449 25.6979 1.37695 26.5978 1.37695 27.7079C1.37695 28.818 2.38449 29.7179 3.62735 29.7179Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6117 11.1578C40.8546 11.1578 41.8621 10.2579 41.8621 9.14782C41.8621 8.03772 40.8546 7.13782 39.6117 7.13782C38.3689 7.13782 37.3613 8.03772 37.3613 9.14782C37.3613 10.2579 38.3689 11.1578 39.6117 11.1578Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M69.4375 2.98779H75.9872C78.7862 2.98779 80.9134 3.54779 82.3801 4.67779C83.8468 5.80779 84.5745 7.43779 84.5745 9.57779C84.5745 11.7178 83.7908 13.3078 82.2457 14.4478C80.6895 15.5878 78.4727 16.1678 75.5953 16.1678H69.4375V2.98779ZM81.4396 9.54779C81.4396 6.66779 79.6259 5.22779 76.0096 5.22779H72.4604V13.8978H75.6849C79.5251 13.8978 81.4508 12.4478 81.4508 9.54779H81.4396Z"
          fill="white"
        />
        <path className="path" d="M87.5527 2.98779H90.6092V16.1578H87.5527V2.98779Z" fill="white" />
        <path
          className="path"
          d="M93.5762 9.62784C93.5762 8.16784 93.9232 6.91784 94.6286 5.87784C95.3339 4.83784 96.308 4.03784 97.5731 3.48784C98.8383 2.93784 100.294 2.65784 101.94 2.65784C103.91 2.65784 105.489 3.01784 106.664 3.74784C107.84 4.47784 108.646 5.44784 109.06 6.65784L106.004 7.00784C105.701 6.28784 105.209 5.74784 104.526 5.39784C103.843 5.05784 102.947 4.87784 101.85 4.87784C100.182 4.87784 98.9055 5.29784 98.0322 6.12784C97.1477 6.96784 96.711 8.11784 96.711 9.57784C96.711 11.0378 97.1925 12.2478 98.1441 13.0478C99.0958 13.8478 100.473 14.2478 102.264 14.2478C103.944 14.2478 105.366 13.9978 106.541 13.5078V11.1078H102.309V9.01784H109.374V14.6678C108.467 15.2478 107.392 15.6878 106.149 16.0078C104.907 16.3178 103.619 16.4778 102.264 16.4778C100.462 16.4778 98.9055 16.2078 97.6067 15.6578C96.308 15.1078 95.3116 14.3278 94.6174 13.3078C93.9232 12.2878 93.5762 11.0578 93.5762 9.61784V9.62784Z"
          fill="white"
        />
        <path className="path" d="M112.475 2.98779H115.531V16.1578H112.475V2.98779Z" fill="white" />
        <path
          className="path"
          d="M123.503 5.27779H117.659V2.98779H132.382V5.27779H126.56V16.1578H123.503V5.27779Z"
          fill="white"
        />
        <path
          className="path"
          d="M134.89 16.1578H131.732L138.551 2.98779H141.954L148.795 16.1578H145.537L143.97 13.0378H136.435L134.901 16.1578H134.89ZM140.208 5.30779L137.42 10.9178H142.973L140.208 5.30779Z"
          fill="white"
        />
        <path
          className="path"
          d="M150.934 2.98779H153.99V13.8678H163.115L162.723 16.1578H150.934V2.98779Z"
          fill="white"
        />
        <path
          className="path"
          d="M69.4375 21.4279H76.5582C80.6335 21.4279 82.6712 22.8579 82.6712 25.7079C82.6712 27.1879 82.145 28.2779 81.0925 28.9779C80.0401 29.6879 78.4503 30.0379 76.3118 30.0379H72.4268V34.5979H69.4375V21.4279ZM79.6147 25.6879C79.6147 24.9679 79.3572 24.4379 78.8309 24.0979C78.3047 23.7579 77.4874 23.5979 76.3566 23.5979H72.438V27.8779H76.323C77.4538 27.8779 78.2823 27.6979 78.8197 27.3379C79.3572 26.9779 79.6259 26.4279 79.6259 25.6779L79.6147 25.6879Z"
          fill="white"
        />
        <path
          className="path"
          d="M85.7275 29.1079V21.4379H88.784V29.2779C88.784 30.4379 89.1087 31.2879 89.7805 31.8279C90.4411 32.3779 91.4711 32.6479 92.8818 32.6479C94.2925 32.6479 95.3225 32.3779 95.9831 31.8279C96.6436 31.2779 96.9795 30.4279 96.9795 29.2779V21.4379H100.036V29.1079C100.036 31.1079 99.4314 32.5879 98.2223 33.5279C97.0131 34.4679 95.2329 34.9379 92.893 34.9379C90.553 34.9379 88.7617 34.4679 87.5525 33.5279C86.3433 32.5879 85.7499 31.1179 85.7499 29.1079H85.7275Z"
          fill="white"
        />
        <path
          className="path"
          d="M102.769 32.6079L112.42 23.6479H102.959V21.4379H116.26V23.4279L106.62 32.3879H116.64L116.249 34.5979H102.78V32.6079H102.769Z"
          fill="white"
        />
        <path
          className="path"
          d="M118.565 32.6079L128.216 23.6479H118.756V21.4379H132.057V23.4279L122.417 32.3879H132.437L132.045 34.5979H118.577V32.6079H118.565Z"
          fill="white"
        />
        <path
          className="path"
          d="M135.998 21.4279H139.055V32.3079H148.179L147.787 34.5979H135.998V21.4279Z"
          fill="white"
        />
        <path
          className="path"
          d="M150.979 21.4279H163.205V23.5679H153.99V26.7779H162.746V28.9179H153.99V32.4579H163.652L163.283 34.5979H150.979V21.4279Z"
          fill="white"
        />
        <path className="path" d="M69.4375 39.8778H72.494V53.0478H69.4375V39.8778Z" fill="white" />
        <path
          className="path"
          d="M77.04 39.8778H79.8502L88.6279 49.3178V39.8778H91.5613V53.0478H88.7511L79.9734 43.5878V53.0478H77.04V39.8778Z"
          fill="white"
        />
        <path
          className="path"
          d="M94.9424 46.4678C94.9424 45.1178 95.2671 43.9178 95.9164 42.8778C96.5658 41.8378 97.4951 41.0279 98.7266 40.4379C99.9582 39.8479 101.414 39.5479 103.127 39.5479C107.157 39.5479 109.62 41.0379 110.505 44.0279L107.493 44.3578C107.146 43.4578 106.631 42.8179 105.948 42.4179C105.254 42.0279 104.313 41.8279 103.115 41.8279C101.57 41.8279 100.361 42.2378 99.4656 43.0578C98.5811 43.8779 98.1332 45.0079 98.1332 46.4579C98.1332 47.9079 98.5811 49.0478 99.4768 49.8578C100.372 50.6778 101.615 51.0878 103.194 51.0878C104.493 51.0878 105.5 50.8778 106.228 50.4478C106.956 50.0278 107.437 49.3579 107.672 48.4678H110.751C110.516 50.0378 109.743 51.2579 108.411 52.0979C107.079 52.9379 105.332 53.3679 103.149 53.3679C101.414 53.3679 99.9358 53.0779 98.7154 52.4979C97.4951 51.9179 96.5658 51.1079 95.9164 50.0679C95.2671 49.0279 94.9424 47.8178 94.9424 46.4478V46.4678Z"
          fill="white"
        />
        <path className="path" d="M113.158 50.2478H116.114V53.0478H113.158V50.2478Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_399_2108">
          <rect className="rect" fill="white" height="54.26" transform="translate(0 0.917847)" width="163.652" />
        </clipPath>
      </defs>
    </svg>
  );
};
