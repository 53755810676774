import React from "react";
import { Link } from "react-router-dom";
import { Frame36 } from "../../components/Frame36";
import { Grayline } from "../../components/Grayline";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Polygon } from "../../components/Polygon";
import "./style.css";

export const RecruitWrapper = () => {
  return (
    <div className="recruit-wrapper">
      <div className="div-27">
        <div className="logo-6">
          <div className="frame-367">
            <div className="group-62" />
          </div>
          <img className="menu-15" alt="Menu" src="/img/menu-12.svg" />
        </div>
        <div className="midashi-10">
          <div className="component-3">
            <div className="text-wrapper-466">採用情報</div>
            <div className="TEXTEXT-3">RECRUIT</div>
          </div>
        </div>
        <div className="frame-368">
          <div className="group-63">
            <Link className="text-wrapper-467" to="/home-320">
              TOP
            </Link>
            <ImgWrapper className="component-10930-6" img="/img/11.svg" />
            <div className="text-wrapper-468">ブログ</div>
          </div>
        </div>
      </div>
      <div className="view-7">
        <div className="frame-369">
          <div className="frame-370">
            <div className="frame-371">
              <div className="group-64">
                <div className="text-wrapper-469">2024/00/00</div>
              </div>
              <div className="frame-372">
                <div className="text-wrapper-470">♯タグ</div>
                <div className="text-wrapper-471">♯タグ</div>
                <div className="text-wrapper-471">♯タグ</div>
                <div className="text-wrapper-471">♯タグ</div>
                <div className="text-wrapper-472">♯タグ</div>
                <div className="text-wrapper-472">♯タグ</div>
                <div className="text-wrapper-472">♯タグ</div>
                <div className="text-wrapper-472">♯タグ</div>
              </div>
            </div>
            <p className="div-28">
              <span className="text-wrapper-473">見出し</span>
              <span className="text-wrapper-474">
                見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し
              </span>
            </p>
            <Grayline className="grayline-7" />
            <div className="frame-373">
              <div className="frame-374">
                <NoimageWrapper className="noimage-11" noimage="/img/noimage-35.svg" />
              </div>
              <div className="frame-375">
                <NoimageWrapper className="noimage-11" noimage="/img/noimage-36.svg" />
              </div>
            </div>
            <div className="text-wrapper-475">
              テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
            </div>
          </div>
          <div className="frame-376">
            <div className="group-65">
              <div className="text-wrapper-476">前の記事へ</div>
              <Polygon className="polygon-8" element="/img/polygon-2-22.svg" one="one" />
            </div>
            <Frame36 className="frame-377" divClassName="frame-378" one="one" />
            <div className="group-66">
              <div className="text-wrapper-477">次の記事へ</div>
              <Polygon className="polygon-9" element="/img/polygon-2-1.svg" one="one" />
            </div>
          </div>
        </div>
        <div className="div-27">
          <div className="frame-379">
            <div className="text-wrapper-478">カテゴリ</div>
          </div>
          <div className="frame-380">
            <div className="frame-381">
              <div className="text-wrapper-479">カテゴリ名</div>
              <Polygon className="polygon-10" element="/img/polygon-2-2.svg" one="one" />
            </div>
            <img className="line-19" alt="Line" src="/img/line-18-2.svg" />
            <div className="frame-381">
              <div className="text-wrapper-479">カテゴリ名</div>
              <Polygon className="polygon-10" element="/img/polygon-2-2.svg" one="one" />
            </div>
            <img className="line-19" alt="Line" src="/img/line-18-2.svg" />
            <div className="frame-381">
              <div className="text-wrapper-479">カテゴリ名</div>
              <Polygon className="polygon-10" element="/img/polygon-2-2.svg" one="one" />
            </div>
            <img className="line-19" alt="Line" src="/img/line-18-2.svg" />
            <div className="frame-381">
              <div className="text-wrapper-479">カテゴリ名</div>
              <Polygon className="polygon-10" element="/img/polygon-2-2.svg" one="one" />
            </div>
            <img className="line-19" alt="Line" src="/img/line-18-2.svg" />
            <div className="frame-382">
              <div className="text-wrapper-479">カテゴリ名</div>
              <Polygon className="polygon-10" element="/img/polygon-2-2.svg" one="one" />
            </div>
          </div>
        </div>
      </div>
      <div className="CONTACT-30">
        <div className="CONTACT-31">
          <div className="frame-383">
            <div className="group-67">
              <div className="overlap-group-16">
                <div className="text-wrapper-480">お問い合わせ</div>
                <div className="text-wrapper-481">CONTACT</div>
              </div>
            </div>
            <div className="group-68">
              <div className="flexcontainer-50">
                <p className="text-i-51">
                  <span className="text-wrapper-482">
                    制作のご依頼・ご相談・ご質問など
                    <br />
                  </span>
                </p>
                <p className="text-i-51">
                  <span className="text-wrapper-482">お気軽にお問い合わせください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-17">
        <div className="frame-384">
          <div className="frame-385">
            <LogoWhite logoWhite="/img/logo-white-12.svg" logoWhiteClassName="logo-white-13" to="/home-320" />
            <div className="flexcontainer-51">
              <p className="text-i-52">
                <span className="text-wrapper-483">
                  千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                </span>
              </p>
              <p className="text-i-52">
                <span className="text-wrapper-483">幕張ベイパーク　クロスレジデンスＳ２－１</span>
              </p>
            </div>
          </div>
          <div className="frame-386">
            <div className="frame-387">
              <div className="frame-388">
                <Link className="text-wrapper-484" to="/philosophy-320">
                  理念
                </Link>
                <div className="text-wrapper-485">- 企業理念</div>
                <div className="text-wrapper-485"> - 経営理念</div>
                <div className="text-wrapper-485"> - 社訓</div>
              </div>
              <div className="frame-388">
                <div className="text-wrapper-486">会社概要</div>
                <div className="text-wrapper-485">- 代表挨拶</div>
                <div className="text-wrapper-485">- 概要</div>
              </div>
              <div className="frame-388">
                <div className="text-wrapper-486">制作について</div>
                <div className="text-wrapper-485">- 業務内容</div>
                <div className="text-wrapper-485">- ご相談の流れ</div>
              </div>
            </div>
            <div className="frame-387">
              <Link className="text-wrapper-484" to="/blogs-320">
                ブログ
              </Link>
              <Link className="text-wrapper-487" to="/recruits-320">
                採用情報
              </Link>
              <div className="text-wrapper-485">よくある質問</div>
              <Link className="text-wrapper-487" to="/information-security-basic-policy-320">
                情報セキュリティ基本方針
              </Link>
              <Link className="text-wrapper-487" to="/privacypolicy-320">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </div>
        <div className="text-wrapper-488">©︎ Digital Puzzle Inc</div>
      </footer>
    </div>
  );
};
