/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Arrow31 = ({ className }) => {
  return (
    <svg
      className={`arrow-31 ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 10 12"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M8.96553 0.917848L2 5.91785L8.96553 10.9178" stroke="#454545" strokeWidth="1.5" />
    </svg>
  );
};
