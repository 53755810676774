/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Triangle0218 = ({ color = "#D6DBED", className }) => {
  return (
    <svg
      className={`triangle02-18 ${className}`}
      fill="none"
      height="21"
      viewBox="0 0 20 21"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M19.282 6.56232L9.93496 16.8505L0.587891 6.56232H19.282Z" fill={color} />
    </svg>
  );
};

Triangle0218.propTypes = {
  color: PropTypes.string,
};
