/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Menu3 = ({ className }) => {
  return (
    <svg
      className={`menu-3 ${className}`}
      fill="none"
      height="35"
      viewBox="0 0 36 35"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 0H36V35H8.3692C3.74966 35 0 31.3152 0 26.7756V0Z" fill="#6079C1" />
      <path className="path" d="M10.4653 11.9412H26.7909" stroke="white" strokeMiterlimit="10" />
      <path className="path" d="M10.4653 17.2941H26.7909" stroke="white" strokeMiterlimit="10" />
      <path className="path" d="M10.4653 23.0588H26.7909" stroke="white" strokeMiterlimit="10" />
    </svg>
  );
};
