/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Noimage24 = ({ className }) => {
  return (
    <svg
      className={`noimage-24 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 198 27"
      width="198"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.873 20.3993C25.1225 20.3993 27.7567 17.8429 27.7567 14.6893C27.7567 11.5358 25.1225 8.97931 21.873 8.97931C18.6235 8.97931 15.9893 11.5358 15.9893 14.6893C15.9893 17.8429 18.6235 20.3993 21.873 20.3993Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M38.9577 2.97929H12.0018V0.659302H5.02579V2.97929H4.79909C2.50125 2.97929 0.646484 4.78931 0.646484 7.00931V22.5493C0.646484 24.7793 2.51155 26.5793 4.79909 26.5793H38.968C41.2658 26.5793 43.1206 24.7693 43.1206 22.5493V7.00931C43.1206 4.77931 41.2555 2.97929 38.968 2.97929H38.9577ZM21.8732 21.9493C17.7412 21.9493 14.3923 18.6993 14.3923 14.6893C14.3923 10.6793 17.7412 7.42931 21.8732 7.42931C26.0052 7.42931 29.3541 10.6793 29.3541 14.6893C29.3541 18.6993 26.0052 21.9493 21.8732 21.9493ZM38.731 8.39931H31.755V6.4593H38.731V8.39931Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M188.009 22.0293V8.61932H197.324V10.9593H190.935V14.1593H196.85V16.4993H190.935V19.6993H197.355V22.0393H188.009V22.0293Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M175.5 12.9593C175.407 12.6393 175.273 12.3593 175.098 12.1193C174.923 11.8693 174.716 11.6593 174.469 11.4893C174.232 11.3193 173.954 11.1793 173.635 11.0893C173.325 10.9993 172.975 10.9493 172.604 10.9493C171.893 10.9493 171.275 11.1193 170.739 11.4593C170.203 11.7993 169.791 12.2993 169.492 12.9493C169.193 13.5993 169.049 14.3893 169.049 15.3193C169.049 16.2493 169.193 17.0493 169.492 17.6993C169.791 18.3493 170.203 18.8493 170.729 19.1993C171.265 19.5393 171.893 19.7093 172.625 19.7093C173.284 19.7093 173.851 19.5993 174.315 19.3693C174.789 19.1393 175.149 18.8093 175.397 18.3893C175.644 17.9693 175.778 17.4793 175.778 16.8993L176.375 16.9893H172.81V14.8593H178.591V16.5493C178.591 17.7293 178.333 18.7393 177.818 19.5893C177.303 20.4293 176.602 21.0793 175.695 21.5393C174.799 21.9893 173.768 22.2193 172.604 22.2193C171.306 22.2193 170.172 21.9393 169.193 21.3893C168.214 20.8293 167.452 20.0393 166.906 19.0093C166.36 17.9793 166.092 16.7593 166.092 15.3393C166.092 14.2493 166.257 13.2793 166.576 12.4293C166.906 11.5693 167.359 10.8493 167.957 10.2593C168.544 9.66932 169.235 9.20932 170.018 8.89932C170.801 8.58932 171.646 8.42932 172.563 8.42932C173.346 8.42932 174.078 8.53932 174.747 8.75932C175.427 8.97932 176.025 9.28932 176.54 9.68932C177.066 10.0893 177.499 10.5693 177.828 11.1193C178.158 11.6693 178.374 12.2793 178.467 12.9393H175.5V12.9593Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M147.925 22.0293H144.793L149.564 8.61932H153.325L158.085 22.0293H154.953L151.491 11.6793H151.388L147.915 22.0293H147.925ZM147.73 16.7593H155.128V18.9693H147.73V16.7593Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M120.938 8.61932H124.545L128.347 17.6293H128.512L132.314 8.61932H135.921V22.0293H133.087V13.2993H132.974L129.398 21.9593H127.471L123.896 13.2593H123.782V22.0193H120.949V8.60931L120.938 8.61932Z"
        fill="#ADADAE"
      />
      <path className="path" d="M111.314 8.61932V22.0293H108.388V8.61932H111.314Z" fill="#ADADAE" />
      <path
        className="path"
        d="M93.3225 15.3293C93.3225 16.7893 93.034 18.0393 92.4673 19.0593C91.9006 20.0893 91.1277 20.8693 90.1488 21.4093C89.1699 21.9493 88.0777 22.2193 86.8515 22.2193C85.6253 22.2193 84.5227 21.9493 83.5438 21.4093C82.5649 20.8693 81.7921 20.0893 81.2254 19.0593C80.6586 18.0293 80.3701 16.7893 80.3701 15.3293C80.3701 13.8693 80.6586 12.6193 81.2254 11.5993C81.7921 10.5694 82.5649 9.78935 83.5438 9.25935C84.5227 8.71935 85.6253 8.44934 86.8515 8.44934C88.0777 8.44934 89.1699 8.71935 90.1488 9.25935C91.1277 9.79935 91.9006 10.5793 92.4673 11.5993C93.034 12.6293 93.3225 13.8693 93.3225 15.3293ZM90.3549 15.3293C90.3549 14.3793 90.2107 13.5794 89.9118 12.9294C89.6233 12.2794 89.2215 11.7893 88.6856 11.4493C88.1601 11.1093 87.5419 10.9493 86.8412 10.9493C86.1405 10.9493 85.5222 11.1193 84.9967 11.4493C84.4712 11.7893 84.059 12.2794 83.7705 12.9294C83.482 13.5794 83.3377 14.3793 83.3377 15.3293C83.3377 16.2793 83.482 17.0793 83.7705 17.7293C84.059 18.3793 84.4712 18.8694 84.9967 19.2094C85.5222 19.5494 86.1405 19.7094 86.8412 19.7094C87.5419 19.7094 88.1601 19.5394 88.6856 19.2094C89.2111 18.8694 89.6233 18.3793 89.9118 17.7293C90.2004 17.0793 90.3549 16.2793 90.3549 15.3293Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M70.9828 8.61932V22.0293H68.4583L62.4406 13.5893H62.3375V22.0293H59.4111V8.61932H61.9769L67.943 17.0593H68.0667V8.61932H70.9725H70.9828Z"
        fill="#ADADAE"
      />
    </svg>
  );
};
