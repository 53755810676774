/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Checkboxes } from "../Checkboxes";
import "./style.css";

export const Checklist = ({ className, divClassName, text = "お問い合わせ" }) => {
  return (
    <div className={`checklist ${className}`}>
      <Checkboxes className="checkboxes-3" />
      <div className={`text-wrapper-3 ${divClassName}`}>{text}</div>
    </div>
  );
};

Checklist.propTypes = {
  text: PropTypes.string,
};
