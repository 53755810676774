import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { Menu9 } from "../../icons/Menu9";
import "./style.css";

export const MenuopenPc = () => {
  return (
    <div className="menuopen-PC">
      <div className="menu-wrapper">
        <div className="menu-5">
          <div className="frame-151">
            <div className="text-wrapper-144">ホーム</div>
            <div className="frame-152">
              <div className="frame-153">
                <div className="frame-153">
                  <div className="frame-154">
                    <div className="text-wrapper-144">理念</div>
                    <div className="frame-155">
                      <div className="text-wrapper-145">- 企業理念</div>
                      <div className="text-wrapper-146">- 経営理念</div>
                      <div className="text-wrapper-146">- 社訓</div>
                    </div>
                  </div>
                  <div className="frame-154">
                    <div className="text-wrapper-144">会社概要</div>
                    <div className="frame-155">
                      <div className="text-wrapper-145">- 代表挨拶</div>
                      <div className="text-wrapper-146">- 概要</div>
                    </div>
                  </div>
                  <div className="frame-154">
                    <div className="text-wrapper-144">制作について</div>
                    <div className="frame-155">
                      <div className="text-wrapper-145">- 業務内容</div>
                      <div className="text-wrapper-146">- ご相談の流れ</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-153">
                <div className="frame-156">
                  <div className="text-wrapper-147">ブログ</div>
                  <div className="text-wrapper-148">採用情報</div>
                  <div className="text-wrapper-148">よくある質問</div>
                  <div className="text-wrapper-148">情報セキュリティ基本方針</div>
                  <Link className="text-wrapper-149" to="/privacypolicy-1200">
                    プライバシーポリシー
                  </Link>
                </div>
                <Button
                  arrow38StyleOverrideClassName="button-10"
                  className="button-9"
                  color="seven"
                  device="PC"
                  text="お問い合わせ"
                  to="/contact-1200"
                />
              </div>
            </div>
          </div>
          <Menu9 className="menu-9" />
        </div>
      </div>
    </div>
  );
};
