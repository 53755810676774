/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWhite22 = ({ className }) => {
  return (
    <svg
      className={`logo-white-22 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 121 40"
      width="121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_399_4859)">
        <path
          className="path"
          d="M19.2704 23.31C20.8705 23.31 22.1677 22.1548 22.1677 20.7298C22.1677 19.3048 20.8705 18.1497 19.2704 18.1497C17.6702 18.1497 16.373 19.3048 16.373 20.7298C16.373 22.1548 17.6702 23.31 19.2704 23.31Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2713 13.5864C20.5925 13.5864 21.6636 12.6326 21.6636 11.4559C21.6636 10.2793 20.5925 9.32544 19.2713 9.32544C17.95 9.32544 16.8789 10.2793 16.8789 11.4559C16.8789 12.6326 17.95 13.5864 19.2713 13.5864Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2771 18.334C29.5984 18.334 30.6695 17.3801 30.6695 16.2035C30.6695 15.0268 29.5984 14.073 28.2771 14.073C26.9559 14.073 25.8848 15.0268 25.8848 16.2035C25.8848 17.3801 26.9559 18.334 28.2771 18.334Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2771 27.6152C29.5984 27.6152 30.6695 26.6614 30.6695 25.4847C30.6695 24.3081 29.5984 23.3542 28.2771 23.3542C26.9559 23.3542 25.8848 24.3081 25.8848 25.4847C25.8848 26.6614 26.9559 27.6152 28.2771 27.6152Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2713 32.1416C20.5925 32.1416 21.6636 31.1877 21.6636 30.0111C21.6636 28.8345 20.5925 27.8806 19.2713 27.8806C17.95 27.8806 16.8789 28.8345 16.8789 30.0111C16.8789 31.1877 17.95 32.1416 19.2713 32.1416Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.2654 18.334C11.5867 18.334 12.6577 17.3801 12.6577 16.2035C12.6577 15.0268 11.5867 14.073 10.2654 14.073C8.94414 14.073 7.87305 15.0268 7.87305 16.2035C7.87305 17.3801 8.94414 18.334 10.2654 18.334Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.5623 27.6152C11.8835 27.6152 12.9546 26.6614 12.9546 25.4847C12.9546 24.3081 11.8835 23.3542 10.5623 23.3542C9.24101 23.3542 8.16992 24.3081 8.16992 25.4847C8.16992 26.6614 9.24101 27.6152 10.5623 27.6152Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.1396 3.40588C19.9351 3.40588 20.58 2.83159 20.58 2.12317C20.58 1.41474 19.9351 0.840454 19.1396 0.840454C18.3441 0.840454 17.6992 1.41474 17.6992 2.12317C17.6992 2.83159 18.3441 3.40588 19.1396 3.40588Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2705 40C20.066 40 20.7108 39.4257 20.7108 38.7173C20.7108 38.0089 20.066 37.4346 19.2705 37.4346C18.475 37.4346 17.8301 38.0089 17.8301 38.7173C17.8301 39.4257 18.475 40 19.2705 40Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.9775 12.7386C37.773 12.7386 38.4179 12.1644 38.4179 11.4559C38.4179 10.7475 37.773 10.1732 36.9775 10.1732C36.182 10.1732 35.5371 10.7475 35.5371 11.4559C35.5371 12.1644 36.182 12.7386 36.9775 12.7386Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.9775 31.1832C37.773 31.1832 38.4179 30.6089 38.4179 29.9005C38.4179 29.1921 37.773 28.6178 36.9775 28.6178C36.182 28.6178 35.5371 29.1921 35.5371 29.9005C35.5371 30.6089 36.182 31.1832 36.9775 31.1832Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.66303 12.7386C2.45853 12.7386 3.10341 12.1644 3.10341 11.4559C3.10341 10.7475 2.45853 10.1732 1.66303 10.1732C0.867535 10.1732 0.222656 10.7475 0.222656 11.4559C0.222656 12.1644 0.867535 12.7386 1.66303 12.7386Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.76264 31.2938C2.55814 31.2938 3.20302 30.7195 3.20302 30.0111C3.20302 29.3027 2.55814 28.7284 1.76264 28.7284C0.967145 28.7284 0.322266 29.3027 0.322266 30.0111C0.322266 30.7195 0.967145 31.2938 1.76264 31.2938Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.8787 22.0715C37.7977 22.0715 38.5426 21.4081 38.5426 20.5898C38.5426 19.7714 37.7977 19.108 36.8787 19.108C35.9598 19.108 35.2148 19.7714 35.2148 20.5898C35.2148 21.4081 35.9598 22.0715 36.8787 22.0715Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2772 35.599C29.1961 35.599 29.941 34.9356 29.941 34.1173C29.941 33.2989 29.1961 32.6355 28.2772 32.6355C27.3582 32.6355 26.6133 33.2989 26.6133 34.1173C26.6133 34.9356 27.3582 35.599 28.2772 35.599Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.5623 35.599C11.4813 35.599 12.2262 34.9356 12.2262 34.1173C12.2262 33.2989 11.4813 32.6355 10.5623 32.6355C9.64338 32.6355 8.89844 33.2989 8.89844 34.1173C8.89844 34.9356 9.64338 35.599 10.5623 35.599Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.2654 8.39662C11.1844 8.39662 11.9293 7.73321 11.9293 6.91486C11.9293 6.09651 11.1844 5.43311 10.2654 5.43311C9.34651 5.43311 8.60156 6.09651 8.60156 6.91486C8.60156 7.73321 9.34651 8.39662 10.2654 8.39662Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.66388 22.0715C2.58282 22.0715 3.32777 21.4081 3.32777 20.5898C3.32777 19.7714 2.58282 19.108 1.66388 19.108C0.744946 19.108 0 19.7714 0 20.5898C0 21.4081 0.744946 22.0715 1.66388 22.0715Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2772 8.39662C29.1961 8.39662 29.941 7.73321 29.941 6.91486C29.941 6.09651 29.1961 5.43311 28.2772 5.43311C27.3582 5.43311 26.6133 6.09651 26.6133 6.91486C26.6133 7.73321 27.3582 8.39662 28.2772 8.39662Z"
          fill="white"
        />
        <path
          className="path"
          d="M20.2821 22.4695C21.8822 22.4695 23.1794 21.3144 23.1794 19.8894C23.1794 18.4644 21.8822 17.3092 20.2821 17.3092C18.6819 17.3092 17.3848 18.4644 17.3848 19.8894C17.3848 21.3144 18.6819 22.4695 20.2821 22.4695Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.281 12.7386C21.6023 12.7386 22.6734 11.7848 22.6734 10.6081C22.6734 9.43151 21.6023 8.47766 20.281 8.47766C18.9598 8.47766 17.8887 9.43151 17.8887 10.6081C17.8887 11.7848 18.9598 12.7386 20.281 12.7386Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2869 17.4935C30.6081 17.4935 31.6792 16.5397 31.6792 15.363C31.6792 14.1864 30.6081 13.2325 29.2869 13.2325C27.9656 13.2325 26.8945 14.1864 26.8945 15.363C26.8945 16.5397 27.9656 17.4935 29.2869 17.4935Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2869 26.7748C30.6081 26.7748 31.6792 25.8209 31.6792 24.6443C31.6792 23.4676 30.6081 22.5138 29.2869 22.5138C27.9656 22.5138 26.8945 23.4676 26.8945 24.6443C26.8945 25.8209 27.9656 26.7748 29.2869 26.7748Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.281 31.3011C21.6023 31.3011 22.6734 30.3473 22.6734 29.1706C22.6734 27.994 21.6023 27.0402 20.281 27.0402C18.9598 27.0402 17.8887 27.994 17.8887 29.1706C17.8887 30.3473 18.9598 31.3011 20.281 31.3011Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.2752 17.4935C12.5964 17.4935 13.6675 16.5397 13.6675 15.363C13.6675 14.1864 12.5964 13.2325 11.2752 13.2325C9.9539 13.2325 8.88281 14.1864 8.88281 15.363C8.88281 16.5397 9.9539 17.4935 11.2752 17.4935Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.572 26.7748C12.8933 26.7748 13.9644 25.8209 13.9644 24.6443C13.9644 23.4676 12.8933 22.5138 11.572 22.5138C10.2508 22.5138 9.17969 23.4676 9.17969 24.6443C9.17969 25.8209 10.2508 26.7748 11.572 26.7748Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.1494 2.56543C20.9449 2.56543 21.5897 1.99114 21.5897 1.28271C21.5897 0.57429 20.9449 0 20.1494 0C19.3539 0 18.709 0.57429 18.709 1.28271C18.709 1.99114 19.3539 2.56543 20.1494 2.56543Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.2802 39.1597C21.0757 39.1597 21.7206 38.5854 21.7206 37.877C21.7206 37.1685 21.0757 36.5942 20.2802 36.5942C19.4847 36.5942 18.8398 37.1685 18.8398 37.877C18.8398 38.5854 19.4847 39.1597 20.2802 39.1597Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.9872 11.8909C38.7827 11.8909 39.4276 11.3166 39.4276 10.6082C39.4276 9.89973 38.7827 9.32544 37.9872 9.32544C37.1918 9.32544 36.5469 9.89973 36.5469 10.6082C36.5469 11.3166 37.1918 11.8909 37.9872 11.8909Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.9872 30.3428C38.7827 30.3428 39.4276 29.7685 39.4276 29.0601C39.4276 28.3516 38.7827 27.7773 37.9872 27.7773C37.1918 27.7773 36.5469 28.3516 36.5469 29.0601C36.5469 29.7685 37.1918 30.3428 37.9872 30.3428Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.68257 11.8909C3.47806 11.8909 4.12294 11.3166 4.12294 10.6082C4.12294 9.89973 3.47806 9.32544 2.68257 9.32544C1.88707 9.32544 1.24219 9.89973 1.24219 10.6082C1.24219 11.3166 1.88707 11.8909 2.68257 11.8909Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.78217 30.4534C3.57767 30.4534 4.22255 29.8791 4.22255 29.1707C4.22255 28.4622 3.57767 27.8879 2.78217 27.8879C1.98668 27.8879 1.3418 28.4622 1.3418 29.1707C1.3418 29.8791 1.98668 30.4534 2.78217 30.4534Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.8885 21.2311C38.8074 21.2311 39.5524 20.5677 39.5524 19.7493C39.5524 18.931 38.8074 18.2676 37.8885 18.2676C36.9696 18.2676 36.2246 18.931 36.2246 19.7493C36.2246 20.5677 36.9696 21.2311 37.8885 21.2311Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2869 34.7586C30.2059 34.7586 30.9508 34.0951 30.9508 33.2768C30.9508 32.4584 30.2059 31.795 29.2869 31.795C28.368 31.795 27.623 32.4584 27.623 33.2768C27.623 34.0951 28.368 34.7586 29.2869 34.7586Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.5721 34.7586C12.491 34.7586 13.236 34.0951 13.236 33.2768C13.236 32.4584 12.491 31.795 11.5721 31.795C10.6531 31.795 9.9082 32.4584 9.9082 33.2768C9.9082 34.0951 10.6531 34.7586 11.5721 34.7586Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.2752 7.54884C12.1942 7.54884 12.9391 6.88543 12.9391 6.06708C12.9391 5.24873 12.1942 4.58533 11.2752 4.58533C10.3563 4.58533 9.61133 5.24873 9.61133 6.06708C9.61133 6.88543 10.3563 7.54884 11.2752 7.54884Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.68146 21.2311C3.6004 21.2311 4.34535 20.5677 4.34535 19.7493C4.34535 18.931 3.6004 18.2676 2.68146 18.2676C1.76252 18.2676 1.01758 18.931 1.01758 19.7493C1.01758 20.5677 1.76252 21.2311 2.68146 21.2311Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2869 7.54884C30.2059 7.54884 30.9508 6.88543 30.9508 6.06708C30.9508 5.24873 30.2059 4.58533 29.2869 4.58533C28.368 4.58533 27.623 5.24873 27.623 6.06708C27.623 6.88543 28.368 7.54884 29.2869 7.54884Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3398 1.526H56.1825C58.252 1.526 59.8248 1.93883 60.9092 2.77185C61.9937 3.60488 62.5317 4.8065 62.5317 6.38409C62.5317 7.96168 61.9523 9.13382 60.8099 9.97421C59.6593 10.8146 58.0202 11.2422 55.8928 11.2422H51.3398V1.526ZM60.2139 6.36198C60.2139 4.23887 58.8729 3.17731 56.199 3.17731H53.5749V9.56876H55.959C58.7983 9.56876 60.2222 8.49983 60.2222 6.36198H60.2139Z"
          fill="white"
        />
        <path className="path" d="M64.7344 1.526H66.9943V11.2348H64.7344V1.526Z" fill="white" />
        <path
          className="path"
          d="M69.1875 6.42094C69.1875 5.34464 69.4441 4.42315 69.9656 3.65647C70.4871 2.88979 71.2073 2.30004 72.1428 1.89458C73.0782 1.48913 74.1543 1.28271 75.3712 1.28271C76.8281 1.28271 77.9953 1.5481 78.8645 2.08625C79.7337 2.6244 80.3297 3.33948 80.636 4.23148L78.3761 4.4895C78.1526 3.95872 77.7884 3.56064 77.2834 3.30262C76.7785 3.05197 76.1162 2.91928 75.305 2.91928C74.0715 2.91928 73.1278 3.2289 72.4822 3.84077C71.8282 4.46001 71.5053 5.30778 71.5053 6.38408C71.5053 7.46038 71.8613 8.35238 72.5649 8.94213C73.2686 9.53189 74.2868 9.82676 75.6112 9.82676C76.853 9.82676 77.9043 9.64246 78.7735 9.28124V7.51198H75.6444V5.97125H80.8678V10.1364C80.1973 10.564 79.4026 10.8883 78.4837 11.1242C77.5649 11.3527 76.6129 11.4707 75.6112 11.4707C74.2785 11.4707 73.1278 11.2717 72.1676 10.8662C71.2073 10.4607 70.4706 9.88574 69.9574 9.1338C69.4441 8.38187 69.1875 7.47512 69.1875 6.41357V6.42094Z"
          fill="white"
        />
        <path className="path" d="M83.1602 1.526H85.4201V11.2348H83.1602V1.526Z" fill="white" />
        <path
          className="path"
          d="M91.3153 3.21417H86.9941V1.526H97.8798V3.21417H93.5752V11.2348H91.3153V3.21417Z"
          fill="white"
        />
        <path
          className="path"
          d="M99.7328 11.2348H97.3984L102.44 1.526H104.956L110.014 11.2348H107.605L106.446 8.93477H100.875L99.7411 11.2348H99.7328ZM103.665 3.23628L101.604 7.37193H105.71L103.665 3.23628Z"
          fill="white"
        />
        <path className="path" d="M111.596 1.526H113.856V9.54664H120.602L120.312 11.2348H111.596V1.526Z" fill="white" />
        <path
          className="path"
          d="M51.3398 15.1198H56.6047C59.6179 15.1198 61.1245 16.1739 61.1245 18.2749C61.1245 19.366 60.7354 20.1695 59.9573 20.6855C59.1791 21.209 58.0037 21.467 56.4226 21.467H53.5501V24.8286H51.3398V15.1198ZM58.8646 18.2602C58.8646 17.7294 58.6742 17.3387 58.2851 17.0881C57.896 16.8374 57.2917 16.7195 56.4557 16.7195H53.5584V19.8746H56.4308C57.2669 19.8746 57.8795 19.7419 58.2768 19.4766C58.6742 19.2112 58.8729 18.8057 58.8729 18.2528L58.8646 18.2602Z"
          fill="white"
        />
        <path
          className="path"
          d="M63.3848 20.7815V15.1272H65.6447V20.9068C65.6447 21.7619 65.8847 22.3885 66.3814 22.7866C66.8698 23.1921 67.6314 23.3911 68.6744 23.3911C69.7175 23.3911 70.479 23.1921 70.9674 22.7866C71.4558 22.3812 71.7042 21.7545 71.7042 20.9068V15.1272H73.9641V20.7815C73.9641 22.2558 73.5171 23.3469 72.6231 24.0398C71.729 24.7328 70.4128 25.0793 68.6827 25.0793C66.9526 25.0793 65.6281 24.7328 64.7341 24.0398C63.8401 23.3469 63.4013 22.2632 63.4013 20.7815H63.3848Z"
          fill="white"
        />
        <path
          className="path"
          d="M75.9844 23.3616L83.12 16.7564H76.1251V15.1272H85.9594V16.5942L78.832 23.1994H86.2409L85.9511 24.8286H75.9927V23.3616H75.9844Z"
          fill="white"
        />
        <path
          className="path"
          d="M87.6641 23.3616L94.7997 16.7564H87.8048V15.1272H97.6391V16.5942L90.5117 23.1994H97.9205L97.6308 24.8286H87.6723V23.3616H87.6641Z"
          fill="white"
        />
        <path
          className="path"
          d="M100.553 15.1198H102.813V23.1404H109.559L109.27 24.8286H100.553V15.1198Z"
          fill="white"
        />
        <path
          className="path"
          d="M111.629 15.1198H120.669V16.6973H113.856V19.0637H120.329V20.6413H113.856V23.251H121L120.726 24.8286H111.629V15.1198Z"
          fill="white"
        />
        <path className="path" d="M51.3398 28.7209H53.5997V38.4298H51.3398V28.7209Z" fill="white" />
        <path
          className="path"
          d="M56.9609 28.7209H59.0387L65.5287 35.68V28.7209H67.6975V38.4298H65.6198L59.1298 31.4559V38.4298H56.9609V28.7209Z"
          fill="white"
        />
        <path
          className="path"
          d="M70.1973 33.579C70.1973 32.5838 70.4373 31.6992 70.9175 30.9325C71.3976 30.1658 72.0847 29.5687 72.9952 29.1338C73.9058 28.6988 74.982 28.4777 76.2485 28.4777C79.2286 28.4777 81.0498 29.5761 81.7037 31.7803L79.4769 32.0235C79.2203 31.3601 78.8395 30.8883 78.3346 30.5934C77.8213 30.3059 77.126 30.1585 76.2402 30.1585C75.0979 30.1585 74.2038 30.4607 73.5416 31.0652C72.8876 31.6697 72.5565 32.5027 72.5565 33.5717C72.5565 34.6406 72.8876 35.481 73.5499 36.0781C74.2121 36.6826 75.131 36.9848 76.2982 36.9848C77.2584 36.9848 78.0034 36.83 78.5415 36.513C79.0796 36.2034 79.4355 35.7095 79.6094 35.0534H81.8858C81.712 36.2108 81.1408 37.1102 80.1557 37.7294C79.1707 38.3487 77.8793 38.6656 76.2651 38.6656C74.982 38.6656 73.8893 38.4519 72.987 38.0243C72.0847 37.5967 71.3976 36.9996 70.9175 36.2329C70.4373 35.4662 70.1973 34.5742 70.1973 33.5643V33.579Z"
          fill="white"
        />
        <path className="path" d="M83.666 36.3656H85.8514V38.4297H83.666V36.3656Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_399_4859">
          <rect className="rect" fill="white" height="40" width="121" />
        </clipPath>
      </defs>
    </svg>
  );
};
