import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LineWrapper } from "../../components/LineWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { OpenOpenWrapper } from "../../components/OpenOpenWrapper";
import "./style.css";

export const ScreenWrapper = () => {
  return (
    <div className="screen-wrapper">
      <div className="headrer-4">
        <div className="logo-10">
          <div className="frame-480">
            <div className="group-94" />
          </div>
          <OpenOpenWrapper open="open" openOpen="/img/component-2.svg" openOpenClassName="component-13" />
        </div>
        <div className="midashi-19">
          <div className="component-14">
            <div className="text-wrapper-627">企業理念</div>
            <div className="TEXTEXT-11">PHILOSOPHY</div>
          </div>
        </div>
        <div className="frame-481">
          <div className="group-95">
            <div className="text-wrapper-628">TOP</div>
            <ImgWrapper className="component-10930-15" img="/img/2.svg" />
            <div className="text-wrapper-629">企業理念</div>
          </div>
        </div>
      </div>
      <div className="view-10">
        <div className="view-11">
          <div className="frame-482">
            <div className="frame-483">
              <div className="component-15">
                <div className="text-wrapper-630">企業理念</div>
                <div className="TEXTEXT-12">CORPORATE PHILOSOPHY</div>
              </div>
              <div className="flexcontainer-73">
                <p className="text-49">
                  <span className="text-wrapper-631">
                    ITテクノロジーの本来の役割は、
                    <br />
                  </span>
                </p>
                <p className="text-49">
                  <span className="text-wrapper-631">
                    人々の暮らしの格差を無くすこと。
                    <br />
                  </span>
                </p>
                <p className="text-49">
                  <span className="text-wrapper-631">
                    私たちデジタルパズルはDX化の支援をすることで、
                    <br />
                  </span>
                </p>
                <p className="text-49">
                  <span className="text-wrapper-631">全ての人が快適に暮らせる社会創成を目指します。</span>
                </p>
              </div>
              <div className="flexcontainer-74">
                <p className="text-50">
                  <span className="text-wrapper-632">
                    世界中の全ての人たちがストレスなくITを使いこなし、
                    <br />
                  </span>
                </p>
                <p className="text-50">
                  <span className="text-wrapper-632">
                    快適に暮らせる世の中にするため、足りないピースを探し繋げていくのが
                    <br />
                  </span>
                </p>
                <p className="text-50">
                  <span className="text-wrapper-632">デジタルパズルの役目と考えています。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="view-12">
        <div className="component-16">
          <div className="text-wrapper-633">経営理念</div>
          <div className="TEXTEXT-13">MANAGEMENT PHILOSOPHY</div>
        </div>
        <div className="text-wrapper-634">晴耕雨読に生きる</div>
        <div className="flexcontainer-74">
          <p className="text-51">
            <span className="text-wrapper-632">
              状況や環境に合わせてのびのびと心穏やかに業務を遂行することが、
              <br />
            </span>
          </p>
          <p className="text-51">
            <span className="text-wrapper-632">結果として正しい判断を生み、ひいては成功につながる。</span>
          </p>
        </div>
        <img className="ill-5" alt="Ill" src="/img/ill-03-1.png" />
      </div>
      <div className="view-13">
        <div className="component-17">
          <div className="text-wrapper-635">社訓</div>
          <div className="TEXTEXT-14">MOTTO</div>
        </div>
        <div className="view-11">
          <div className="frame-484">
            <div className="frame-485">
              <div className="text-wrapper-636">不平不満は互いに伝え合い調整できる</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                厳重なルールを作らなくとも社員が不満を一人で抱えず率直に提案・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-485">
              <div className="text-wrapper-636">謙虚であること</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                謙虚でないと得られない学びは世の中にたくさんある。たとえ相手が子供だろうが赤ちゃんだろうが、何事からでも学ぶという姿勢が大事。・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-485">
              <div className="text-wrapper-636">大事な判断が必要な時こそ睡眠をしっかりとること</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                時間を惜しんで徹夜で対応しても決して良い結果にはならない。大事な判断をする時はしっかり睡眠をとって適切な判断ができる状態で対応するべきである。
              </div>
            </div>
            <div className="frame-485">
              <div className="text-wrapper-636">健康第一</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                正しい判断をするためには心身ともに健康であること。人として根本的に大切にするべきことを忘れないで欲しい。
              </div>
            </div>
            <div className="frame-485">
              <div className="text-wrapper-636">隣人を大切にすること</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                隣にいるからこそ気づいてあげられることがある。目の届く範囲にいる人に対して思いやりを持って接することでお互いが幸せになれる。
              </div>
            </div>
            <div className="frame-485">
              <div className="text-wrapper-636">無知は罪</div>
              <LineWrapper className="line-26" line="/img/line-2-7.svg" lineClassName="line-26" />
              <div className="text-wrapper-637">
                学問に限らず、世の中のあらゆる情報を積極的に得ておくことが非常に重要。自分には関係ないことと思い込み、見過ごしてしまうとお客様だけでなく自分自身をも守れなくなる。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-486">
        <div className="group-96">
          <div className="frame-487">
            <div className="group-97">
              <div className="text-wrapper-638">お問い合わせ</div>
              <div className="text-wrapper-639">CONTACT</div>
            </div>
            <div className="group-98">
              <div className="flexcontainer-75">
                <p className="text-52">
                  <span className="text-wrapper-632">
                    制作のご依頼・ご相談・ご質問など
                    <br />
                  </span>
                </p>
                <p className="text-52">
                  <span className="text-wrapper-632">お気軽にお問い合わせください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-26">
        <div className="frame-488">
          <div className="frame-489">
            <LogoWhite logoWhite="/img/image.svg" logoWhiteClassName="logo-white-35" />
            <div className="flexcontainer-74">
              <p className="text-i-66">
                <span className="text-wrapper-640">
                  千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                </span>
              </p>
              <p className="text-i-66">
                <span className="text-wrapper-640">幕張ベイパーク　クロスレジデンスＳ２－１</span>
              </p>
            </div>
          </div>
          <div className="frame-490">
            <div className="frame-491">
              <p className="text-wrapper-641">
                理念
                <br />- 企業理念 <br />- 経営理念
                <br />- 社訓
              </p>
              <div className="text-wrapper-642">
                会社概要
                <br />- 代表挨拶 <br />- 概要
              </div>
              <div className="text-wrapper-642">
                制作について
                <br />- 業務内容
                <br />- ご相談の流れ
              </div>
            </div>
            <div className="frame-491">
              <div className="text-wrapper-643">ブログ</div>
              <div className="text-wrapper-644">採用情報</div>
              <div className="text-wrapper-644">よくある質問</div>
              <div className="text-wrapper-644">情報セキュリティ基本方針</div>
              <div className="text-wrapper-644">プライバシーポリシー</div>
            </div>
          </div>
        </div>
        <div className="group-99">
          <div className="text-wrapper-645">©︎ Digital Puzzle Inc</div>
        </div>
      </footer>
    </div>
  );
};
