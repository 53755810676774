/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Polygon35 = ({ className }) => {
  return (
    <svg
      className={`polygon-3-5 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 11 18"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M10.0003 1L0.762696 9L10.0003 17" stroke="#454545" />
    </svg>
  );
};
