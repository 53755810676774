/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Line21 = ({ className }) => {
  return (
    <svg
      className={`line-21 ${className}`}
      fill="none"
      height="2"
      viewBox="0 0 250 2"
      width="250"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 1H70" stroke="#6079C1" strokeWidth="2" />
      <path className="path" d="M70 1.00007L250 1.00005" stroke="#454545" strokeWidth="2" />
    </svg>
  );
};
