/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWhite9 = ({ className }) => {
  return (
    <svg
      className={`logo-white-9 ${className}`}
      fill="none"
      height="55"
      viewBox="0 0 164 55"
      width="164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_399_4188)">
        <path
          className="path"
          d="M26.0644 31.6201C28.2285 31.6201 29.9829 30.0531 29.9829 28.1201C29.9829 26.1871 28.2285 24.6201 26.0644 24.6201C23.9002 24.6201 22.1458 26.1871 22.1458 28.1201C22.1458 30.0531 23.9002 31.6201 26.0644 31.6201Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0643 18.4299C27.8513 18.4299 29.2999 17.136 29.2999 15.5399C29.2999 13.9438 27.8513 12.6499 26.0643 12.6499C24.2773 12.6499 22.8286 13.9438 22.8286 15.5399C22.8286 17.136 24.2773 18.4299 26.0643 18.4299Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2457 24.8698C40.0326 24.8698 41.4813 23.5759 41.4813 21.9798C41.4813 20.3837 40.0326 19.0898 38.2457 19.0898C36.4587 19.0898 35.01 20.3837 35.01 21.9798C35.01 23.5759 36.4587 24.8698 38.2457 24.8698Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2457 37.4602C40.0326 37.4602 41.4813 36.1663 41.4813 34.5702C41.4813 32.9741 40.0326 31.6802 38.2457 31.6802C36.4587 31.6802 35.01 32.9741 35.01 34.5702C35.01 36.1663 36.4587 37.4602 38.2457 37.4602Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0643 43.5998C27.8513 43.5998 29.2999 42.3059 29.2999 40.7098C29.2999 39.1137 27.8513 37.8198 26.0643 37.8198C24.2773 37.8198 22.8286 39.1137 22.8286 40.7098C22.8286 42.3059 24.2773 43.5998 26.0643 43.5998Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.8831 24.8698C15.6701 24.8698 17.1187 23.5759 17.1187 21.9798C17.1187 20.3837 15.6701 19.0898 13.8831 19.0898C12.0961 19.0898 10.6475 20.3837 10.6475 21.9798C10.6475 23.5759 12.0961 24.8698 13.8831 24.8698Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.2862 37.4602C16.0732 37.4602 17.5218 36.1663 17.5218 34.5702C17.5218 32.9741 16.0732 31.6802 14.2862 31.6802C12.4992 31.6802 11.0505 32.9741 11.0505 34.5702C11.0505 36.1663 12.4992 37.4602 14.2862 37.4602Z"
          fill="white"
        />
        <path
          className="path"
          d="M25.8851 4.62014C26.961 4.62014 27.8332 3.84111 27.8332 2.88014C27.8332 1.91916 26.961 1.14014 25.8851 1.14014C24.8092 1.14014 23.937 1.91916 23.937 2.88014C23.937 3.84111 24.8092 4.62014 25.8851 4.62014Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0643 54.2598C27.1402 54.2598 28.0124 53.4808 28.0124 52.5198C28.0124 51.5588 27.1402 50.7798 26.0643 50.7798C24.9884 50.7798 24.1162 51.5588 24.1162 52.5198C24.1162 53.4808 24.9884 54.2598 26.0643 54.2598Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 17.2798C51.0885 17.2798 51.9607 16.5008 51.9607 15.5398C51.9607 14.5788 51.0885 13.7998 50.0126 13.7998C48.9366 13.7998 48.0645 14.5788 48.0645 15.5398C48.0645 16.5008 48.9366 17.2798 50.0126 17.2798Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 42.2998C51.0885 42.2998 51.9607 41.5208 51.9607 40.5598C51.9607 39.5989 51.0885 38.8198 50.0126 38.8198C48.9366 38.8198 48.0645 39.5989 48.0645 40.5598C48.0645 41.5208 48.9366 42.2998 50.0126 42.2998Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.25035 17.2798C3.32626 17.2798 4.19845 16.5008 4.19845 15.5398C4.19845 14.5788 3.32626 13.7998 2.25035 13.7998C1.17444 13.7998 0.302246 14.5788 0.302246 15.5398C0.302246 16.5008 1.17444 17.2798 2.25035 17.2798Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.38463 42.4502C3.46054 42.4502 4.33273 41.6712 4.33273 40.7102C4.33273 39.7492 3.46054 38.9702 2.38463 38.9702C1.30872 38.9702 0.436523 39.7492 0.436523 40.7102C0.436523 41.6712 1.30872 42.4502 2.38463 42.4502Z"
          fill="white"
        />
        <path
          className="path"
          d="M49.8781 29.9399C51.1209 29.9399 52.1285 29.04 52.1285 27.9299C52.1285 26.8198 51.1209 25.9199 49.8781 25.9199C48.6352 25.9199 47.6277 26.8198 47.6277 27.9299C47.6277 29.04 48.6352 29.9399 49.8781 29.9399Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2455 48.29C39.4884 48.29 40.4959 47.3901 40.4959 46.28C40.4959 45.1699 39.4884 44.27 38.2455 44.27C37.0027 44.27 35.9951 45.1699 35.9951 46.28C35.9951 47.3901 37.0027 48.29 38.2455 48.29Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.286 48.29C15.5289 48.29 16.5364 47.3901 16.5364 46.28C16.5364 45.1699 15.5289 44.27 14.286 44.27C13.0432 44.27 12.0356 45.1699 12.0356 46.28C12.0356 47.3901 13.0432 48.29 14.286 48.29Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.883 11.3901C15.1258 11.3901 16.1334 10.4902 16.1334 9.38012C16.1334 8.27003 15.1258 7.37012 13.883 7.37012C12.6401 7.37012 11.6326 8.27003 11.6326 9.38012C11.6326 10.4902 12.6401 11.3901 13.883 11.3901Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.2504 29.9399C3.49326 29.9399 4.50079 29.04 4.50079 27.9299C4.50079 26.8198 3.49326 25.9199 2.2504 25.9199C1.00754 25.9199 0 26.8198 0 27.9299C0 29.04 1.00754 29.9399 2.2504 29.9399Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2455 11.3901C39.4884 11.3901 40.4959 10.4902 40.4959 9.38012C40.4959 8.27003 39.4884 7.37012 38.2455 7.37012C37.0027 7.37012 35.9951 8.27003 35.9951 9.38012C35.9951 10.4902 37.0027 11.3901 38.2455 11.3901Z"
          fill="white"
        />
        <path
          className="path"
          d="M27.4303 30.48C29.5945 30.48 31.3489 28.913 31.3489 26.98C31.3489 25.047 29.5945 23.48 27.4303 23.48C25.2661 23.48 23.5117 25.047 23.5117 26.98C23.5117 28.913 25.2661 30.48 27.4303 30.48Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.4302 17.28C29.2172 17.28 30.6659 15.9861 30.6659 14.39C30.6659 12.7939 29.2172 11.5 27.4302 11.5C25.6432 11.5 24.1946 12.7939 24.1946 14.39C24.1946 15.9861 25.6432 17.28 27.4302 17.28Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6114 23.7302C41.3984 23.7302 42.847 22.4363 42.847 20.8402C42.847 19.2441 41.3984 17.9502 39.6114 17.9502C37.8244 17.9502 36.3757 19.2441 36.3757 20.8402C36.3757 22.4363 37.8244 23.7302 39.6114 23.7302Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6114 36.32C41.3984 36.32 42.847 35.0261 42.847 33.43C42.847 31.8339 41.3984 30.54 39.6114 30.54C37.8244 30.54 36.3757 31.8339 36.3757 33.43C36.3757 35.0261 37.8244 36.32 39.6114 36.32Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.4302 42.4602C29.2172 42.4602 30.6659 41.1663 30.6659 39.5702C30.6659 37.9741 29.2172 36.6802 27.4302 36.6802C25.6432 36.6802 24.1946 37.9741 24.1946 39.5702C24.1946 41.1663 25.6432 42.4602 27.4302 42.4602Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2491 23.7302C17.0361 23.7302 18.4847 22.4363 18.4847 20.8402C18.4847 19.2441 17.0361 17.9502 15.2491 17.9502C13.4621 17.9502 12.0134 19.2441 12.0134 20.8402C12.0134 22.4363 13.4621 23.7302 15.2491 23.7302Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.6519 36.32C17.4389 36.32 18.8875 35.0261 18.8875 33.43C18.8875 31.8339 17.4389 30.54 15.6519 30.54C13.8649 30.54 12.4163 31.8339 12.4163 33.43C12.4163 35.0261 13.8649 36.32 15.6519 36.32Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.2511 3.48C28.327 3.48 29.1992 2.70098 29.1992 1.74C29.1992 0.779025 28.327 0 27.2511 0C26.1752 0 25.303 0.779025 25.303 1.74C25.303 2.70098 26.1752 3.48 27.2511 3.48Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.4303 53.1201C28.5062 53.1201 29.3784 52.3411 29.3784 51.3801C29.3784 50.4192 28.5062 49.6401 27.4303 49.6401C26.3544 49.6401 25.4822 50.4192 25.4822 51.3801C25.4822 52.3411 26.3544 53.1201 27.4303 53.1201Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3785 16.1299C52.4544 16.1299 53.3266 15.3509 53.3266 14.3899C53.3266 13.4289 52.4544 12.6499 51.3785 12.6499C50.3026 12.6499 49.4304 13.4289 49.4304 14.3899C49.4304 15.3509 50.3026 16.1299 51.3785 16.1299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3785 41.1602C52.4544 41.1602 53.3266 40.3811 53.3266 39.4202C53.3266 38.4592 52.4544 37.6802 51.3785 37.6802C50.3026 37.6802 49.4304 38.4592 49.4304 39.4202C49.4304 40.3811 50.3026 41.1602 51.3785 41.1602Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62755 16.1299C4.70346 16.1299 5.57565 15.3509 5.57565 14.3899C5.57565 13.4289 4.70346 12.6499 3.62755 12.6499C2.55164 12.6499 1.67944 13.4289 1.67944 14.3899C1.67944 15.3509 2.55164 16.1299 3.62755 16.1299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.76182 41.3101C4.83773 41.3101 5.70993 40.5311 5.70993 39.5701C5.70993 38.6091 4.83773 37.8301 3.76182 37.8301C2.68592 37.8301 1.81372 38.6091 1.81372 39.5701C1.81372 40.5311 2.68592 41.3101 3.76182 41.3101Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.2441 28.7998C52.4869 28.7998 53.4944 27.8999 53.4944 26.7898C53.4944 25.6797 52.4869 24.7798 51.2441 24.7798C50.0012 24.7798 48.9937 25.6797 48.9937 26.7898C48.9937 27.8999 50.0012 28.7998 51.2441 28.7998Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6115 47.1499C40.8543 47.1499 41.8619 46.25 41.8619 45.1399C41.8619 44.0298 40.8543 43.1299 39.6115 43.1299C38.3686 43.1299 37.3611 44.0298 37.3611 45.1399C37.3611 46.25 38.3686 47.1499 39.6115 47.1499Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.652 47.1499C16.8949 47.1499 17.9024 46.25 17.9024 45.1399C17.9024 44.0298 16.8949 43.1299 15.652 43.1299C14.4091 43.1299 13.4016 44.0298 13.4016 45.1399C13.4016 46.25 14.4091 47.1499 15.652 47.1499Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2489 10.2402C16.4918 10.2402 17.4993 9.34031 17.4993 8.23021C17.4993 7.12012 16.4918 6.22021 15.2489 6.22021C14.0061 6.22021 12.9985 7.12012 12.9985 8.23021C12.9985 9.34031 14.0061 10.2402 15.2489 10.2402Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62759 28.7998C4.87045 28.7998 5.87799 27.8999 5.87799 26.7898C5.87799 25.6797 4.87045 24.7798 3.62759 24.7798C2.38473 24.7798 1.3772 25.6797 1.3772 26.7898C1.3772 27.8999 2.38473 28.7998 3.62759 28.7998Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6115 10.2402C40.8543 10.2402 41.8619 9.34031 41.8619 8.23021C41.8619 7.12012 40.8543 6.22021 39.6115 6.22021C38.3686 6.22021 37.3611 7.12012 37.3611 8.23021C37.3611 9.34031 38.3686 10.2402 39.6115 10.2402Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M69.4375 2.06982H75.9872C78.7862 2.06982 80.9134 2.62982 82.3801 3.75982C83.8468 4.88982 84.5745 6.51982 84.5745 8.65982C84.5745 10.7998 83.7908 12.3898 82.2457 13.5298C80.6895 14.6698 78.4727 15.2498 75.5953 15.2498H69.4375V2.06982ZM81.4396 8.62982C81.4396 5.74982 79.6259 4.30982 76.0096 4.30982H72.4604V12.9798H75.6849C79.5251 12.9798 81.4508 11.5298 81.4508 8.62982H81.4396Z"
          fill="white"
        />
        <path className="path" d="M87.5527 2.06982H90.6092V15.2398H87.5527V2.06982Z" fill="white" />
        <path
          className="path"
          d="M93.5762 8.71023C93.5762 7.25023 93.9232 6.00023 94.6286 4.96023C95.3339 3.92023 96.308 3.12023 97.5731 2.57023C98.8383 2.02023 100.294 1.74023 101.94 1.74023C103.91 1.74023 105.489 2.10023 106.664 2.83023C107.84 3.56023 108.646 4.53023 109.06 5.74023L106.004 6.09023C105.701 5.37023 105.209 4.83023 104.526 4.48023C103.843 4.14023 102.947 3.96023 101.85 3.96023C100.182 3.96023 98.9055 4.38023 98.0322 5.21023C97.1477 6.05023 96.711 7.20023 96.711 8.66023C96.711 10.1202 97.1925 11.3302 98.1441 12.1302C99.0958 12.9302 100.473 13.3302 102.264 13.3302C103.944 13.3302 105.366 13.0802 106.541 12.5902V10.1902H102.309V8.10023H109.374V13.7502C108.467 14.3302 107.392 14.7702 106.149 15.0902C104.907 15.4002 103.619 15.5602 102.264 15.5602C100.462 15.5602 98.9055 15.2902 97.6067 14.7402C96.308 14.1902 95.3116 13.4102 94.6174 12.3902C93.9232 11.3702 93.5762 10.1402 93.5762 8.70023V8.71023Z"
          fill="white"
        />
        <path className="path" d="M112.475 2.06982H115.532V15.2398H112.475V2.06982Z" fill="white" />
        <path
          className="path"
          d="M123.503 4.35982H117.659V2.06982H132.381V4.35982H126.56V15.2398H123.503V4.35982Z"
          fill="white"
        />
        <path
          className="path"
          d="M134.889 15.2398H131.732L138.551 2.06982H141.954L148.795 15.2398H145.537L143.969 12.1198H136.434L134.901 15.2398H134.889ZM140.208 4.38982L137.42 9.99982H142.973L140.208 4.38982Z"
          fill="white"
        />
        <path
          className="path"
          d="M150.933 2.06982H153.99V12.9498H163.115L162.723 15.2398H150.933V2.06982Z"
          fill="white"
        />
        <path
          className="path"
          d="M69.4375 20.5098H76.5582C80.6335 20.5098 82.6712 21.9398 82.6712 24.7898C82.6712 26.2698 82.145 27.3598 81.0925 28.0598C80.0401 28.7698 78.4503 29.1198 76.3118 29.1198H72.4268V33.6798H69.4375V20.5098ZM79.6147 24.7698C79.6147 24.0498 79.3572 23.5198 78.8309 23.1798C78.3047 22.8398 77.4874 22.6798 76.3566 22.6798H72.438V26.9598H76.323C77.4538 26.9598 78.2823 26.7798 78.8197 26.4198C79.3572 26.0598 79.6259 25.5098 79.6259 24.7598L79.6147 24.7698Z"
          fill="white"
        />
        <path
          className="path"
          d="M85.7278 28.19V20.52H88.7843V28.36C88.7843 29.52 89.109 30.37 89.7807 30.91C90.4413 31.46 91.4713 31.73 92.882 31.73C94.2927 31.73 95.3228 31.46 95.9833 30.91C96.6439 30.36 96.9798 29.51 96.9798 28.36V20.52H100.036V28.19C100.036 30.19 99.4317 31.67 98.2225 32.61C97.0133 33.55 95.2332 34.02 92.8932 34.02C90.5533 34.02 88.7619 33.55 87.5527 32.61C86.3436 31.67 85.7502 30.2 85.7502 28.19H85.7278Z"
          fill="white"
        />
        <path
          className="path"
          d="M102.768 31.69L112.419 22.73H102.958V20.52H116.259V22.51L106.619 31.47H116.64L116.248 33.68H102.779V31.69H102.768Z"
          fill="white"
        />
        <path
          className="path"
          d="M118.566 31.69L128.217 22.73H118.756V20.52H132.057V22.51L122.417 31.47H132.438L132.046 33.68H118.577V31.69H118.566Z"
          fill="white"
        />
        <path
          className="path"
          d="M135.998 20.5098H139.054V31.3898H148.179L147.787 33.6798H135.998V20.5098Z"
          fill="white"
        />
        <path
          className="path"
          d="M150.978 20.5098H163.204V22.6498H153.99V25.8598H162.745V27.9998H153.99V31.5398H163.652L163.282 33.6798H150.978V20.5098Z"
          fill="white"
        />
        <path className="path" d="M69.4375 38.96H72.494V52.13H69.4375V38.96Z" fill="white" />
        <path
          className="path"
          d="M77.0396 38.96H79.8497L88.6274 48.4V38.96H91.5608V52.13H88.7506L79.9729 42.67V52.13H77.0396V38.96Z"
          fill="white"
        />
        <path
          className="path"
          d="M94.9421 45.5499C94.9421 44.1999 95.2668 42.9999 95.9162 41.9599C96.5656 40.9199 97.4948 40.1099 98.7264 39.5199C99.9579 38.9299 101.413 38.6299 103.126 38.6299C107.157 38.6299 109.62 40.1199 110.505 43.1099L107.493 43.4399C107.146 42.5399 106.631 41.8999 105.948 41.4999C105.254 41.1099 104.313 40.9099 103.115 40.9099C101.57 40.9099 100.361 41.3199 99.4653 42.1399C98.5808 42.9599 98.133 44.0899 98.133 45.5399C98.133 46.9899 98.5808 48.1299 99.4765 48.9399C100.372 49.7599 101.615 50.1699 103.194 50.1699C104.492 50.1699 105.5 49.9599 106.228 49.5299C106.955 49.1099 107.437 48.4399 107.672 47.5499H110.751C110.516 49.1199 109.743 50.3399 108.411 51.1799C107.079 52.0199 105.332 52.4499 103.149 52.4499C101.413 52.4499 99.9355 52.1599 98.7152 51.5799C97.4948 50.9999 96.5656 50.1899 95.9162 49.1499C95.2668 48.1099 94.9421 46.8999 94.9421 45.5299V45.5499Z"
          fill="white"
        />
        <path className="path" d="M113.158 49.3301H116.114V52.1301H113.158V49.3301Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_399_4188">
          <rect className="rect" fill="white" height="54.26" width="163.652" />
        </clipPath>
      </defs>
    </svg>
  );
};
