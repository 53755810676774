/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Menu17 = ({ className }) => {
  return (
    <svg
      className={`menu-17 ${className}`}
      fill="none"
      height="35"
      viewBox="0 0 36 35"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 0H36V35H8.3692C3.74966 35 0 31.3152 0 26.7756V0Z" fill="#6079C1" />
      <path
        className="path"
        d="M12.1396 10.2942L23.8606 21.8236"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M23.8604 10.2942L12.1394 21.8236"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
