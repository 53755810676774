/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Qaicon } from "../../icons/Qaicon";
import { Qaicon4 } from "../../icons/Qaicon4";
import { Triangle12 } from "../../icons/Triangle12";
import { Triangle13 } from "../../icons/Triangle13";
import "./style.css";

export const QA = ({
  open,
  device,
  className,
  frameClassName,
  icon = <Qaicon className="q-aicon" />,
  frameClassNameOverride,
  triangle12StyleOverrideClassName,
  divClassName,
  override = <Qaicon4 className="q-aicon" />,
  divClassNameOverride,
  icon1 = <Qaicon className="q-aicon" />,
  frameClassName1,
}) => {
  return (
    <div className={`q-a device-0-${device} open-${open} ${className}`}>
      <div className="frame-6">
        {open === "open" && (
          <>
            <div className={`frame-7 ${frameClassName}`}>
              {icon}
              <div className={`frame-8 ${frameClassNameOverride}`}>
                <div className="text-wrapper-7">着手前に料金は発生しますか？</div>
              </div>
            </div>
            <Triangle12 className={triangle12StyleOverrideClassName} />
          </>
        )}

        {open === "one" && (
          <>
            <div className={`frame-9 ${divClassNameOverride}`}>
              {icon1}
              <div className={`frame-10 ${frameClassName1}`}>
                <div className="text-wrapper-8">着手前に料金は発生しますか？</div>
              </div>
            </div>
            <Triangle13 className={`${device === "SP" ? "class-3" : "class-4"}`} />
          </>
        )}
      </div>
      {open === "open" && (
        <div className="frame-11">
          <div className={`frame-12 ${divClassName}`}>
            {override}
            <div className="frame-13">
              <div className="div-3">
                {device === "PC" && (
                  <>
                    <p className="span-wrapper-2">
                      <span className="span">
                        テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                      </span>
                    </p>
                    <p className="span-wrapper-2">
                      <span className="span">テキストテキストテキストテキストテキストテキストテキスト</span>
                    </p>
                  </>
                )}

                {device === "SP" && (
                  <>
                    テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QA.propTypes = {
  open: PropTypes.oneOf(["open", "one"]),
  device: PropTypes.oneOf(["PC", "SP"]),
};
