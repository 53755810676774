/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Process = ({
  one,
  className,
  frameClassName,
  divClassName,
  image = "/img/image-1-4.png",
  text = "Process 01",
  text1 = "お問い合わせ",
  frameClassNameOverride,
  imageClassName,
  divClassNameOverride,
}) => {
  return (
    <div className={`process one-2-${one} ${className}`}>
      <div className={`image-wrapper ${frameClassNameOverride}`}>
        <img className={`image ${imageClassName}`} alt="Image" src={image} />
      </div>
      <div className={`frame-14 ${frameClassName}`}>
        <div className={`text-wrapper-9 ${divClassNameOverride}`}>{text}</div>
        <div className={`text-wrapper-10 ${divClassName}`}>{text1}</div>
      </div>
    </div>
  );
};

Process.propTypes = {
  one: PropTypes.oneOf(["two", "tab", "one"]),
  image: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
};
