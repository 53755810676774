/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Menu = ({ className }) => {
  return (
    <svg
      className={`menu ${className}`}
      fill="none"
      height="59"
      viewBox="0 0 60 59"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 0H60V59H13.9487C6.24944 59 0 52.7885 0 45.1361V0Z" fill="#6079C1" />
      <path className="path" d="M17.4419 20.1294H44.6512" stroke="white" strokeMiterlimit="10" strokeWidth="3" />
      <path className="path" d="M17.4419 29.153H44.6512" stroke="white" strokeMiterlimit="10" strokeWidth="3" />
      <path className="path" d="M17.4419 38.8706H44.6512" stroke="white" strokeMiterlimit="10" strokeWidth="3" />
    </svg>
  );
};
