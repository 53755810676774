import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading } from "../../components/Heading";
import { LineWrapper } from "../../components/LineWrapper";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Philosophy = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="philosophy"
      style={{
        minWidth:
          screenWidth < 600
            ? "320px"
            : screenWidth >= 600 && screenWidth < 1280
            ? "600px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && (
        <SpWhite className="header-20" icon={<Menu3 className="menu-24-instance" />} />
      )}

      {screenWidth >= 1280 && (
        <ColorWhite className="header-21" groupClassName="header-22" menuStyleOverrideClassName="header-23" />
      )}

      <div className="div-17">
        <div className="midashi-4">
          <ElementWrapper
            className={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-91"} ${
              screenWidth >= 1280 && "class-92"
            }`}
            headingDivClassName={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-87"} ${
              screenWidth >= 1280 && "class-88"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-89"
            } ${screenWidth >= 1280 && "class-90"}`}
            headingText="PHILOSOPHY"
            headingText1="理念"
            one={
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "two"
                : screenWidth >= 1280
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-7"
            style={{
              padding:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "10px 15px 20px"
                  : screenWidth >= 1280
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-225"
              style={{
                gap:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "17px"
                    : screenWidth >= 1280
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 600 && screenWidth < 1280 ? "0px 10px" : screenWidth >= 1280 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && (
                <>
                  <Link className="text-wrapper-240" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-7" />
                </>
              )}

              <div
                className="TOP-3"
                style={{
                  fontFamily:
                    screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1280 && <>TOP</>}

                {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && <>理念</>}
              </div>
              {screenWidth >= 1280 && (
                <>
                  <Twelve className="instance-node-7" />
                  <div className="text-wrapper-241">理念</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="corporate"
          style={{
            backgroundImage:
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "url(/img/corporate.png)"
                : screenWidth >= 1280
                ? "url(/img/corporate-1-2.png)"
                : undefined,
            padding:
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "40px 20px"
                : screenWidth >= 1280
                ? "100px 15px"
                : undefined,
          }}
        >
          <div
            className="frame-226"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "stretch" : undefined,
              display:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "flex"
                  : screenWidth >= 1280
                  ? "inline-flex"
                  : undefined,
              gap:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "25px"
                  : screenWidth >= 1280
                  ? "50px"
                  : undefined,
              minWidth: screenWidth >= 1280 ? "1000px" : undefined,
              padding:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "20px 16px 30px"
                  : screenWidth >= 1280
                  ? "80px 0px"
                  : undefined,
              width: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "100%" : undefined,
            }}
          >
            <Heading
              className="heading-28"
              divClassName={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-95"} ${
                screenWidth >= 1280 && "class-96"
              }`}
              divClassNameOverride={`${
                ((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-93"
              } ${screenWidth >= 1280 && "class-94"}`}
              one={
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "SP"
                  : screenWidth >= 1280
                  ? "one"
                  : undefined
              }
              text="CORPORATE PHILOSOPHY"
              text1="企業理念"
            />
            <div
              className="flexcontainer-26"
              style={{
                display:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "flex"
                    : screenWidth >= 1280
                    ? "inline-flex"
                    : undefined,
              }}
            >
              <p
                className="text-10"
                style={{
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "15px"
                      : screenWidth >= 1280
                      ? "24px"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "0.30px"
                      : screenWidth >= 1280
                      ? "1.44px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "24px"
                      : screenWidth >= 1280
                      ? "43px"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.04px"
                        : screenWidth >= 1280
                        ? "0.35px"
                        : undefined,
                  }}
                >
                  ITテクノロジーの本来の役割は、
                  <br />
                </span>
              </p>
              <p
                className="text-11"
                style={{
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "15px"
                      : screenWidth >= 1280
                      ? "24px"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "0.30px"
                      : screenWidth >= 1280
                      ? "1.44px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "24px"
                      : screenWidth >= 1280
                      ? "43px"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.04px"
                        : screenWidth >= 1280
                        ? "0.35px"
                        : undefined,
                  }}
                >
                  人々の暮らしの格差を無くすこと。
                  <br />
                </span>
              </p>
              <p
                className="text-12"
                style={{
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "15px"
                      : screenWidth >= 1280
                      ? "24px"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "0.30px"
                      : screenWidth >= 1280
                      ? "1.44px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "24px"
                      : screenWidth >= 1280
                      ? "43px"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.04px"
                        : screenWidth >= 1280
                        ? "0.35px"
                        : undefined,
                  }}
                >
                  私たちデジタルパズルはDX化の支援をすることで、
                  <br />
                </span>
              </p>
              <p
                className="text-13"
                style={{
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "15px"
                      : screenWidth >= 1280
                      ? "24px"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "0.30px"
                      : screenWidth >= 1280
                      ? "1.44px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "24px"
                      : screenWidth >= 1280
                      ? "43px"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.04px"
                        : screenWidth >= 1280
                        ? "0.35px"
                        : undefined,
                  }}
                >
                  全ての人が快適に暮らせる社会創成を目指します。
                </span>
              </p>
            </div>
            <div
              className="flexcontainer-27"
              style={{
                display:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "flex"
                    : screenWidth >= 1280
                    ? "inline-flex"
                    : undefined,
                gap:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "2px"
                    : screenWidth >= 1280
                    ? "5px"
                    : undefined,
              }}
            >
              <p
                className="text-14"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--text-line-height)"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1280
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  世界中の全ての人たちがストレスなくITを使いこなし、
                  <br />
                </span>
              </p>
              <p
                className="text-15"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--text-line-height)"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1280
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  快適に暮らせる世の中にするため、足りないピースを探し繋げていくのが
                  <br />
                </span>
              </p>
              <p
                className="text-16"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--text-line-height)"
                      : undefined,
                  textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1280
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  デジタルパズルの役目と考えています。
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className="management"
          style={{
            gap:
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "20px"
                : screenWidth >= 1280
                ? "50px"
                : undefined,
            padding:
              screenWidth < 600
                ? "40px 15px"
                : screenWidth >= 600 && screenWidth < 1280
                ? "40px"
                : screenWidth >= 1280
                ? "80px 15px"
                : undefined,
          }}
        >
          <Heading
            className="heading-29"
            divClassName={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-98"} ${
              screenWidth >= 1280 && "class-96"
            }`}
            divClassNameOverride={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-93"} ${
              screenWidth >= 1280 && "class-97"
            }`}
            one={
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "SP"
                : screenWidth >= 1280
                ? "one"
                : undefined
            }
            text="MANAGEMENT PHILOSOPHY"
            text1="経営理念"
          />
          <div
            className="text-wrapper-242"
            style={{
              fontFamily:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-font-family)"
                  : screenWidth >= 1280
                  ? "'Noto Sans JP', Helvetica"
                  : undefined,
              fontSize:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-font-size)"
                  : screenWidth >= 1280
                  ? "24px"
                  : undefined,
              fontStyle:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-font-style)"
                  : undefined,
              fontWeight:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-font-weight)"
                  : screenWidth >= 1280
                  ? "700"
                  : undefined,
              letterSpacing:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-letter-spacing)"
                  : screenWidth >= 1280
                  ? "1.44px"
                  : undefined,
              lineHeight:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "var(--bold-SP-line-height)"
                  : screenWidth >= 1280
                  ? "43px"
                  : undefined,
              whiteSpace: screenWidth >= 1280 ? "nowrap" : undefined,
            }}
          >
            晴耕雨読に生きる
          </div>
          <div
            className="flexcontainer-28"
            style={{
              display:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "flex"
                  : screenWidth >= 1280
                  ? "inline-flex"
                  : undefined,
              gap:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "3px"
                  : screenWidth >= 1280
                  ? "5px"
                  : undefined,
            }}
          >
            <p
              className="text-17"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-family)"
                    : screenWidth >= 1280
                    ? "var(--text-font-family)"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-size)"
                    : screenWidth >= 1280
                    ? "var(--text-font-size)"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-style)"
                    : screenWidth >= 1280
                    ? "var(--text-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-weight)"
                    : screenWidth >= 1280
                    ? "var(--text-font-weight)"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-letter-spacing)"
                    : screenWidth >= 1280
                    ? "var(--text-letter-spacing)"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-line-height)"
                    : screenWidth >= 1280
                    ? "var(--text-line-height)"
                    : undefined,
                textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                状況や環境に合わせてのびのびと心穏やかに業務を遂行することが、
                <br />
              </span>
            </p>
            <p
              className="text-18"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-family)"
                    : screenWidth >= 1280
                    ? "var(--text-font-family)"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-size)"
                    : screenWidth >= 1280
                    ? "var(--text-font-size)"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-style)"
                    : screenWidth >= 1280
                    ? "var(--text-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-font-weight)"
                    : screenWidth >= 1280
                    ? "var(--text-font-weight)"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-letter-spacing)"
                    : screenWidth >= 1280
                    ? "var(--text-letter-spacing)"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "var(--lead-SP-line-height)"
                    : screenWidth >= 1280
                    ? "var(--text-line-height)"
                    : undefined,
                textAlign: screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "center" : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--lead-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                結果として正しい判断を生み、ひいては成功につながる。
              </span>
            </p>
          </div>
          <img
            className="ill"
            style={{
              height:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "176px"
                  : screenWidth >= 1280
                  ? "376px"
                  : undefined,
              objectFit: screenWidth >= 1280 ? "cover" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "290px"
                  : screenWidth >= 1280
                  ? "632px"
                  : undefined,
            }}
            alt="Ill"
            src={
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "/img/ill-03.png"
                : screenWidth >= 1280
                ? "/img/ill-03-1.png"
                : undefined
            }
          />
        </div>
        <div
          className="motto"
          style={{
            gap:
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "20px"
                : screenWidth >= 1280
                ? "50px"
                : undefined,
            padding:
              screenWidth < 600
                ? "40px 15px"
                : screenWidth >= 600 && screenWidth < 1280
                ? "40px"
                : screenWidth >= 1280
                ? "80px 15px"
                : undefined,
          }}
        >
          <Heading
            className="heading-30"
            divClassName={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-101"} ${
              screenWidth >= 1280 && "class-102"
            }`}
            divClassNameOverride={`${((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && "class-99"} ${
              screenWidth >= 1280 && "class-100"
            }`}
            one={
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "SP"
                : screenWidth >= 1280
                ? "one"
                : undefined
            }
            text="MOTTO"
            text1="社訓"
          />
          <div
            className="mottos"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "stretch" : undefined,
              gap:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "30px"
                  : screenWidth >= 1280
                  ? "50px"
                  : undefined,
              maxWidth: screenWidth >= 1280 ? "1000px" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1280
                  ? "1000px"
                  : undefined,
            }}
          >
            <div className="frame-227">
              <div
                className="text-wrapper-243"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.88px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "43px"
                      : undefined,
                }}
              >
                不平不満は互いに伝え合い調整できる
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-244"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                厳重なルールを作らなくとも社員が不満を一人で抱えず率直に提案・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-227">
              <div
                className="text-wrapper-245"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "1.10px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "37px"
                      : undefined,
                }}
              >
                謙虚であること
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-246"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                謙虚でないと得られない学びは世の中にたくさんある。たとえ相手が子供だろうが赤ちゃんだろうが、何事からでも学ぶという姿勢が大事。・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-227">
              <div
                className="text-wrapper-247"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "1.10px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "37px"
                      : undefined,
                }}
              >
                大事な判断が必要な時こそ睡眠をしっかりとること
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-248"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                時間を惜しんで徹夜で対応しても決して良い結果にはならない。大事な判断をする時はしっかり睡眠をとって適切な判断ができる状態で対応するべきである。
              </div>
            </div>
            <div className="frame-227">
              <div
                className="text-wrapper-249"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "1.10px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "37px"
                      : undefined,
                }}
              >
                健康第一
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-250"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                正しい判断をするためには心身ともに健康であること。人として根本的に大切にするべきことを忘れないで欲しい。
              </div>
            </div>
            <div className="frame-227">
              <div
                className="text-wrapper-251"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "1.10px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "37px"
                      : undefined,
                }}
              >
                隣人を大切にすること
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-252"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                隣にいるからこそ気づいてあげられることがある。目の届く範囲にいる人に対して思いやりを持って接することでお互いが幸せになれる。
              </div>
            </div>
            <div className="frame-227">
              <div
                className="text-wrapper-253"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "22px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "700"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "1.10px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "37px"
                      : undefined,
                }}
              >
                無知は罪
              </div>
              <LineWrapper className="line-instance" line="/img/line-2-7.svg" lineClassName="line-instance" />
              <div
                className="text-wrapper-254"
                style={{
                  color:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "#454545"
                      : screenWidth >= 1280
                      ? "var(--darkgray)"
                      : undefined,
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1280
                      ? "'Noto Sans JP', Helvetica"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1280
                      ? "16px"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "400"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "0.80px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1280
                      ? "28px"
                      : undefined,
                }}
              >
                学問に限らず、世の中のあらゆる情報を積極的に得ておくことが非常に重要。自分には関係ないことと思い込み、見過ごしてしまうとお客様だけでなく自分自身をも守れなくなる。
              </div>
            </div>
          </div>
        </div>
        <div
          className="CONTACT-16"
          style={{
            alignSelf: screenWidth >= 1280 || screenWidth < 600 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "center" : undefined,
            padding:
              screenWidth < 600
                ? "80px 30px"
                : screenWidth >= 600 && screenWidth < 1280
                ? "40px 50px"
                : screenWidth >= 1280
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1280 || screenWidth < 600
                ? "100%"
                : screenWidth >= 600 && screenWidth < 1280
                ? "600px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-17"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "stretch" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1280
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-228"
              style={{
                gap:
                  screenWidth >= 1280 || screenWidth < 600
                    ? "20px 74px"
                    : screenWidth >= 600 && screenWidth < 1280
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 600 ? "-10px" : undefined,
                padding:
                  screenWidth < 600
                    ? "25px 60px"
                    : screenWidth >= 600 && screenWidth < 1280
                    ? "40px 20px"
                    : screenWidth >= 1280
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 600 ? "-3px" : screenWidth >= 600 && screenWidth < 1280 ? "14px" : undefined,
                width:
                  screenWidth < 600
                    ? "280px"
                    : screenWidth >= 600 && screenWidth < 1280
                    ? "500px"
                    : screenWidth >= 1280
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-31"
                style={{
                  height:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "53px"
                      : screenWidth >= 1280
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && (
                  <>
                    <div className="text-wrapper-255">お問い合わせ</div>
                    <div className="text-wrapper-256">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1280 && (
                  <div className="overlap-group-9">
                    <div className="text-wrapper-257">お問い合わせ</div>
                    <div className="text-wrapper-258">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-32"
                style={{
                  height:
                    screenWidth < 600
                      ? "43px"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "48px"
                      : screenWidth >= 1280
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "212px"
                      : screenWidth >= 1280
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-29"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "2px"
                        : screenWidth >= 1280
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 600
                        ? "43px"
                        : screenWidth >= 600 && screenWidth < 1280
                        ? "48px"
                        : screenWidth >= 1280
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "210px"
                        : screenWidth >= 1280
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-27"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-28"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-9"
          style={{
            padding:
              (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                ? "50px 20px"
                : screenWidth >= 1280
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-229"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "stretch" : undefined,
              flexDirection: screenWidth < 600 ? "column" : undefined,
              flexWrap: screenWidth >= 1280 ? "wrap" : undefined,
              gap: screenWidth < 600 ? "54px" : screenWidth >= 1280 ? "50px 150px" : undefined,
              justifyContent:
                screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280) ? "space-between" : undefined,
              padding: screenWidth >= 1280 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1280
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-230"
              style={{
                alignSelf: screenWidth < 600 ? "stretch" : undefined,
                flex: screenWidth < 600 ? "0 0 auto" : screenWidth >= 1280 ? "1" : undefined,
                flexGrow: screenWidth >= 1280 ? "1" : undefined,
                gap:
                  (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                    ? "24px"
                    : screenWidth >= 1280
                    ? "44px"
                    : undefined,
                width: screenWidth < 600 ? "100%" : screenWidth >= 600 && screenWidth < 1280 ? "205px" : undefined,
              }}
            >
              {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && (
                <LogoWhite22 className="logo-white-15" />
              )}

              {screenWidth >= 1280 && <LogoWhite30 className="logo-white-5" />}

              <div
                className="flexcontainer-30"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "3px"
                      : screenWidth >= 1280
                      ? "5px"
                      : undefined,
                }}
              >
                <p
                  className="text-i-29"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p
                  className="text-i-30"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <div
              className="frame-231"
              style={{
                alignSelf: screenWidth < 600 ? "stretch" : undefined,
                display:
                  screenWidth < 600
                    ? "flex"
                    : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                    ? "inline-flex"
                    : undefined,
                gap:
                  screenWidth < 600
                    ? "40px"
                    : screenWidth >= 600 && screenWidth < 1280
                    ? "30px"
                    : screenWidth >= 1280
                    ? "60px"
                    : undefined,
                width: screenWidth < 600 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-232"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                      ? "30px"
                      : screenWidth >= 1280
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-233"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-259"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-260"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-261"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-262"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-234"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-263"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/profile-600"
                  >
                    会社概要
                  </Link>
                  <div
                    className="text-wrapper-264"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-265"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-235"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-266"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/aboutcontents-744"
                  >
                    制作について
                  </Link>
                  <div
                    className="text-wrapper-267"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-268"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-236"
                style={{
                  alignSelf: (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600 ? "stretch" : undefined,
                  display:
                    screenWidth < 600
                      ? "flex"
                      : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                      ? "inline-flex"
                      : undefined,
                  flex:
                    screenWidth < 600
                      ? "1"
                      : screenWidth >= 1280 || (screenWidth >= 600 && screenWidth < 1280)
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: screenWidth < 600 ? "1" : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-269"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-270"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-271"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-272"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-273"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-274">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
