/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Menu10 } from "../../icons/Menu10";
import { Logo } from "../Logo";
import "./style.css";

export const SpWhite = ({ className, groupClassName, icon = <Menu10 className="menu-10" /> }) => {
  return (
    <div className={`SP-white ${className}`}>
      <div className={`group ${groupClassName}`}>
        <Logo className="logo-instance" />
      </div>
      {icon}
    </div>
  );
};
