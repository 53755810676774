/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Menu9 = ({ className }) => {
  return (
    <svg
      className={`menu-9 ${className}`}
      fill="none"
      height="70"
      viewBox="0 0 71 70"
      width="71"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 0H71V70H16.5059C7.39517 70 0 62.6305 0 53.5513V0Z" fill="#6079C1" />
      <path className="path" d="M23.9419 20.5881L47.0582 43.647" stroke="white" strokeMiterlimit="10" strokeWidth="3" />
      <path className="path" d="M47.0581 20.5881L23.9418 43.647" stroke="white" strokeMiterlimit="10" strokeWidth="3" />
    </svg>
  );
};
