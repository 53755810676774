/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Noimage } from "../../icons/Noimage";
import { Frame } from "../Frame";
import "./style.css";

export const Recruite = ({
  one,
  className,
  frameClassName,
  frameElementClassName,
  frameDivClassName,
  vector = "/img/vector.svg",
  divClassName,
  icon = <Noimage className="noimage-2" />,
  frameOne = "one",
}) => {
  return (
    <div className={`RECRUITE ${className}`}>
      <div className={`frame-19 ${frameClassName}`}>{icon}</div>
      <div className="frame-20">
        <Frame className={frameElementClassName} divClassName={frameDivClassName} one={frameOne} />
        <div className="frame-21">
          <div className="text-wrapper-14">2024/00/00</div>
        </div>
      </div>
      <div className="frame-22">
        <img className="vector-2" alt="Vector" src={vector} />
        <div className="frame-23">
          <div className={`text-wrapper-15 ${divClassName}`}>
            見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
          </div>
        </div>
      </div>
    </div>
  );
};

Recruite.propTypes = {
  one: PropTypes.oneOf(["one"]),
  vector: PropTypes.string,
  frameOne: PropTypes.string,
};
