/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame36 = ({ one, className, divClassName }) => {
  return (
    <div className={`frame-36 one-9-${one} ${className}`}>
      <div className={`text-wrapper-16 ${divClassName}`}>一覧へ</div>
    </div>
  );
};

Frame36.propTypes = {
  one: PropTypes.oneOf(["two", "three", "one"]),
};
