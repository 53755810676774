/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Freeicon = ({ one, className, divClassName, text = "無料" }) => {
  return (
    <div className={`freeicon one-11-${one} ${className}`}>
      <div className={`div-5 ${divClassName}`}>
        {one === "one" && <>{text}</>}

        {one === "two" && <>有料</>}
      </div>
    </div>
  );
};

Freeicon.propTypes = {
  one: PropTypes.oneOf(["two", "one"]),
  text: PropTypes.string,
};
