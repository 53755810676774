import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading02 } from "../../components/Heading02";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Line2 } from "../../components/Line2";
import { LogoWhite } from "../../components/LogoWhite";
import { OpenOpenWrapper } from "../../components/OpenOpenWrapper";
import { SpWhite } from "../../components/SpWhite";
import { Line21 } from "../../icons/Line21";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { Menu3 } from "../../icons/Menu3";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const InformationSecurity = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="information-security"
      style={{
        minWidth:
          screenWidth < 600
            ? "320px"
            : screenWidth >= 600 && screenWidth < 1200
            ? "600px"
            : screenWidth >= 1200
            ? "1200px"
            : undefined,
      }}
    >
      {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
        <SpWhite className="header-35" groupClassName="header-36" icon={<Menu3 className="menu-12" />} />
      )}

      {screenWidth >= 1200 && (
        <ColorWhite
          className="header-38"
          groupClassName="header-39"
          icon={<OpenOpenWrapper open="open" openOpenClassName="menu-instance" to="/menuopen-pc" />}
          logoLogoClassName="header-37"
        />
      )}

      <div className="blog-3">
        <div className="midashi-8">
          <ElementWrapper
            className={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-131"} ${
              screenWidth >= 1200 && "class-132"
            }`}
            headingDivClassName={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-127"} ${
              screenWidth >= 1200 && "class-128"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-129"
            } ${screenWidth >= 1200 && "class-130"}`}
            headingText="INFORMATION SECURITY BASIC POLICY"
            headingText1="情報セキュリティ基本方針"
            one={
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "two"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-11"
            style={{
              padding:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "10px 15px 20px"
                  : screenWidth >= 1200
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-294"
              style={{
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "17px"
                    : screenWidth >= 1200
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 600 && screenWidth < 1200 ? "0px 10px" : screenWidth >= 1200 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                <>
                  <Link className="text-wrapper-381" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-6" />
                </>
              )}

              <div
                className="TOP-6"
                style={{
                  fontFamily:
                    screenWidth >= 1200
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1200
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1200
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1200
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1200
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1200
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1200 && <>TOP</>}

                {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && <>情報セキュリティ基本方針</>}
              </div>
              {screenWidth >= 1200 && (
                <>
                  <ImgWrapper className="component-10930-3" />
                  <div className="text-wrapper-382">情報セキュリティ基本方針</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="security"
          style={{
            padding:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "30px 15px 40px"
                : screenWidth >= 1200
                ? "80px 15px"
                : undefined,
          }}
        >
          <div
            className="frame-295"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              gap:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "30px"
                  : screenWidth >= 1200
                  ? "40px"
                  : undefined,
              padding:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "20px"
                  : screenWidth >= 1200
                  ? "50px 60px"
                  : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1000px"
                  : undefined,
            }}
          >
            <div
              className="frame-296"
              style={{
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "20px"
                    : screenWidth >= 1200
                    ? "24px"
                    : undefined,
              }}
            >
              <div
                className="text-wrapper-383"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1200
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1200
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1200
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1200
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                デジタルパズル株式会社について、第一にお客様はもとより
              </div>
              <div className="frame-297">
                <div className="bulletpoint-2">
                  <div
                    className="group-40"
                    style={{
                      width:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "10px"
                          : screenWidth >= 1200
                          ? "17px"
                          : undefined,
                    }}
                  >
                    {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                      <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-1-1.svg" />
                    )}

                    {screenWidth >= 1200 && <div className="ellipse-6" />}
                  </div>
                  <div
                    className="text-wrapper-384"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    DXコンサルティング
                  </div>
                </div>
                <div className="bulletpoint-2">
                  <div
                    className="group-41"
                    style={{
                      width:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "10px"
                          : screenWidth >= 1200
                          ? "17px"
                          : undefined,
                    }}
                  >
                    {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                      <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-1-1.svg" />
                    )}

                    {screenWidth >= 1200 && <div className="ellipse-6" />}
                  </div>
                  <div
                    className="text-wrapper-385"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    顧客要求事項に基づく情報システムの設計、開発、運用、保守
                  </div>
                </div>
              </div>
              <div
                className="text-wrapper-386"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1200
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1200
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1200
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1200
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                に関わる全ての人々の満足を得る事を目標とし、信頼・安心される会社を目指す。
              </div>
            </div>
            <div className="frame-298">
              <Heading02
                className={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-133"} ${
                  screenWidth >= 1200 && "class-134"
                }`}
                icon={
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? (
                    <Line21 className="line-21" />
                  ) : undefined
                }
                one={
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "SP"
                    : screenWidth >= 1200
                    ? "one"
                    : undefined
                }
                text="行動指針"
              />
              <div
                className="flexcontainer-39"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "2px"
                      : screenWidth >= 1200
                      ? "5px"
                      : undefined,
                }}
              >
                <p
                  className="text-24"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    情報資産の機密性、完全性、可用性を確実に保護するために組織的、技術的に適切な対策を講じ、変化する情報技術や新たな脅威に対応する。
                    <br />
                  </span>
                </p>
                <p
                  className="text-25"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    全社員に情報セキュリティ教育の実施と方針の周知徹底をはかり、意識の高揚・維持に務める。 <br />
                  </span>
                </p>
                <p
                  className="text-26"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    マネジメントシステム及び、情報セキュリティに関する目的を設定し、定期的にレビューし、継続的に改善を実施し、維持する。
                    <br />
                  </span>
                </p>
                <p
                  className="text-27"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    マネジメントシステムを実行・維持・改善して行くために管理責任者に責任と権限を委譲する。
                  </span>
                </p>
              </div>
            </div>
            <div
              className="text-wrapper-387"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-font-family)"
                    : screenWidth >= 1200
                    ? "var(--text-font-family)"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-font-size)"
                    : screenWidth >= 1200
                    ? "var(--text-font-size)"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-font-style)"
                    : screenWidth >= 1200
                    ? "var(--text-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-font-weight)"
                    : screenWidth >= 1200
                    ? "var(--text-font-weight)"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-letter-spacing)"
                    : screenWidth >= 1200
                    ? "var(--text-letter-spacing)"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--text-SP-line-height)"
                    : screenWidth >= 1200
                    ? "var(--text-line-height)"
                    : undefined,
              }}
            >
              2023年12月18日
            </div>
            <Line2
              line={(screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "/img/line-2-30.svg" : undefined}
            />
            <div
              className="flexcontainer-40"
              style={{
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "2px"
                    : screenWidth >= 1200
                    ? "5px"
                    : undefined,
              }}
            >
              <p
                className="text-28"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1200
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1200
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1200
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1200
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  デジタルパズル株式会社 <br />
                </span>
              </p>
              <p
                className="text-29"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1200
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1200
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1200
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1200
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  代表取締役
                  <br />
                </span>
              </p>
              <p
                className="text-30"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-family)"
                      : screenWidth >= 1200
                      ? "var(--text-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-size)"
                      : screenWidth >= 1200
                      ? "var(--text-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-style)"
                      : screenWidth >= 1200
                      ? "var(--text-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--text-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--text-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--text-SP-line-height)"
                      : screenWidth >= 1200
                      ? "var(--text-line-height)"
                      : undefined,
                }}
              >
                <span
                  className="text-wrapper-670"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-family)"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-size)"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-style)"
                        : screenWidth >= 1200
                        ? "var(--text-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-font-weight)"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-letter-spacing)"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-SP-line-height)"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  堀川　まゆ美
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className="CONTACT-24"
          style={{
            alignSelf: screenWidth >= 1200 || screenWidth < 600 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "center" : undefined,
            padding:
              screenWidth < 600
                ? "80px 30px"
                : screenWidth >= 600 && screenWidth < 1200
                ? "40px 50px"
                : screenWidth >= 1200
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1200 || screenWidth < 600
                ? "100%"
                : screenWidth >= 600 && screenWidth < 1200
                ? "600px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-25"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1200
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-299"
              style={{
                gap:
                  screenWidth >= 1200 || screenWidth < 600
                    ? "20px 74px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 600 ? "-10px" : undefined,
                padding:
                  screenWidth < 600
                    ? "25px 60px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "40px 20px"
                    : screenWidth >= 1200
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 600 ? "-3px" : screenWidth >= 600 && screenWidth < 1200 ? "14px" : undefined,
                width:
                  screenWidth < 600
                    ? "280px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "500px"
                    : screenWidth >= 1200
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-42"
                style={{
                  height:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "53px"
                      : screenWidth >= 1200
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                  <>
                    <div className="text-wrapper-388">お問い合わせ</div>
                    <div className="text-wrapper-389">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1200 && (
                  <div className="overlap-group-13">
                    <div className="text-wrapper-390">お問い合わせ</div>
                    <div className="text-wrapper-391">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-43"
                style={{
                  height:
                    screenWidth < 600
                      ? "43px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "48px"
                      : screenWidth >= 1200
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "212px"
                      : screenWidth >= 1200
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-41"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "2px"
                        : screenWidth >= 1200
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 600
                        ? "43px"
                        : screenWidth >= 600 && screenWidth < 1200
                        ? "48px"
                        : screenWidth >= 1200
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "210px"
                        : screenWidth >= 1200
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-41"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-42"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-13"
          style={{
            padding:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "50px 20px"
                : screenWidth >= 1200
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-300"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              flexDirection: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "column" : undefined,
              flexWrap: screenWidth >= 1200 ? "wrap" : undefined,
              gap:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "54px"
                  : screenWidth >= 1200
                  ? "50px 150px"
                  : undefined,
              justifyContent: screenWidth >= 1200 ? "space-between" : undefined,
              marginLeft: screenWidth >= 1200 ? "-40.00px" : undefined,
              marginRight: screenWidth >= 1200 ? "-40.00px" : undefined,
              padding: screenWidth >= 1200 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-301"
              style={{
                alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                flex:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "0 0 auto"
                    : screenWidth >= 1200
                    ? "1"
                    : undefined,
                flexGrow: screenWidth >= 1200 ? "1" : undefined,
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "24px"
                    : screenWidth >= 1200
                    ? "44px"
                    : undefined,
                width: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "100%" : undefined,
              }}
            >
              {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                <LogoWhite22 className="logo-white-27" />
              )}

              {screenWidth >= 1200 && <LogoWhite logoWhiteClassName="logo-white-6" />}

              <div
                className="flexcontainer-42"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "3px"
                      : screenWidth >= 1200
                      ? "5px"
                      : undefined,
                }}
              >
                <p
                  className="text-i-43"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "13px"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1200 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "400"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "0.26px"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "18px"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1200 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1200 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1200 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1200 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "0.03px"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1200 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p
                  className="text-i-44"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1200
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "13px"
                        : screenWidth >= 1200
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1200 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "400"
                        : screenWidth >= 1200
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "0.26px"
                        : screenWidth >= 1200
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "18px"
                        : screenWidth >= 1200
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1200 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1200 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1200 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1200 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "0.03px"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1200 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <div
              className="frame-302"
              style={{
                alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                display:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "flex"
                    : screenWidth >= 1200
                    ? "inline-flex"
                    : undefined,
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "40px"
                    : screenWidth >= 1200
                    ? "60px"
                    : undefined,
                width: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-303"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "30px"
                      : screenWidth >= 1200
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-304"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-392"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-393"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-394"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-395"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-305"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-396"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    会社概要
                  </div>
                  <div
                    className="text-wrapper-397"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-398"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-306"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-399"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    制作について
                  </div>
                  <div
                    className="text-wrapper-400"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-401"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-307"
                style={{
                  alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                  display:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "flex"
                      : screenWidth >= 1200
                      ? "inline-flex"
                      : undefined,
                  flex:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "1"
                      : screenWidth >= 1200
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "1" : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-402"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-403"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-404"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-405"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-406"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-407">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
