/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Noimage23 = ({ className }) => {
  return (
    <svg
      className={`noimage-23 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 212 27"
      width="212"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M22.8427 20.2079C26.3327 20.2079 29.1619 17.6515 29.1619 14.4979C29.1619 11.3444 26.3327 8.78793 22.8427 8.78793C19.3527 8.78793 16.5234 11.3444 16.5234 14.4979C16.5234 17.6515 19.3527 20.2079 22.8427 20.2079Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M41.1919 2.78792H12.2407V0.467926H4.74838V2.78792H4.50491C2.03697 2.78792 0.0449219 4.59793 0.0449219 6.81793V22.3579C0.0449219 24.5879 2.04804 26.3879 4.50491 26.3879H41.203C43.6709 26.3879 45.6629 24.5779 45.6629 22.3579V6.81793C45.6629 4.58793 43.6598 2.78792 41.203 2.78792H41.1919ZM22.8429 21.7579C18.405 21.7579 14.8082 18.5079 14.8082 14.4979C14.8082 10.4879 18.405 7.23793 22.8429 7.23793C27.2807 7.23793 30.8775 10.4879 30.8775 14.4979C30.8775 18.5079 27.2807 21.7579 22.8429 21.7579ZM40.9484 8.20793H33.4561V6.26793H40.9484V8.20793Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M201.275 21.8379V8.42795H211.28V10.7679H204.418V13.9679H210.771V16.3079H204.418V19.5079H211.313V21.8479H201.275V21.8379Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M187.84 12.7679C187.741 12.4479 187.597 12.1679 187.409 11.9279C187.221 11.6779 186.999 11.4679 186.734 11.2979C186.479 11.1279 186.18 10.9879 185.837 10.8979C185.505 10.8079 185.129 10.7579 184.731 10.7579C183.967 10.7579 183.303 10.9279 182.728 11.2679C182.152 11.6079 181.709 12.1079 181.388 12.7579C181.067 13.4079 180.913 14.1979 180.913 15.1279C180.913 16.0579 181.067 16.8579 181.388 17.5079C181.709 18.1579 182.152 18.6579 182.716 19.0079C183.292 19.3479 183.967 19.5179 184.753 19.5179C185.461 19.5179 186.07 19.4079 186.568 19.1779C187.077 18.9479 187.464 18.6179 187.73 18.1979C187.995 17.7779 188.139 17.2879 188.139 16.7079L188.781 16.7979H184.952V14.6679H191.161V16.3579C191.161 17.5379 190.884 18.5479 190.331 19.3979C189.777 20.2379 189.025 20.8879 188.051 21.3479C187.088 21.7979 185.981 22.0279 184.731 22.0279C183.336 22.0279 182.119 21.7479 181.067 21.1979C180.016 20.6379 179.197 19.8479 178.611 18.8179C178.024 17.7879 177.736 16.5679 177.736 15.1479C177.736 14.0579 177.913 13.0879 178.256 12.2379C178.611 11.3779 179.098 10.6579 179.739 10.0679C180.37 9.47795 181.112 9.01795 181.953 8.70795C182.794 8.39795 183.701 8.23795 184.686 8.23795C185.527 8.23795 186.313 8.34795 187.033 8.56795C187.763 8.78795 188.405 9.09794 188.958 9.49794C189.523 9.89794 189.987 10.3779 190.342 10.9279C190.696 11.4779 190.928 12.0879 191.028 12.7479H187.84V12.7679Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M158.225 21.8379H154.86L159.984 8.42795H164.024L169.137 21.8379H165.772L162.054 11.4879H161.943L158.214 21.8379H158.225ZM158.014 16.5679H165.961V18.7779H158.014V16.5679Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M129.24 8.42795H133.114L137.197 17.4379H137.374L141.458 8.42795H145.332V21.8379H142.288V13.1079H142.166L138.326 21.7679H136.257L132.416 13.0679H132.295V21.8279H129.251V8.41794L129.24 8.42795Z"
        fill="#ADADAE"
      />
      <path className="path" d="M118.904 8.42795V21.8379H115.761V8.42795H118.904Z" fill="#ADADAE" />
      <path
        className="path"
        d="M99.5811 15.138C99.5811 16.598 99.2712 17.848 98.6625 18.868C98.0538 19.898 97.2238 20.678 96.1725 21.218C95.1211 21.758 93.948 22.028 92.631 22.028C91.3141 22.028 90.1299 21.758 89.0785 21.218C88.0272 20.678 87.1972 19.898 86.5885 18.868C85.9798 17.838 85.6699 16.598 85.6699 15.138C85.6699 13.678 85.9798 12.428 86.5885 11.408C87.1972 10.378 88.0272 9.59798 89.0785 9.06798C90.1299 8.52798 91.3141 8.25797 92.631 8.25797C93.948 8.25797 95.1211 8.52798 96.1725 9.06798C97.2238 9.60798 98.0538 10.388 98.6625 11.408C99.2712 12.438 99.5811 13.678 99.5811 15.138ZM96.3938 15.138C96.3938 14.188 96.2389 13.388 95.9179 12.738C95.608 12.088 95.1764 11.598 94.601 11.258C94.0365 10.918 93.3725 10.758 92.62 10.758C91.8674 10.758 91.2034 10.928 90.639 11.258C90.0746 11.598 89.6319 12.088 89.322 12.738C89.0121 13.388 88.8572 14.188 88.8572 15.138C88.8572 16.088 89.0121 16.888 89.322 17.538C89.6319 18.188 90.0746 18.678 90.639 19.018C91.2034 19.358 91.8674 19.518 92.62 19.518C93.3725 19.518 94.0365 19.348 94.601 19.018C95.1654 18.678 95.608 18.188 95.9179 17.538C96.2278 16.888 96.3938 16.088 96.3938 15.138Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M75.5883 8.42795V21.8379H72.8769L66.4138 13.3979H66.3032V21.8379H63.1602V8.42795H65.9158L72.3236 16.8679H72.4564V8.42795H75.5773H75.5883Z"
        fill="#ADADAE"
      />
    </svg>
  );
};
