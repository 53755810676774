/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Triangle027 = ({ className }) => {
  return (
    <svg
      className={`triangle02-7 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M5.88232 0.717627L16.1706 10.0647L5.88232 19.4117L5.88232 0.717627Z" fill="#BACEF6" />
    </svg>
  );
};
