import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Line2 } from "../../components/Line2";
import { LogoWhite } from "../../components/LogoWhite";
import "./style.css";

export const InformationSecurityScreen = () => {
  return (
    <div className="information-security-screen">
      <ColorWhite className="header-48" logoLogoClassName="header-49" />
      <div className="blog-4">
        <div className="midashi-15">
          <ElementWrapper
            className="heading-66"
            headingDivClassName="heading-67"
            headingDivClassNameOverride="heading-68"
            headingText="INFORMATION SECURITY BASIC POLICY"
            headingText1="情報セキュリティ基本方針"
            one="one"
          />
          <div className="breadcrumbs-16">
            <div className="frame-437">
              <Link className="text-wrapper-558" to="/home-744">
                TOP
              </Link>
              <ImgWrapper className="component-10930-11" img="/img/34.svg" />
              <div className="text-wrapper-559">情報セキュリティ基本方針</div>
            </div>
          </div>
        </div>
        <div className="security-2">
          <div className="frame-438">
            <div className="frame-439">
              <div className="text-wrapper-560">デジタルパズル株式会社について、第一にお客様はもとより</div>
              <div className="frame-440">
                <div className="bulletpoint-3">
                  <div className="group-79">
                    <div className="ellipse-7" />
                  </div>
                  <div className="text-wrapper-561">DXコンサルティング</div>
                </div>
                <div className="bulletpoint-3">
                  <div className="group-79">
                    <div className="ellipse-7" />
                  </div>
                  <div className="text-wrapper-561">顧客要求事項に基づく情報システムの設計、開発、運用、保守</div>
                </div>
              </div>
              <div className="div-29">に関わる全ての人々の満足を得る事を目標とし、信頼・安心される会社を目指す。</div>
            </div>
            <div className="frame-441">
              <div className="heading-69">
                <div className="text-wrapper-562">行動指針</div>
                <img className="line-pg" alt="Line pg" src="/img/line-pg-3.svg" />
              </div>
              <div className="flexcontainer-63">
                <p className="div-29">
                  <span className="text-wrapper-563">
                    情報資産の機密性、完全性、可用性を確実に保護するために組織的、技術的に適切な対策を講じ、変化する情報技術や新たな脅威に対応する。
                    <br />
                  </span>
                </p>
                <p className="div-29">
                  <span className="text-wrapper-563">
                    全社員に情報セキュリティ教育の実施と方針の周知徹底をはかり、意識の高揚・維持に務める。 <br />
                  </span>
                </p>
                <p className="div-29">
                  <span className="text-wrapper-563">
                    マネジメントシステム及び、情報セキュリティに関する目的を設定し、定期的にレビューし、継続的に改善を実施し、維持する。
                    <br />
                  </span>
                </p>
                <p className="div-29">
                  <span className="text-wrapper-563">
                    マネジメントシステムを実行・維持・改善して行くために管理責任者に責任と権限を委譲する。
                  </span>
                </p>
              </div>
            </div>
            <div className="div-29">2023年12月18日</div>
            <Line2 line="/img/line-2.svg" />
            <div className="flexcontainer-63">
              <p className="div-29">
                <span className="text-wrapper-563">
                  デジタルパズル株式会社 <br />
                </span>
              </p>
              <p className="div-29">
                <span className="text-wrapper-563">
                  代表取締役
                  <br />
                </span>
              </p>
              <p className="div-29">
                <span className="text-wrapper-563">堀川　まゆ美</span>
              </p>
            </div>
          </div>
        </div>
        <div className="CONTACT-38">
          <div className="CONTACT-39">
            <div className="frame-442">
              <div className="group-80">
                <div className="overlap-group-20">
                  <div className="text-wrapper-564">お問い合わせ</div>
                  <div className="text-wrapper-565">CONTACT</div>
                </div>
              </div>
              <div className="group-81">
                <div className="flexcontainer-64">
                  <p className="text-i-60">
                    <span className="text-wrapper-566">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-60">
                    <span className="text-wrapper-566">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-22">
          <div className="frame-443">
            <div className="frame-444">
              <LogoWhite logoWhite="/img/logo-white-32.svg" logoWhiteClassName="logo-white-31" />
              <div className="flexcontainer-63">
                <p className="text-i-61">
                  <span className="text-wrapper-563">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-61">
                  <span className="text-wrapper-563">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-445">
              <div className="frame-444">
                <div className="frame-446">
                  <div className="text-wrapper-567">理念</div>
                  <div className="text-wrapper-568">- 企業理念</div>
                  <div className="text-wrapper-568"> - 経営理念</div>
                  <div className="text-wrapper-568"> - 社訓</div>
                </div>
                <div className="frame-446">
                  <div className="text-wrapper-567">会社概要</div>
                  <div className="text-wrapper-568">- 代表挨拶</div>
                  <div className="text-wrapper-568">- 概要</div>
                </div>
                <div className="frame-446">
                  <div className="text-wrapper-567">制作について</div>
                  <div className="text-wrapper-568">- 業務内容</div>
                  <div className="text-wrapper-568">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-444">
                <div className="text-wrapper-567">ブログ</div>
                <div className="text-wrapper-568">採用情報</div>
                <Link className="text-wrapper-569" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-568">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-569" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-570">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
