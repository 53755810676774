/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const OpenOpenWrapper = ({ open, openOpenClassName, openOpen = "/img/open-open.svg", to }) => {
  return (
    <Link to={to}>
      <img
        className={`open-open-wrapper ${openOpenClassName}`}
        alt="Open open"
        src={open === "close" ? "/img/open-close.svg" : openOpen}
      />
    </Link>
  );
};

OpenOpenWrapper.propTypes = {
  open: PropTypes.oneOf(["close", "open"]),
  openOpen: PropTypes.string,
  to: PropTypes.string,
};
