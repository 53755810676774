import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Flow } from "../../components/Flow";
import { Freeicon } from "../../components/Freeicon";
import { Heading } from "../../components/Heading";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite12 } from "../../icons/LogoWhite12";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Triangle0218 } from "../../icons/Triangle0218";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Aboutcontents = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="aboutcontents"
      style={{
        minWidth:
          screenWidth < 744
            ? "320px"
            : screenWidth >= 744 && screenWidth < 1200
            ? "744px"
            : screenWidth >= 1200
            ? "1200px"
            : undefined,
      }}
    >
      {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
        <SpWhite className="header-10" groupClassName="header-11" icon={<Menu3 className="menu-3-instance" />} />
      )}

      {screenWidth >= 1200 && (
        <ColorWhite className="header-12" groupClassName="header-13" menuStyleOverrideClassName="header-14" />
      )}

      <div className="div-14">
        <div className="div-15">
          <ElementWrapper
            className={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-56"} ${
              screenWidth >= 1200 && "class-57"
            }`}
            headingDivClassName={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-52"} ${
              screenWidth >= 1200 && "class-53"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-54"
            } ${screenWidth >= 1200 && "class-55"}`}
            headingText="ABOUT CONTENTS"
            headingText1="制作について"
            one={
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "two"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-5"
            style={{
              padding:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "10px 15px 20px"
                  : screenWidth >= 1200
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-170"
              style={{
                gap:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "17px"
                    : screenWidth >= 1200
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 744 && screenWidth < 1200 ? "0px 10px" : screenWidth >= 1200 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
                <>
                  <Link className="text-wrapper-163" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-5" />
                </>
              )}

              <div
                className="TOP"
                style={{
                  fontFamily:
                    screenWidth >= 1200
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1200
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1200
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1200
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1200
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1200
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1200 && <>TOP</>}

                {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && <>制作について</>}
              </div>
              {screenWidth >= 1200 && (
                <>
                  <Twelve className="instance-node-5" />
                  <div className="text-wrapper-164">制作について</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="contents"
          style={{
            gap:
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "20px"
                : screenWidth >= 1200
                ? "40px"
                : undefined,
            padding:
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "20px 0px"
                : screenWidth >= 1200
                ? "50px 0px"
                : undefined,
          }}
        >
          <Heading
            className="heading-26"
            divClassName={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-60"} ${
              screenWidth >= 1200 && "class-61"
            }`}
            divClassNameOverride={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-58"} ${
              screenWidth >= 1200 && "class-59"
            }`}
            one={
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "SP"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
            text="CONTENTS"
            text1="業務内容"
          />
          <div
            className="frame-171"
            style={{
              padding: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "0px 15px 30px" : undefined,
            }}
          >
            {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
              <>
                <div
                  className="group-23"
                  style={{
                    height:
                      screenWidth < 744 ? "167.68px" : screenWidth >= 744 && screenWidth < 1200 ? "205px" : undefined,
                    width:
                      screenWidth < 744 ? "262px" : screenWidth >= 744 && screenWidth < 1200 ? "320.31px" : undefined,
                  }}
                />
                <div className="frame-172">
                  <div className="flexcontainer-20">
                    <p className="text-9">
                      <span className="text-wrapper-165">
                        デジタルパズルは
                        <br />
                      </span>
                    </p>
                    <p className="text-9">
                      <span className="text-wrapper-165">さまざまなご要望に対応いたします</span>
                    </p>
                  </div>
                  <img
                    className="line-12"
                    alt="Line"
                    src={
                      screenWidth < 744
                        ? "/img/line-12-2.svg"
                        : screenWidth >= 744 && screenWidth < 1200
                        ? "/img/line-12-1.svg"
                        : undefined
                    }
                  />
                  <div
                    className="frame-173"
                    style={{
                      alignItems:
                        screenWidth < 744
                          ? "center"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "flex-start"
                          : undefined,
                      flexDirection: screenWidth < 744 ? "column" : undefined,
                      gap: screenWidth < 744 ? "5px" : screenWidth >= 744 && screenWidth < 1200 ? "10px" : undefined,
                      justifyContent: screenWidth >= 744 && screenWidth < 1200 ? "center" : undefined,
                      padding:
                        screenWidth < 744
                          ? "0px 5px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "0px 10px"
                          : undefined,
                    }}
                  >
                    <div
                      className="frame-174"
                      style={{
                        alignSelf: screenWidth < 744 ? "stretch" : undefined,
                        flex:
                          screenWidth < 744 ? "0 0 auto" : screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        gap: screenWidth < 744 ? "5px" : screenWidth >= 744 && screenWidth < 1200 ? "10px" : undefined,
                        width: screenWidth < 744 ? "100%" : undefined,
                      }}
                    >
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-1.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-1.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-1.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-1.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                    </div>
                    <div
                      className="frame-175"
                      style={{
                        alignSelf: screenWidth < 744 ? "stretch" : undefined,
                        flex:
                          screenWidth < 744 ? "0 0 auto" : screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        gap: screenWidth < 744 ? "5px" : screenWidth >= 744 && screenWidth < 1200 ? "10px" : undefined,
                        width: screenWidth < 744 ? "100%" : undefined,
                      }}
                    >
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-5.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-5.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-5.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-5.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                    </div>
                    <div
                      className="frame-176"
                      style={{
                        alignSelf: screenWidth < 744 ? "stretch" : undefined,
                        flex:
                          screenWidth < 744 ? "0 0 auto" : screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1200 ? "1" : undefined,
                        gap: screenWidth < 744 ? "5px" : screenWidth >= 744 && screenWidth < 1200 ? "10px" : undefined,
                        width: screenWidth < 744 ? "100%" : undefined,
                      }}
                    >
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-9.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-9.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-9.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="ellipse-wrapper">
                          <img
                            className="ellipse-2"
                            alt="Ellipse"
                            src={
                              screenWidth < 744
                                ? "/img/ellipse-1-13.svg"
                                : screenWidth >= 744 && screenWidth < 1200
                                ? "/img/ellipse-1-9.svg"
                                : undefined
                            }
                          />
                        </div>
                        <div className="text-wrapper-166">コンサルティング</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {screenWidth >= 1200 && (
              <>
                <img className="rectangle-4" alt="Rectangle" src="/img/lead-ill.png" />
                <div className="frame-177">
                  <div className="text-wrapper-167">デジタルパズルはさまざまなご要望に対応いたします</div>
                  <img className="line-12" alt="Line" src="/img/line-12-3.svg" />
                  <div className="frame-178">
                    <div className="frame-179">
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                    </div>
                    <div className="frame-179">
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                    </div>
                    <div className="frame-179">
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                      <div className="ellipse">
                        <div className="group-24">
                          <div className="ellipse-3" />
                        </div>
                        <div className="text-wrapper-168">コンサルティング</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="flow-2"
          style={{
            gap:
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "20px"
                : screenWidth >= 1200
                ? "40px"
                : undefined,
            padding:
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "20px 15px 60px"
                : screenWidth >= 1200
                ? "50px 50px 80px"
                : undefined,
          }}
        >
          <Heading
            className="heading-27"
            divClassName={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-64"} ${
              screenWidth >= 1200 && "class-65"
            }`}
            divClassNameOverride={`${((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && "class-62"} ${
              screenWidth >= 1200 && "class-63"
            }`}
            one={
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "SP"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
            text="FLOW"
            text1="制作の流れ"
          />
          <div
            className="frame-180"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              gap:
                screenWidth < 744
                  ? "10px"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "20px"
                  : undefined,
              maxWidth: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "1000px" : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1000px"
                  : undefined,
            }}
          >
            <div
              className="flow-3"
              style={{
                borderColor:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "#d7d7d7"
                    : screenWidth < 744
                    ? "var(--linecolor)"
                    : undefined,
                borderRadius:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "10px"
                    : screenWidth < 744
                    ? "6px"
                    : undefined,
              }}
            >
              {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
                <>
                  <div className="frame-181">
                    <div className="text-wrapper-169">1</div>
                  </div>
                  <div
                    className="frame-182"
                    style={{
                      display:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "inline-flex"
                          : screenWidth >= 1200
                          ? "flex"
                          : undefined,
                      flex: screenWidth >= 744 && screenWidth < 1200 ? "0 0 auto" : undefined,
                      width: screenWidth >= 1200 ? "180px" : undefined,
                    }}
                  >
                    <div className="group-25">
                      <img className="image-7" alt="Image" src="/img/image-1-5.png" />
                    </div>
                  </div>
                  <div className="frame-183">
                    <div className="frame-184">
                      <Freeicon className="freeicon-3" one="one" text="無料" />
                      <div className="text-wrapper-170">お問い合わせ</div>
                    </div>
                    <div
                      className="text-wrapper-171"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "15px"
                            : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "400"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "25px"
                            : undefined,
                      }}
                    >
                      ホームページのお問い合せフォームからご連絡ください。折り返しメールにて面談日時を調整させていただきます。
                    </div>
                  </div>
                  <div className="frame-185">
                    <div className="frame-186">
                      <div className="text-wrapper-172">所要時間</div>
                    </div>
                    <div
                      className="text-wrapper-173"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-size)"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-style)"
                            : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-line-height)"
                            : undefined,
                      }}
                    >
                      返信まで5営業日以内
                    </div>
                  </div>
                </>
              )}

              {screenWidth < 744 && (
                <div className="frame-187">
                  <div className="frame-188">
                    <div className="frame-189">
                      <div className="text-wrapper-174">1</div>
                    </div>
                    <div className="frame-190">
                      <div className="frame-191">
                        <div className="group-26">
                          <img className="image-8" alt="Image" src="/img/image-1-10.png" />
                        </div>
                      </div>
                      <img className="line-12" alt="Line" src="/img/line-19-1.svg" />
                    </div>
                  </div>
                  <div className="div-15">
                    <div className="frame-192">
                      <div className="frame-184">
                        <Freeicon className="freeicon-4" divClassName="freeicon-5" one="one" />
                        <div className="text-wrapper-175">お問い合わせ</div>
                      </div>
                      <div className="text-wrapper-176">
                        ホームページのお問い合せフォームからご連絡ください。折り返しメールにて面談日時を調整させていただきます。
                      </div>
                    </div>
                    <div className="frame-193">
                      <div className="frame-186">
                        <div className="text-wrapper-177">所要時間</div>
                      </div>
                      <div className="text-wrapper-178">返信まで5営業日以内</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Triangle0218
              className={`${screenWidth < 744 && "triangle02-18-instance"} ${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "triangle-02-instance"
              }`}
              color="#D6DBED"
            />
            {screenWidth < 744 && (
              <Flow
                className="flow-instance"
                divClassName="flow-6"
                divClassNameOverride="flow-10"
                frameClassName="flow-4"
                frameClassNameOverride="flow-5"
                image="/img/image-1-11.png"
                imageClassName="flow-9"
                line="/img/line-19-2.svg"
                overlapGroupClassName="flow-7"
                prop="SP"
                rectangleClassName="flow-8"
                text="2"
                text1="オンライン面談"
                text2="ご相談の内容・予算・スケジュールの概要をヒアリングさせていただきます。この際、必要な資料および秘密保持契約の要・不要もお話しさせていただきます。"
                text3="1時間程度"
              />
            )}

            {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
              <div className="flow-11">
                <div className="frame-181">
                  <div className="element-5">2</div>
                </div>
                <div
                  className="frame-194"
                  style={{
                    display:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "inline-flex"
                        : screenWidth >= 1200
                        ? "flex"
                        : undefined,
                    flex: screenWidth >= 744 && screenWidth < 1200 ? "0 0 auto" : undefined,
                    width: screenWidth >= 1200 ? "180px" : undefined,
                  }}
                >
                  <div className="group-25">
                    <img className="image-7" alt="Image" src="/img/image-1-6.png" />
                  </div>
                </div>
                <div className="frame-183">
                  <div className="frame-184">
                    <Freeicon className="freeicon-3" one="one" text="無料" />
                    <div className="text-wrapper-170">オンライン面談</div>
                  </div>
                  <div
                    className="text-wrapper-179"
                    style={{
                      fontFamily:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-SP-font-style)" : undefined,
                      fontWeight:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    ご相談の内容・予算・スケジュールの概要をヒアリングさせていただきます。この際、必要な資料および秘密保持契約の要・不要もお話しさせていただきます。
                  </div>
                </div>
                <div className="frame-185">
                  <div className="frame-186">
                    <div className="text-wrapper-172">所要時間</div>
                  </div>
                  <div
                    className="element-6"
                    style={{
                      fontFamily:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-font-size)"
                          : undefined,
                      fontStyle:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-font-style)"
                          : undefined,
                      fontWeight:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "var(--textbold-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-bold-line-height)"
                          : undefined,
                    }}
                  >
                    1時間程度
                  </div>
                </div>
              </div>
            )}

            <Triangle0218
              className={`${screenWidth < 744 && "triangle02-18-instance"} ${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "triangle-02-instance"
              }`}
              color="#D6DBED"
            />
            {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
              <>
                <div className="flow-11">
                  <div className="frame-181">
                    <div className="element-5">3</div>
                  </div>
                  <div
                    className="frame-195"
                    style={{
                      display:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "inline-flex"
                          : screenWidth >= 1200
                          ? "flex"
                          : undefined,
                      flex: screenWidth >= 744 && screenWidth < 1200 ? "0 0 auto" : undefined,
                      width: screenWidth >= 1200 ? "180px" : undefined,
                    }}
                  >
                    <div className="group-25">
                      <img className="image-7" alt="Image" src="/img/image-1-7.png" />
                    </div>
                  </div>
                  <div className="frame-183">
                    <div className="frame-184">
                      <Freeicon className="freeicon-3" one="one" text="無料" />
                      <div className="text-wrapper-170">情報資料のご提供</div>
                    </div>
                    <div
                      className="text-wrapper-180"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "15px"
                            : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "400"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "25px"
                            : undefined,
                      }}
                    >
                      検討のために詳細な情報資料をご提供いただく必要がある場合は秘密保持契約を締結します（電子契約）。
                      締結後、情報資料のご提供をお願いいたします。
                    </div>
                  </div>
                  <div className="frame-185">
                    <div className="frame-186">
                      <div className="text-wrapper-172">所要時間</div>
                    </div>
                    <div
                      className="element-7"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-size)"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-style)"
                            : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-line-height)"
                            : undefined,
                      }}
                    >
                      最短当日
                    </div>
                  </div>
                </div>
                <Triangle0218 className="triangle-02-instance" color="#D6DBED" />
                <div className="flow-11">
                  <div className="frame-181">
                    <div className="element-8">4</div>
                  </div>
                  <div
                    className="frame-196"
                    style={{
                      display:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "inline-flex"
                          : screenWidth >= 1200
                          ? "flex"
                          : undefined,
                      flex: screenWidth >= 744 && screenWidth < 1200 ? "0 0 auto" : undefined,
                      width: screenWidth >= 1200 ? "180px" : undefined,
                    }}
                  >
                    <div className="group-25">
                      <img className="image-7" alt="Image" src="/img/image-1-8.png" />
                    </div>
                  </div>
                  <div className="frame-183">
                    <div className="frame-184">
                      <Freeicon className="freeicon-3" one="one" text="無料" />
                      <div className="text-wrapper-170">提案書・見積書の提示</div>
                    </div>
                    <div
                      className="text-wrapper-181"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "15px"
                            : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "400"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "25px"
                            : undefined,
                      }}
                    >
                      ご提案書・お見積書をご提示させて頂きます。
                    </div>
                  </div>
                  <div className="frame-185">
                    <div className="frame-186">
                      <div className="text-wrapper-172">所要時間</div>
                    </div>
                    <div
                      className="element-9"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-size)"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-style)"
                            : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-line-height)"
                            : undefined,
                      }}
                    >
                      通常、資料のご提供から1〜2週間程度
                    </div>
                  </div>
                </div>
                <Triangle0218 className="triangle-02-instance" color="#D6DBED" />
                <div className="flow-12">
                  <div className="frame-181">
                    <div className="element-5">5</div>
                  </div>
                  <div
                    className="frame-197"
                    style={{
                      display:
                        screenWidth >= 744 && screenWidth < 1200
                          ? "inline-flex"
                          : screenWidth >= 1200
                          ? "flex"
                          : undefined,
                      flex: screenWidth >= 744 && screenWidth < 1200 ? "0 0 auto" : undefined,
                      width: screenWidth >= 1200 ? "180px" : undefined,
                    }}
                  >
                    <div className="group-25">
                      <img className="image-7" alt="Image" src="/img/image-1-9.png" />
                    </div>
                  </div>
                  <div className="frame-183">
                    <div className="frame-184">
                      <Freeicon className="freeicon-3" one="two" />
                      <div className="text-wrapper-170">契約締結・着手</div>
                    </div>
                    <div
                      className="text-wrapper-182"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "15px"
                            : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "400"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "25px"
                            : undefined,
                      }}
                    >
                      契約締結後から役務提供開始となります。
                      個別契約で合意した条件に基づき納品等や請求をさせていただきます。
                    </div>
                  </div>
                  <div className="frame-185">
                    <div className="frame-186">
                      <div className="text-wrapper-172">所要時間</div>
                    </div>
                    <div
                      className="text-wrapper-183"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-family)"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-size)"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-style)"
                            : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-font-weight)"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-letter-spacing)"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1200
                            ? "var(--textbold-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-bold-line-height)"
                            : undefined,
                      }}
                    >
                      返信まで5営業日以内
                    </div>
                  </div>
                </div>
              </>
            )}

            {screenWidth < 744 && (
              <>
                <Flow
                  className="flow-instance"
                  divClassName="flow-13"
                  divClassNameOverride="flow-10"
                  frameClassName="flow-4"
                  frameClassNameOverride="flow-5"
                  image="/img/image-1-12.png"
                  imageClassName="flow-9"
                  line="/img/line-19-2.svg"
                  overlapGroupClassName="flow-7"
                  prop="SP"
                  rectangleClassName="flow-8"
                  text="3"
                  text1="情報資料のご提供"
                  text2="検討のために詳細な情報資料をご提供いただく必要がある場合は秘密保持契約を締結します（電子契約）。 締結後、情報資料のご提供をお願いいたします。"
                  text3="最短当日"
                />
                <Triangle0218 className="triangle02-18-instance" color="#D6DBED" />
                <Flow
                  className="flow-instance"
                  divClassName="flow-6"
                  divClassNameOverride="flow-10"
                  frameClassName="flow-4"
                  frameClassNameOverride="flow-5"
                  image="/img/image-1-13.png"
                  imageClassName="flow-9"
                  line="/img/line-19-2.svg"
                  overlapGroupClassName="flow-7"
                  prop="SP"
                  rectangleClassName="flow-8"
                  text="4"
                  text1="提案書・見積書の提示"
                  text2="ご提案書・お見積書をご提示させて頂きます。"
                  text3="通常、資料のご提供から1〜2週間程度"
                />
                <Triangle0218 className="triangle02-18-instance" color="#D6DBED" />
                <Flow
                  className="flow-15"
                  divClassName="flow-13"
                  divClassNameOverride="flow-10"
                  frameClassName="flow-4"
                  frameClassNameOverride="flow-5"
                  freeiconElementClassName="flow-14"
                  freeiconText="有料"
                  image="/img/image-1-14.png"
                  imageClassName="flow-9"
                  line="/img/line-19-2.svg"
                  overlapGroupClassName="flow-7"
                  prop="SP"
                  rectangleClassName="flow-8"
                  text="5"
                  text1="契約締結・着手"
                  text2="契約締結後から役務提供開始となります。 個別契約で合意した条件に基づき納品等や請求をさせていただきます。"
                  text3="返信まで5営業日以内"
                />
              </>
            )}
          </div>
        </div>
        <div
          className="CONTACT-12"
          style={{
            alignSelf: screenWidth >= 1200 || screenWidth < 744 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "center" : undefined,
            padding:
              screenWidth < 744
                ? "80px 30px"
                : screenWidth >= 744 && screenWidth < 1200
                ? "40px 50px"
                : screenWidth >= 1200
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1200 || screenWidth < 744
                ? "100%"
                : screenWidth >= 744 && screenWidth < 1200
                ? "744px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-13"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-198"
              style={{
                gap:
                  screenWidth >= 1200 || screenWidth < 744
                    ? "20px 74px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 744 ? "-10px" : undefined,
                padding:
                  screenWidth < 744
                    ? "25px 60px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "40px 20px"
                    : screenWidth >= 1200
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 744 ? "-3px" : screenWidth >= 744 && screenWidth < 1200 ? "14px" : undefined,
                width:
                  screenWidth < 744
                    ? "280px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "644px"
                    : screenWidth >= 1200
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-27"
                style={{
                  height:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "53px"
                      : screenWidth >= 1200
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 744 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 744 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
                  <>
                    <div className="text-wrapper-184">お問い合わせ</div>
                    <div className="text-wrapper-185">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1200 && (
                  <div className="overlap-group-7">
                    <div className="text-wrapper-186">お問い合わせ</div>
                    <div className="text-wrapper-187">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-28"
                style={{
                  height:
                    screenWidth < 744
                      ? "43px"
                      : screenWidth >= 744 && screenWidth < 1200
                      ? "48px"
                      : screenWidth >= 1200
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 744 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 744 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "212px"
                      : screenWidth >= 1200
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-21"
                  style={{
                    gap:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "2px"
                        : screenWidth >= 1200
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 744
                        ? "43px"
                        : screenWidth >= 744 && screenWidth < 1200
                        ? "48px"
                        : screenWidth >= 1200
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "210px"
                        : screenWidth >= 1200
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-19"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-20"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-7"
          style={{
            padding:
              (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                ? "50px 20px"
                : screenWidth >= 1200
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-199"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              flexDirection: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "column" : undefined,
              flexWrap: screenWidth >= 1200 ? "wrap" : undefined,
              gap:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "54px"
                  : screenWidth >= 1200
                  ? "50px 150px"
                  : undefined,
              justifyContent: screenWidth >= 1200 ? "space-between" : undefined,
              marginLeft: screenWidth >= 1200 ? "-40.00px" : undefined,
              marginRight: screenWidth >= 1200 ? "-40.00px" : undefined,
              padding: screenWidth >= 1200 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-200"
              style={{
                alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
                flex:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "0 0 auto"
                    : screenWidth >= 1200
                    ? "1"
                    : undefined,
                flexGrow: screenWidth >= 1200 ? "1" : undefined,
                gap:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "24px"
                    : screenWidth >= 1200
                    ? "44px"
                    : undefined,
                width: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "100%" : undefined,
              }}
            >
              {screenWidth < 744 && <LogoWhite12 className="logo-white-3" />}

              {screenWidth >= 744 && screenWidth < 1200 && <LogoWhite22 className="logo-white-3" />}

              {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
                <div className="flexcontainer-22">
                  <p className="text-i-21">
                    <span className="text-wrapper-188">
                      千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                    </span>
                  </p>
                  <p className="text-i-21">
                    <span className="text-wrapper-188">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                  </p>
                </div>
              )}

              {screenWidth >= 1200 && (
                <>
                  <LogoWhite30 className="logo-white-25" />
                  <div className="flexcontainer-23">
                    <p className="text-i-22">
                      <span className="text-wrapper-189">
                        千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                      </span>
                    </p>
                    <p className="text-i-22">
                      <span className="text-wrapper-189">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="frame-201"
              style={{
                alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
                display:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "flex"
                    : screenWidth >= 1200
                    ? "inline-flex"
                    : undefined,
                gap:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "40px"
                    : screenWidth >= 1200
                    ? "60px"
                    : undefined,
                width: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-202"
                style={{
                  gap:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "30px"
                      : screenWidth >= 1200
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-203"
                  style={{
                    gap:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-190"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-191"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-192"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-193"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-204"
                  style={{
                    gap:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-194"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    会社概要
                  </div>
                  <div
                    className="text-wrapper-195"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-196"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-205"
                  style={{
                    gap:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-197"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    制作について
                  </div>
                  <div
                    className="text-wrapper-198"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-199"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-206"
                style={{
                  alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
                  display:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "flex"
                      : screenWidth >= 1200
                      ? "inline-flex"
                      : undefined,
                  flex:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "1"
                      : screenWidth >= 1200
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "1" : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-200"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-201"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-202"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-203"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-204"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-205">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
