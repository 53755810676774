/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame = ({ one, className, divClassName }) => {
  return (
    <div className={`frame one-8-${one} ${className}`}>
      <div className={`div-4 ${divClassName}`}>
        {one === "one" && <>募集中</>}

        {one === "two" && <>募集終了</>}
      </div>
    </div>
  );
};

Frame.propTypes = {
  one: PropTypes.oneOf(["two", "one"]),
};
