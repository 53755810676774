/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Required } from "../Required";
import "./style.css";

export const InputField = ({
  prop,
  prop1,
  className,
  frameClassName,
  divClassName,
  text = "お名前",
  text1 = "お名前を入れてください",
  requiredRequiredClassName,
}) => {
  return (
    <div className={`input-field prop-1-${prop1} ${className}`}>
      <div className={`frame-wrapper prop-${prop} prop-1-0-${prop1}`}>
        <div className="frame-4">
          <div className="text-wrapper-5">{text}</div>
          {prop === "one" && (
            <Required
              className={requiredRequiredClassName}
              divWrapperDivClassName={`${prop1 === "SP" ? "class" : "class-2"}`}
            />
          )}
        </div>
      </div>
      <div className={`frame-5 ${frameClassName}`}>
        <div className={`text-wrapper-6 ${divClassName}`}>{text1}</div>
      </div>
    </div>
  );
};

InputField.propTypes = {
  prop: PropTypes.oneOf(["three", "one"]),
  prop1: PropTypes.oneOf(["two", "SP"]),
  text: PropTypes.string,
  text1: PropTypes.string,
};
