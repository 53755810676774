import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading02 } from "../../components/Heading02";
import { Line2 } from "../../components/Line2";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Twelve } from "../../icons/Twelve";
import "./style.css";

export const Privacypolicy = () => {
  return (
    <div className="privacypolicy">
      <ColorWhite className="header-28" groupClassName="header-29" menuStyleOverrideClassName="header-30" />
      <div className="div-19">
        <div className="midashi-6">
          <ElementWrapper
            className="heading-33"
            headingDivClassName="heading-34"
            headingDivClassNameOverride="heading-35"
            headingText="PRIVACY POLICY"
            headingText1="プライバシーポリシー"
            one="one"
          />
          <div className="breadcrumbs-9">
            <div className="frame-267">
              <div className="text-wrapper-329">TOP</div>
              <Twelve className="instance-12" />
              <div className="text-wrapper-329">プライバシーポリシー</div>
            </div>
          </div>
        </div>
        <div className="privacypolicys">
          <div className="frame-268">
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="お客様から取得する情報" />
              <div className="div-20">当社は、お客様から以下の情報を取得します。</div>
              <div className="frame-270">
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">氏名(ニックネームやペンネームも含む)</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">メールアドレス、開発、運用、保守</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">電話番号</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">住所</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">Cookie(クッキー)を用いて生成された識別情報</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">
                    当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="お客様の情報を利用する目的" />
              <div className="div-20">当社は、お客様から取得した情報を、以下の目的のために利用します。</div>
              <div className="frame-270">
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">当社サービスに関する登録の受付、お客様の本人確認、認証のため</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">お客様の当社サービスの利用履歴を管理するため</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">
                    当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため
                  </div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">お客様からのお問い合わせに対応するため</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">当社の規約や法令に違反する行為に対応するため</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">当社サービスの変更、提供中止、終了、契約解除をご連絡するため</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">当社規約の変更等を通知するため</div>
                </div>
              </div>
              <div className="flexcontainer-35">
                <p className="div-20">
                  <span className="text-wrapper-331">
                    以上の他、当社サービスの提供、維持、保護及び改善のため
                    <br />
                  </span>
                </p>
                <p className="div-20">
                  <span className="text-wrapper-331">広告の配信、表示及び効果測定のため</span>
                </p>
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="安全管理のために講じた措置" />
              <div className="div-20">
                当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="第三者提供" />
              <div className="div-20">
                当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第１６条第３項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
                但し、次の場合は除きます。
              </div>
              <div className="frame-270">
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">個人データの取扱いを外部に委託する場合</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">当社や当社サービスが買収された場合</div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">
                    事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）
                  </div>
                </div>
                <div className="bulletpoint">
                  <div className="group-35">
                    <div className="ellipse-4" />
                  </div>
                  <div className="text-wrapper-330">その他、法律によって合法的に第三者提供が許されている場合</div>
                </div>
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="アクセス解析ツール" />
              <div className="flexcontainer-35">
                <p className="text-22">
                  <span className="text-wrapper-332">
                    当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
                    <br />
                  </span>
                </p>
                <p className="text-22">
                  <span className="text-wrapper-333">Google アナリティクス利用規約</span>
                </p>
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="プライバシーポリシーの変更" />
              <div className="div-20">
                当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
              </div>
            </div>
            <div className="frame-269">
              <Heading02 className="heading-02-instance" one="one" text="お問い合わせ" />
              <div className="flexcontainer-35">
                <p className="div-20">
                  <span className="text-wrapper-331">
                    お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
                    <br />
                  </span>
                </p>
                <p className="div-20">
                  <span className="text-wrapper-331">
                    privacy＠disitalpuzzleco.jp
                    <br />
                  </span>
                </p>
                <p className="div-20">
                  <span className="text-wrapper-331">
                    この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。
                  </span>
                </p>
              </div>
            </div>
            <div className="frame-271">
              <div className="flexcontainer-35">
                <p className="text-23">
                  <span className="text-wrapper-334">
                    事業者の名称
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-335">
                    デジタルパズル株式会社
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-335">
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-334">
                    代表者氏名
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-335">
                    堀川まゆ美
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-335">
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-334">
                    事業者の住所
                    <br />
                  </span>
                </p>
                <p className="text-23">
                  <span className="text-wrapper-335">
                    千葉県千葉市美浜区若葉３丁目１番１８号　幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <Line2 />
            <div className="text-wrapper-336">2024年02月13日 制定</div>
          </div>
        </div>
        <div className="CONTACT-20">
          <div className="CONTACT-21">
            <div className="frame-272">
              <div className="group-36">
                <div className="overlap-group-11">
                  <div className="text-wrapper-337">お問い合わせ</div>
                  <div className="text-wrapper-338">CONTACT</div>
                </div>
              </div>
              <div className="group-37">
                <div className="flexcontainer-36">
                  <p className="text-i-35">
                    <span className="text-wrapper-331">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-35">
                    <span className="text-wrapper-331">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-11">
          <div className="frame-273">
            <div className="frame-274">
              <LogoWhite30 className="logo-white-17" />
              <div className="flexcontainer-35">
                <p className="text-i-36">
                  <span className="text-wrapper-335">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-36">
                  <span className="text-wrapper-335">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-275">
              <div className="frame-276">
                <div className="frame-277">
                  <div className="text-wrapper-339">理念</div>
                  <div className="text-wrapper-340">- 企業理念</div>
                  <div className="text-wrapper-340"> - 経営理念</div>
                  <div className="text-wrapper-340"> - 社訓</div>
                </div>
                <div className="frame-277">
                  <div className="text-wrapper-339">会社概要</div>
                  <div className="text-wrapper-340">- 代表挨拶</div>
                  <div className="text-wrapper-340">- 概要</div>
                </div>
                <div className="frame-277">
                  <div className="text-wrapper-339">制作について</div>
                  <div className="text-wrapper-340">- 業務内容</div>
                  <div className="text-wrapper-340">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-278">
                <div className="text-wrapper-339">ブログ</div>
                <div className="text-wrapper-341">採用情報</div>
                <Link className="text-wrapper-342" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-341">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-342" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-343">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
