/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Heading = ({
  one,
  className,
  divClassName,
  text = "TEXTTEXT",
  divClassNameOverride,
  text1 = "見出し",
}) => {
  return (
    <div className={`heading ${className}`}>
      <div className={`div ${one}`}>
        <div className={`div-2 ${divClassName}`}>
          {one === "one" && <>{text1}</>}

          {one === "SP" && <>{text}</>}
        </div>
        <div className={`TEXTTEXT ${divClassNameOverride}`}>
          {one === "one" && <>{text}</>}

          {one === "SP" && <>{text1}</>}
        </div>
      </div>
    </div>
  );
};

Heading.propTypes = {
  one: PropTypes.oneOf(["SP", "one"]),
  text: PropTypes.string,
  text1: PropTypes.string,
};
