/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Triangle13 = ({ className }) => {
  return (
    <svg
      className={`triangle-13 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 17 18"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M1.95932 5.5L8.47966 12.5L15 5.5" stroke="#2B3987" strokeWidth="2" />
      <path className="path" d="M1.95932 5.5L8.47966 12.5L15 5.5" stroke="black" strokeOpacity="0.2" strokeWidth="2" />
    </svg>
  );
};
