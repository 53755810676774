/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Twelve = ({ className }) => {
  return (
    <svg
      className={`twelve ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 5 9"
      width="5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_166_5428)">
        <path
          className="path"
          d="M0.5 0.929993L4.07 4.49999L0.5 8.06999"
          stroke="#8A8A8A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_166_5428">
          <rect className="rect" fill="white" height="8.14" transform="translate(0 0.429993)" width="4.57" />
        </clipPath>
      </defs>
    </svg>
  );
};
