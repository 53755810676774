/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const LogoWhite = ({ logoWhiteClassName, logoWhite = "/img/logo-white-4.svg", to }) => {
  return (
    <Link to={to}>
      <img className={`logo-white ${logoWhiteClassName}`} alt="Logo white" src={logoWhite} />
    </Link>
  );
};

LogoWhite.propTypes = {
  logoWhite: PropTypes.string,
  to: PropTypes.string,
};
