import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { Menu6 } from "../../icons/Menu6";
import "./style.css";

export const MenuopenSp = () => {
  return (
    <div className="menuopen-SP">
      <div className="menu-SP-wrapper">
        <div className="menu-SP">
          <div className="frame-141">
            <div className="frame-142">
              <div className="rectangle-3" />
              <Menu6 className="menu-6" />
            </div>
            <div className="menu-4">
              <div className="frame-143">
                <Link className="text-wrapper-137" to="/home-320">
                  ホーム
                </Link>
                <div className="frame-144">
                  <div className="frame-145">
                    <div className="frame-146">
                      <div className="frame-147">
                        <Link className="text-wrapper-137" to="/philosophy-320">
                          理念
                        </Link>
                        <div className="frame-148">
                          <div className="text-wrapper-138">- 企業理念</div>
                          <div className="text-wrapper-139">- 経営理念</div>
                          <div className="text-wrapper-139">- 社訓</div>
                        </div>
                      </div>
                      <div className="frame-147">
                        <div className="text-wrapper-140">会社概要</div>
                        <div className="frame-148">
                          <div className="text-wrapper-138">- 代表挨拶</div>
                          <div className="text-wrapper-139">- 概要</div>
                        </div>
                      </div>
                      <div className="frame-147">
                        <div className="text-wrapper-140">制作について</div>
                        <div className="frame-148">
                          <div className="text-wrapper-138">- 業務内容</div>
                          <div className="text-wrapper-139">- ご相談の流れ</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-149">
                    <div className="frame-150">
                      <Link className="text-wrapper-141" to="/blogs-320">
                        ブログ
                      </Link>
                      <Link className="text-wrapper-142" to="/recruits-320">
                        採用情報
                      </Link>
                      <div className="text-wrapper-143">よくある質問</div>
                      <Link className="text-wrapper-142" to="/information-security-basic-policy-320">
                        情報セキュリティ基本方針
                      </Link>
                      <Link className="text-wrapper-142" to="/privacypolicy-320">
                        プライバシーポ　リシー
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Button className="button-8" color="white" device="SP" text="お問い合わせ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
