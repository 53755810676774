/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWhite30 = ({ className }) => {
  return (
    <svg
      className={`logo-white-30 ${className}`}
      fill="none"
      height="55"
      viewBox="0 0 164 55"
      width="164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_399_2716)">
        <path
          className="path"
          d="M26.0651 31.6201C28.2293 31.6201 29.9837 30.0531 29.9837 28.1201C29.9837 26.1871 28.2293 24.6201 26.0651 24.6201C23.9009 24.6201 22.1465 26.1871 22.1465 28.1201C22.1465 30.0531 23.9009 31.6201 26.0651 31.6201Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0638 18.4299C27.8508 18.4299 29.2994 17.136 29.2994 15.5399C29.2994 13.9438 27.8508 12.6499 26.0638 12.6499C24.2768 12.6499 22.8281 13.9438 22.8281 15.5399C22.8281 17.136 24.2768 18.4299 26.0638 18.4299Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2454 24.8701C40.0324 24.8701 41.481 23.5762 41.481 21.9801C41.481 20.384 40.0324 19.0901 38.2454 19.0901C36.4584 19.0901 35.0098 20.384 35.0098 21.9801C35.0098 23.5762 36.4584 24.8701 38.2454 24.8701Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2454 37.4599C40.0324 37.4599 41.481 36.166 41.481 34.5699C41.481 32.9738 40.0324 31.6799 38.2454 31.6799C36.4584 31.6799 35.0098 32.9738 35.0098 34.5699C35.0098 36.166 36.4584 37.4599 38.2454 37.4599Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0638 43.6001C27.8508 43.6001 29.2994 42.3062 29.2994 40.7101C29.2994 39.114 27.8508 37.8201 26.0638 37.8201C24.2768 37.8201 22.8281 39.114 22.8281 40.7101C22.8281 42.3062 24.2768 43.6001 26.0638 43.6001Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.8821 24.8701C15.6691 24.8701 17.1178 23.5762 17.1178 21.9801C17.1178 20.384 15.6691 19.0901 13.8821 19.0901C12.0951 19.0901 10.6465 20.384 10.6465 21.9801C10.6465 23.5762 12.0951 24.8701 13.8821 24.8701Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.2864 37.4599C16.0734 37.4599 17.5221 36.166 17.5221 34.5699C17.5221 32.9738 16.0734 31.6799 14.2864 31.6799C12.4994 31.6799 11.0508 32.9738 11.0508 34.5699C11.0508 36.166 12.4994 37.4599 14.2864 37.4599Z"
          fill="white"
        />
        <path
          className="path"
          d="M25.8856 4.61989C26.9615 4.61989 27.8337 3.84087 27.8337 2.87989C27.8337 1.91892 26.9615 1.13989 25.8856 1.13989C24.8097 1.13989 23.9375 1.91892 23.9375 2.87989C23.9375 3.84087 24.8097 4.61989 25.8856 4.61989Z"
          fill="white"
        />
        <path
          className="path"
          d="M26.0633 54.26C27.1392 54.26 28.0114 53.481 28.0114 52.52C28.0114 51.5591 27.1392 50.78 26.0633 50.78C24.9874 50.78 24.1152 51.5591 24.1152 52.52C24.1152 53.481 24.9874 54.26 26.0633 54.26Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 17.28C51.0885 17.28 51.9607 16.501 51.9607 15.54C51.9607 14.5791 51.0885 13.8 50.0126 13.8C48.9366 13.8 48.0645 14.5791 48.0645 15.54C48.0645 16.501 48.9366 17.28 50.0126 17.28Z"
          fill="white"
        />
        <path
          className="path"
          d="M50.0126 42.3001C51.0885 42.3001 51.9607 41.521 51.9607 40.5601C51.9607 39.5991 51.0885 38.8201 50.0126 38.8201C48.9366 38.8201 48.0645 39.5991 48.0645 40.5601C48.0645 41.521 48.9366 42.3001 50.0126 42.3001Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.25084 17.28C3.32675 17.28 4.19894 16.501 4.19894 15.54C4.19894 14.5791 3.32675 13.8 2.25084 13.8C1.17493 13.8 0.302734 14.5791 0.302734 15.54C0.302734 16.501 1.17493 17.28 2.25084 17.28Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.3856 42.45C3.46151 42.45 4.33371 41.6709 4.33371 40.71C4.33371 39.749 3.46151 38.97 2.3856 38.97C1.3097 38.97 0.4375 39.749 0.4375 40.71C0.4375 41.6709 1.3097 42.45 2.3856 42.45Z"
          fill="white"
        />
        <path
          className="path"
          d="M49.8773 29.9399C51.1202 29.9399 52.1277 29.04 52.1277 27.9299C52.1277 26.8198 51.1202 25.9199 49.8773 25.9199C48.6345 25.9199 47.627 26.8198 47.627 27.9299C47.627 29.04 48.6345 29.9399 49.8773 29.9399Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2465 48.29C39.4893 48.29 40.4969 47.3901 40.4969 46.28C40.4969 45.1699 39.4893 44.27 38.2465 44.27C37.0036 44.27 35.9961 45.1699 35.9961 46.28C35.9961 47.3901 37.0036 48.29 38.2465 48.29Z"
          fill="white"
        />
        <path
          className="path"
          d="M14.2856 48.29C15.5284 48.29 16.5359 47.3901 16.5359 46.28C16.5359 45.1699 15.5284 44.27 14.2856 44.27C13.0427 44.27 12.0352 45.1699 12.0352 46.28C12.0352 47.3901 13.0427 48.29 14.2856 48.29Z"
          fill="white"
        />
        <path
          className="path"
          d="M13.8832 11.3901C15.1261 11.3901 16.1336 10.4902 16.1336 9.38012C16.1336 8.27003 15.1261 7.37012 13.8832 7.37012C12.6403 7.37012 11.6328 8.27003 11.6328 9.38012C11.6328 10.4902 12.6403 11.3901 13.8832 11.3901Z"
          fill="white"
        />
        <path
          className="path"
          d="M2.2504 29.9399C3.49326 29.9399 4.50079 29.04 4.50079 27.9299C4.50079 26.8198 3.49326 25.9199 2.2504 25.9199C1.00754 25.9199 0 26.8198 0 27.9299C0 29.04 1.00754 29.9399 2.2504 29.9399Z"
          fill="white"
        />
        <path
          className="path"
          d="M38.2465 11.3901C39.4893 11.3901 40.4969 10.4902 40.4969 9.38012C40.4969 8.27003 39.4893 7.37012 38.2465 7.37012C37.0036 7.37012 35.9961 8.27003 35.9961 9.38012C35.9961 10.4902 37.0036 11.3901 38.2465 11.3901Z"
          fill="white"
        />
        <path
          className="path"
          d="M27.4303 30.48C29.5945 30.48 31.3489 28.913 31.3489 26.98C31.3489 25.047 29.5945 23.48 27.4303 23.48C25.2661 23.48 23.5117 25.047 23.5117 26.98C23.5117 28.913 25.2661 30.48 27.4303 30.48Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.431 17.28C29.218 17.28 30.6666 15.9861 30.6666 14.39C30.6666 12.7939 29.218 11.5 27.431 11.5C25.644 11.5 24.1953 12.7939 24.1953 14.39C24.1953 15.9861 25.644 17.28 27.431 17.28Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6106 23.7299C41.3976 23.7299 42.8463 22.4361 42.8463 20.84C42.8463 19.2438 41.3976 17.95 39.6106 17.95C37.8236 17.95 36.375 19.2438 36.375 20.84C36.375 22.4361 37.8236 23.7299 39.6106 23.7299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6106 36.32C41.3976 36.32 42.8463 35.0261 42.8463 33.43C42.8463 31.8339 41.3976 30.54 39.6106 30.54C37.8236 30.54 36.375 31.8339 36.375 33.43C36.375 35.0261 37.8236 36.32 39.6106 36.32Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.431 42.4599C29.218 42.4599 30.6666 41.166 30.6666 39.5699C30.6666 37.9738 29.218 36.6799 27.431 36.6799C25.644 36.6799 24.1953 37.9738 24.1953 39.5699C24.1953 41.166 25.644 42.4599 27.431 42.4599Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2493 23.7299C17.0363 23.7299 18.485 22.4361 18.485 20.84C18.485 19.2438 17.0363 17.95 15.2493 17.95C13.4623 17.95 12.0137 19.2438 12.0137 20.84C12.0137 22.4361 13.4623 23.7299 15.2493 23.7299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.6517 36.32C17.4387 36.32 18.8873 35.0261 18.8873 33.43C18.8873 31.8339 17.4387 30.54 15.6517 30.54C13.8647 30.54 12.416 31.8339 12.416 33.43C12.416 35.0261 13.8647 36.32 15.6517 36.32Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.2508 3.48C28.3267 3.48 29.1989 2.70098 29.1989 1.74C29.1989 0.779025 28.3267 0 27.2508 0C26.1749 0 25.3027 0.779025 25.3027 1.74C25.3027 2.70098 26.1749 3.48 27.2508 3.48Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M27.4305 53.1199C28.5064 53.1199 29.3786 52.3409 29.3786 51.3799C29.3786 50.4189 28.5064 49.6399 27.4305 49.6399C26.3546 49.6399 25.4824 50.4189 25.4824 51.3799C25.4824 52.3409 26.3546 53.1199 27.4305 53.1199Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3778 16.1299C52.4537 16.1299 53.3259 15.3509 53.3259 14.3899C53.3259 13.4289 52.4537 12.6499 51.3778 12.6499C50.3019 12.6499 49.4297 13.4289 49.4297 14.3899C49.4297 15.3509 50.3019 16.1299 51.3778 16.1299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3778 41.1599C52.4537 41.1599 53.3259 40.3809 53.3259 39.4199C53.3259 38.459 52.4537 37.6799 51.3778 37.6799C50.3019 37.6799 49.4297 38.459 49.4297 39.4199C49.4297 40.3809 50.3019 41.1599 51.3778 41.1599Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62779 16.1299C4.7037 16.1299 5.5759 15.3509 5.5759 14.3899C5.5759 13.4289 4.7037 12.6499 3.62779 12.6499C2.55188 12.6499 1.67969 13.4289 1.67969 14.3899C1.67969 15.3509 2.55188 16.1299 3.62779 16.1299Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.76256 41.3101C4.83846 41.3101 5.71066 40.5311 5.71066 39.5701C5.71066 38.6091 4.83846 37.8301 3.76256 37.8301C2.68665 37.8301 1.81445 38.6091 1.81445 39.5701C1.81445 40.5311 2.68665 41.3101 3.76256 41.3101Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.2445 28.8C52.4874 28.8 53.4949 27.9001 53.4949 26.79C53.4949 25.6799 52.4874 24.78 51.2445 24.78C50.0017 24.78 48.9941 25.6799 48.9941 26.79C48.9941 27.9001 50.0017 28.8 51.2445 28.8Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6117 47.1499C40.8546 47.1499 41.8621 46.25 41.8621 45.1399C41.8621 44.0298 40.8546 43.1299 39.6117 43.1299C38.3689 43.1299 37.3613 44.0298 37.3613 45.1399C37.3613 46.25 38.3689 47.1499 39.6117 47.1499Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.6527 47.1499C16.8956 47.1499 17.9031 46.25 17.9031 45.1399C17.9031 44.0298 16.8956 43.1299 15.6527 43.1299C14.4099 43.1299 13.4023 44.0298 13.4023 45.1399C13.4023 46.25 14.4099 47.1499 15.6527 47.1499Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M15.2484 10.24C16.4913 10.24 17.4988 9.34006 17.4988 8.22997C17.4988 7.11988 16.4913 6.21997 15.2484 6.21997C14.0056 6.21997 12.998 7.11988 12.998 8.22997C12.998 9.34006 14.0056 10.24 15.2484 10.24Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M3.62735 28.8C4.87021 28.8 5.87774 27.9001 5.87774 26.79C5.87774 25.6799 4.87021 24.78 3.62735 24.78C2.38449 24.78 1.37695 25.6799 1.37695 26.79C1.37695 27.9001 2.38449 28.8 3.62735 28.8Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M39.6117 10.24C40.8546 10.24 41.8621 9.34006 41.8621 8.22997C41.8621 7.11988 40.8546 6.21997 39.6117 6.21997C38.3689 6.21997 37.3613 7.11988 37.3613 8.22997C37.3613 9.34006 38.3689 10.24 39.6117 10.24Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M69.4375 2.07007H75.9872C78.7862 2.07007 80.9134 2.63007 82.3801 3.76007C83.8468 4.89007 84.5745 6.52007 84.5745 8.66007C84.5745 10.8001 83.7908 12.3901 82.2457 13.5301C80.6895 14.6701 78.4727 15.2501 75.5953 15.2501H69.4375V2.07007ZM81.4396 8.63007C81.4396 5.75007 79.6259 4.31007 76.0096 4.31007H72.4604V12.9801H75.6849C79.5251 12.9801 81.4508 11.5301 81.4508 8.63007H81.4396Z"
          fill="white"
        />
        <path className="path" d="M87.5527 2.07007H90.6092V15.2401H87.5527V2.07007Z" fill="white" />
        <path
          className="path"
          d="M93.5762 8.70999C93.5762 7.24999 93.9232 5.99999 94.6286 4.95999C95.3339 3.91999 96.308 3.11999 97.5731 2.56999C98.8383 2.01999 100.294 1.73999 101.94 1.73999C103.91 1.73999 105.489 2.09999 106.664 2.82999C107.84 3.55999 108.646 4.52999 109.06 5.73999L106.004 6.08999C105.701 5.36999 105.209 4.82999 104.526 4.47999C103.843 4.13999 102.947 3.95999 101.85 3.95999C100.182 3.95999 98.9055 4.37999 98.0322 5.20999C97.1477 6.04999 96.711 7.19999 96.711 8.65999C96.711 10.12 97.1925 11.33 98.1441 12.13C99.0958 12.93 100.473 13.33 102.264 13.33C103.944 13.33 105.366 13.08 106.541 12.59V10.19H102.309V8.09999H109.374V13.75C108.467 14.33 107.392 14.77 106.149 15.09C104.907 15.4 103.619 15.56 102.264 15.56C100.462 15.56 98.9055 15.29 97.6067 14.74C96.308 14.19 95.3116 13.41 94.6174 12.39C93.9232 11.37 93.5762 10.14 93.5762 8.69999V8.70999Z"
          fill="white"
        />
        <path className="path" d="M112.475 2.07007H115.531V15.2401H112.475V2.07007Z" fill="white" />
        <path
          className="path"
          d="M123.503 4.36007H117.658V2.07007H132.381V4.36007H126.559V15.2401H123.503V4.36007Z"
          fill="white"
        />
        <path
          className="path"
          d="M134.89 15.2401H131.732L138.551 2.07007H141.954L148.795 15.2401H145.537L143.97 12.1201H136.435L134.901 15.2401H134.89ZM140.208 4.39007L137.42 10.0001H142.973L140.208 4.39007Z"
          fill="white"
        />
        <path
          className="path"
          d="M150.934 2.07007H153.99V12.9501H163.115L162.723 15.2401H150.934V2.07007Z"
          fill="white"
        />
        <path
          className="path"
          d="M69.4375 20.51H76.5582C80.6335 20.51 82.6712 21.94 82.6712 24.79C82.6712 26.27 82.145 27.36 81.0925 28.06C80.0401 28.77 78.4503 29.12 76.3118 29.12H72.4268V33.68H69.4375V20.51ZM79.6147 24.77C79.6147 24.05 79.3572 23.52 78.8309 23.18C78.3047 22.84 77.4874 22.68 76.3566 22.68H72.438V26.96H76.323C77.4538 26.96 78.2823 26.78 78.8197 26.42C79.3572 26.06 79.6259 25.51 79.6259 24.76L79.6147 24.77Z"
          fill="white"
        />
        <path
          className="path"
          d="M85.7285 28.19V20.52H88.785V28.36C88.785 29.52 89.1097 30.37 89.7815 30.91C90.442 31.46 91.4721 31.73 92.8828 31.73C94.2935 31.73 95.3235 31.46 95.9841 30.91C96.6446 30.36 96.9805 29.51 96.9805 28.36V20.52H100.037V28.19C100.037 30.19 99.4324 31.67 98.2232 32.61C97.0141 33.55 95.2339 34.02 92.894 34.02C90.554 34.02 88.7626 33.55 87.5535 32.61C86.3443 31.67 85.7509 30.2 85.7509 28.19H85.7285Z"
          fill="white"
        />
        <path
          className="path"
          d="M102.768 31.69L112.419 22.73H102.958V20.52H116.259V22.51L106.619 31.47H116.639L116.248 33.68H102.779V31.69H102.768Z"
          fill="white"
        />
        <path
          className="path"
          d="M118.566 31.69L128.217 22.73H118.757V20.52H132.058V22.51L122.418 31.47H132.438L132.046 33.68H118.578V31.69H118.566Z"
          fill="white"
        />
        <path className="path" d="M135.998 20.51H139.055V31.39H148.179L147.787 33.68H135.998V20.51Z" fill="white" />
        <path
          className="path"
          d="M150.979 20.51H163.205V22.65H153.99V25.86H162.746V28H153.99V31.54H163.652L163.283 33.68H150.979V20.51Z"
          fill="white"
        />
        <path className="path" d="M69.4375 38.96H72.494V52.13H69.4375V38.96Z" fill="white" />
        <path
          className="path"
          d="M77.0391 38.96H79.8493L88.6269 48.4V38.96H91.5603V52.13H88.7501L79.9724 42.67V52.13H77.0391V38.96Z"
          fill="white"
        />
        <path
          className="path"
          d="M94.9414 45.5499C94.9414 44.1999 95.2661 42.9999 95.9155 41.9599C96.5648 40.9199 97.4941 40.1099 98.7257 39.5199C99.9572 38.9299 101.413 38.6299 103.126 38.6299C107.156 38.6299 109.619 40.1199 110.504 43.1099L107.492 43.4399C107.145 42.5399 106.63 41.8999 105.947 41.4999C105.253 41.1099 104.312 40.9099 103.114 40.9099C101.569 40.9099 100.36 41.3199 99.4646 42.1399C98.5801 42.9599 98.1323 44.0899 98.1323 45.5399C98.1323 46.9899 98.5801 48.1299 99.4758 48.9399C100.371 49.7599 101.614 50.1699 103.193 50.1699C104.492 50.1699 105.499 49.9599 106.227 49.5299C106.955 49.1099 107.436 48.4399 107.671 47.5499H110.75C110.515 49.1199 109.743 50.3399 108.41 51.1799C107.078 52.0199 105.331 52.4499 103.148 52.4499C101.413 52.4499 99.9348 52.1599 98.7145 51.5799C97.4941 50.9999 96.5648 50.1899 95.9155 49.1499C95.2661 48.1099 94.9414 46.8999 94.9414 45.5299V45.5499Z"
          fill="white"
        />
        <path className="path" d="M113.158 49.3301H116.114V52.1301H113.158V49.3301Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_399_2716">
          <rect className="rect" fill="white" height="54.26" width="163.652" />
        </clipPath>
      </defs>
    </svg>
  );
};
