import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { Menu17 } from "../../icons/Menu17";
import "./style.css";

export const MenuopenTab = () => {
  return (
    <div className="menuopen-TAB">
      <div className="menu-wrapper-2">
        <div className="menu-7">
          <div className="frame-219">
            <div className="text-wrapper-234">ホーム</div>
            <div className="frame-220">
              <div className="frame-221">
                <div className="frame-221">
                  <div className="frame-222">
                    <div className="text-wrapper-234">理念</div>
                    <div className="frame-223">
                      <div className="text-wrapper-235">- 企業理念</div>
                      <div className="text-wrapper-236">- 経営理念</div>
                      <div className="text-wrapper-236">- 社訓</div>
                    </div>
                  </div>
                  <div className="frame-222">
                    <Link className="text-wrapper-237" to="/profile-600">
                      会社概要
                    </Link>
                    <div className="frame-223">
                      <div className="text-wrapper-235">- 代表挨拶</div>
                      <div className="text-wrapper-236">- 概要</div>
                    </div>
                  </div>
                  <div className="frame-222">
                    <Link className="text-wrapper-237" to="/aboutcontents-744">
                      制作について
                    </Link>
                    <div className="frame-223">
                      <div className="text-wrapper-235">- 業務内容</div>
                      <div className="text-wrapper-236">- ご相談の流れ</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-221">
                <div className="frame-224">
                  <div className="text-wrapper-238">ブログ</div>
                  <div className="text-wrapper-239">採用情報</div>
                  <div className="text-wrapper-239">よくある質問</div>
                  <div className="text-wrapper-239">情報セキュリティ基本方針</div>
                  <div className="text-wrapper-239">プライバシーポリシー</div>
                </div>
                <Button
                  arrow38StyleOverrideClassName="button-16"
                  className="button-15"
                  color="seven"
                  device="PC"
                  text="お問い合わせ"
                  to="/contact-600"
                />
              </div>
            </div>
          </div>
          <Menu17 className="menu-17" />
        </div>
      </div>
    </div>
  );
};
