import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Recruite } from "../../components/Recruite";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Noimage41 } from "../../icons/Noimage41";
import { Noimage54 } from "../../icons/Noimage54";
import { Polygon27 } from "../../icons/Polygon27";
import { Polygon35 } from "../../icons/Polygon35";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Recruits = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="recruits"
      style={{
        minWidth:
          screenWidth < 700
            ? "320px"
            : screenWidth >= 700 && screenWidth < 1280
            ? "700px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
        <SpWhite className="header-15" groupClassName="header-16" icon={<Menu3 className="menu-2-instance" />} />
      )}

      {screenWidth >= 1280 && (
        <ColorWhite className="header-17" groupClassName="header-18" menuStyleOverrideClassName="header-19" />
      )}

      <div className="div-16">
        <div className="midashi-3">
          <ElementWrapper
            className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-70"} ${
              screenWidth >= 1280 && "class-71"
            }`}
            headingDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-66"} ${
              screenWidth >= 1280 && "class-67"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-68"
            } ${screenWidth >= 1280 && "class-69"}`}
            headingText="RECRUIT"
            headingText1="採用情報"
            one={
              (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                ? "two"
                : screenWidth >= 1280
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-6"
            style={{
              padding:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "10px 15px 20px"
                  : screenWidth >= 1280
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-207"
              style={{
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "17px"
                    : screenWidth >= 1280
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 700 && screenWidth < 1280 ? "0px 10px" : screenWidth >= 1280 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                <>
                  <Link className="text-wrapper-206" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-6" />
                </>
              )}

              <div
                className="TOP-2"
                style={{
                  fontFamily:
                    screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1280 && <>TOP</>}

                {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && <>採用情報</>}
              </div>
              {screenWidth >= 1280 && (
                <>
                  <Twelve className="instance-node-6" />
                  <div className="text-wrapper-207">採用情報</div>
                  <ImgWrapper className="component-10930-2" img="/img/19.png" />
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="recruit-list"
          style={{
            padding:
              (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                ? "40px 15px"
                : screenWidth >= 1280
                ? "80px 15px"
                : undefined,
          }}
        >
          <div
            className="frame-208"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              display:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "flex"
                  : screenWidth >= 1280
                  ? "inline-flex"
                  : undefined,
              gap:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "30px 10px"
                  : screenWidth >= 1280
                  ? "30px 30px"
                  : undefined,
              maxWidth: screenWidth >= 1280 ? "1200px" : undefined,
              width: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "100%" : undefined,
            }}
          >
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-77"} ${
                screenWidth >= 1280 && "class-78"
              }`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne={screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "one" : undefined}
              icon={<Noimage41 className="noimage-4" />}
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-77"} ${
                screenWidth >= 1280 && "class-78"
              }`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne={screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "one" : undefined}
              icon={<Noimage41 className="noimage-4" />}
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-77"} ${
                screenWidth >= 1280 && "class-78"
              }`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne={screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "one" : undefined}
              icon={
                screenWidth < 700 ? (
                  <Noimage54 className="noimage-4" />
                ) : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? (
                  <Noimage41 className="noimage-4" />
                ) : undefined
              }
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-80"} ${
                screenWidth >= 1280 && "class-81"
              }`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne="two"
              icon={
                screenWidth < 700 ? (
                  <Noimage54 className="noimage-4" />
                ) : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? (
                  <Noimage41 className="noimage-4" />
                ) : undefined
              }
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${screenWidth < 700 && "class-80"} ${
                screenWidth >= 700 && screenWidth < 1280 && "class-82"
              } ${screenWidth >= 1280 && "class-81"}`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne="two"
              icon={
                screenWidth >= 1280 || screenWidth < 700 ? (
                  <Noimage41 className="noimage-4" />
                ) : screenWidth >= 700 && screenWidth < 1280 ? (
                  <Noimage54 className="noimage-4" />
                ) : undefined
              }
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
            <Recruite
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-73"} ${
                screenWidth >= 1280 && "class-74"
              }`}
              divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-72"}`}
              frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-79"}`}
              frameDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-80"} ${
                screenWidth >= 1280 && "class-81"
              }`}
              frameElementClassName={`${
                ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-75"
              } ${screenWidth >= 1280 && "class-76"}`}
              frameOne="two"
              icon={
                screenWidth >= 1280 || screenWidth < 700 ? (
                  <Noimage41 className="noimage-4" />
                ) : screenWidth >= 700 && screenWidth < 1280 ? (
                  <Noimage54 className="noimage-4" />
                ) : undefined
              }
              one="one"
              vector={
                screenWidth < 700
                  ? "/img/vector-5.svg"
                  : screenWidth >= 700 && screenWidth < 1280
                  ? "/img/vector-26.svg"
                  : screenWidth >= 1280
                  ? "/img/vector-17.svg"
                  : undefined
              }
            />
          </div>
          <div
            className="frame-209"
            style={{
              width:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "206.48px"
                  : screenWidth >= 1280
                  ? "218.48px"
                  : undefined,
            }}
          >
            <div
              className="text-wrapper-208"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              1
            </div>
            <div
              className="text-wrapper-209"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              2
            </div>
            <div
              className="text-wrapper-210"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              3
            </div>
            <div
              className="text-wrapper-211"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              4
            </div>
            <div
              className="text-wrapper-212"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              5
            </div>
            <div
              className="text-wrapper-213"
              style={{
                top:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "-1px"
                    : screenWidth >= 1280
                    ? "0"
                    : undefined,
              }}
            >
              6
            </div>
            <Polygon27
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-83"} ${
                screenWidth >= 1280 && "class-84"
              }`}
            />
            <Polygon35
              className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-85"} ${
                screenWidth >= 1280 && "class-86"
              }`}
            />
          </div>
        </div>
        <div
          className="CONTACT-14"
          style={{
            alignSelf: screenWidth >= 1280 || screenWidth < 700 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "center" : undefined,
            padding:
              screenWidth < 700
                ? "80px 30px"
                : screenWidth >= 700 && screenWidth < 1280
                ? "40px 50px"
                : screenWidth >= 1280
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1280 || screenWidth < 700
                ? "100%"
                : screenWidth >= 700 && screenWidth < 1280
                ? "700px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-15"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              width:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "100%"
                  : screenWidth >= 1280
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-210"
              style={{
                gap:
                  screenWidth >= 1280 || screenWidth < 700
                    ? "20px 74px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 700 ? "-10px" : undefined,
                padding:
                  screenWidth < 700
                    ? "25px 60px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "40px 20px"
                    : screenWidth >= 1280
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 700 ? "-3px" : screenWidth >= 700 && screenWidth < 1280 ? "14px" : undefined,
                width:
                  screenWidth < 700
                    ? "280px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "600px"
                    : screenWidth >= 1280
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-29"
                style={{
                  height:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "53px"
                      : screenWidth >= 1280
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 700 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 700 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                  <>
                    <div className="text-wrapper-214">お問い合わせ</div>
                    <div className="text-wrapper-215">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1280 && (
                  <div className="overlap-group-8">
                    <div className="text-wrapper-216">お問い合わせ</div>
                    <div className="text-wrapper-217">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-30"
                style={{
                  height:
                    screenWidth < 700
                      ? "43px"
                      : screenWidth >= 700 && screenWidth < 1280
                      ? "48px"
                      : screenWidth >= 1280
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 700 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 700 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "212px"
                      : screenWidth >= 1280
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-24"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "2px"
                        : screenWidth >= 1280
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 700
                        ? "43px"
                        : screenWidth >= 700 && screenWidth < 1280
                        ? "48px"
                        : screenWidth >= 1280
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "210px"
                        : screenWidth >= 1280
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-23"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-24"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-8"
          style={{
            padding:
              (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                ? "50px 20px"
                : screenWidth >= 1280
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-211"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              flexDirection: screenWidth < 700 ? "column" : undefined,
              flexWrap: screenWidth >= 1280 ? "wrap" : undefined,
              gap: screenWidth < 700 ? "54px" : screenWidth >= 1280 ? "50px 150px" : undefined,
              justifyContent:
                screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "space-between" : undefined,
              padding: screenWidth >= 1280 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "100%"
                  : screenWidth >= 1280
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-212"
              style={{
                alignSelf: screenWidth < 700 ? "stretch" : undefined,
                flex: screenWidth < 700 ? "0 0 auto" : screenWidth >= 1280 ? "1" : undefined,
                flexGrow: screenWidth >= 1280 ? "1" : undefined,
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "24px"
                    : screenWidth >= 1280
                    ? "44px"
                    : undefined,
                width: screenWidth < 700 ? "100%" : screenWidth >= 700 && screenWidth < 1280 ? "205px" : undefined,
              }}
            >
              {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                <LogoWhite22 className="logo-white-28" />
              )}

              {screenWidth >= 1280 && <LogoWhite30 className="logo-white-24" />}

              <div
                className="flexcontainer-25"
                style={{
                  gap:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "3px"
                      : screenWidth >= 1280
                      ? "5px"
                      : undefined,
                }}
              >
                <p
                  className="text-i-25"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p
                  className="text-i-26"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <div
              className="frame-213"
              style={{
                alignSelf: screenWidth < 700 ? "stretch" : undefined,
                display:
                  screenWidth < 700
                    ? "flex"
                    : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                    ? "inline-flex"
                    : undefined,
                gap:
                  screenWidth < 700
                    ? "40px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "30px"
                    : screenWidth >= 1280
                    ? "60px"
                    : undefined,
                width: screenWidth < 700 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-214"
                style={{
                  gap:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "30px"
                      : screenWidth >= 1280
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-215"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-218"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-219"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-220"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-221"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-216"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-222"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/profile-600"
                  >
                    会社概要
                  </Link>
                  <div
                    className="text-wrapper-223"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-224"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-217"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-225"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/aboutcontents-744"
                  >
                    制作について
                  </Link>
                  <div
                    className="text-wrapper-226"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-227"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-218"
                style={{
                  alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
                  display:
                    screenWidth < 700
                      ? "flex"
                      : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                      ? "inline-flex"
                      : undefined,
                  flex:
                    screenWidth < 700
                      ? "1"
                      : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: screenWidth < 700 ? "1" : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-228"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-229"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-230"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-231"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-232"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-233">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
