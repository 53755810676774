import React from "react";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LineWrapper } from "../../components/LineWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { OpenOpenWrapper } from "../../components/OpenOpenWrapper";
import "./style.css";

export const ScreenScreen = () => {
  return (
    <div className="screen-screen">
      <div className="headrer-3">
        <div className="logo-9">
          <div className="frame-469">
            <div className="group-90" />
          </div>
          <OpenOpenWrapper open="open" openOpen="/img/component-2.svg" openOpenClassName="component-9" />
        </div>
        <div className="midashi-18">
          <div className="component-10">
            <div className="text-wrapper-607">企業理念</div>
            <div className="TEXTEXT-8">PHILOSOPHY</div>
          </div>
        </div>
        <div className="frame-470">
          <div className="group-91">
            <div className="text-wrapper-608">TOP</div>
            <ImgWrapper className="component-10930-14" img="/img/1.svg" />
            <div className="text-wrapper-609">企業理念</div>
          </div>
        </div>
      </div>
      <div className="view-wrapper">
        <div className="frame-wrapper-2">
          <div className="frame-471">
            <div className="frame-472">
              <div className="component-11">
                <div className="text-wrapper-610">企業理念</div>
                <div className="TEXTEXT-9">CORPORATE PHILOSOPHY</div>
              </div>
              <div className="flexcontainer-71">
                <p className="text-45">
                  <span className="text-wrapper-611">
                    ITテクノロジーの本来の役割は、
                    <br />
                  </span>
                </p>
                <p className="text-45">
                  <span className="text-wrapper-611">
                    人々の暮らしの格差を無くすこと。
                    <br />
                  </span>
                </p>
                <p className="text-45">
                  <span className="text-wrapper-611">
                    私たちデジタルパズルはDX化の支援をすることで、
                    <br />
                  </span>
                </p>
                <p className="text-45">
                  <span className="text-wrapper-611">全ての人が快適に暮らせる社会創成を目指します。</span>
                </p>
              </div>
              <div className="flexcontainer-72">
                <p className="text-46">
                  <span className="text-wrapper-612">
                    世界中の全ての人たちがストレスなくITを使いこなし、
                    <br />
                  </span>
                </p>
                <p className="text-46">
                  <span className="text-wrapper-612">
                    快適に暮らせる世の中にするため、足りないピースを探し繋げていくのが
                    <br />
                  </span>
                </p>
                <p className="text-46">
                  <span className="text-wrapper-612">デジタルパズルの役目と考えています。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-473">
        <div className="view-9">
          <div className="text-wrapper-613">企業理念</div>
          <div className="text-wrapper-614">CORPORATE PHILOSOPHY</div>
        </div>
        <div className="text-wrapper-615">晴耕雨読に生きる</div>
        <div className="flexcontainer-72">
          <p className="text-47">
            <span className="text-wrapper-612">
              状況や環境に合わせてのびのびと心穏やかに業務を遂行することが、
              <br />
            </span>
          </p>
          <p className="text-47">
            <span className="text-wrapper-612">結果として正しい判断を生み、ひいては成功につながる。</span>
          </p>
        </div>
        <img className="ill-4" alt="Ill" src="/img/ill-03-1.png" />
      </div>
      <div className="div-32">
        <div className="component-12">
          <div className="text-wrapper-616">社訓</div>
          <div className="TEXTEXT-10">MOTTO</div>
        </div>
        <div className="frame-wrapper-2">
          <div className="frame-474">
            <div className="frame-475">
              <div className="text-wrapper-617">不平不満は互いに伝え合い調整できる</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                厳重なルールを作らなくとも社員が不満を一人で抱えず率直に提案・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-475">
              <div className="text-wrapper-617">謙虚であること</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                謙虚でないと得られない学びは世の中にたくさんある。たとえ相手が子供だろうが赤ちゃんだろうが、何事からでも学ぶという姿勢が大事。・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-475">
              <div className="text-wrapper-617">大事な判断が必要な時こそ睡眠をしっかりとること</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                時間を惜しんで徹夜で対応しても決して良い結果にはならない。大事な判断をする時はしっかり睡眠をとって適切な判断ができる状態で対応するべきである。
              </div>
            </div>
            <div className="frame-475">
              <div className="text-wrapper-617">健康第一</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                正しい判断をするためには心身ともに健康であること。人として根本的に大切にするべきことを忘れないで欲しい。
              </div>
            </div>
            <div className="frame-475">
              <div className="text-wrapper-617">隣人を大切にすること</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                隣にいるからこそ気づいてあげられることがある。目の届く範囲にいる人に対して思いやりを持って接することでお互いが幸せになれる。
              </div>
            </div>
            <div className="frame-475">
              <div className="text-wrapper-617">無知は罪</div>
              <LineWrapper className="line-25" line="/img/line-2-1.svg" lineClassName="line-25" />
              <div className="text-wrapper-618">
                学問に限らず、世の中のあらゆる情報を積極的に得ておくことが非常に重要。自分には関係ないことと思い込み、見過ごしてしまうとお客様だけでなく自分自身をも守れなくなる。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-32">
        <div className="frame-476">
          <div className="group-92">
            <div className="text-wrapper-619">お問い合わせ</div>
            <div className="text-wrapper-620">CONTACT</div>
          </div>
          <div className="flexcontainer-72">
            <p className="text-48">
              <span className="text-wrapper-612">
                制作のご依頼・ご相談・ご質問など
                <br />
              </span>
            </p>
            <p className="text-48">
              <span className="text-wrapper-612">お気軽にお問い合わせください。</span>
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-25">
        <div className="frame-477">
          <div className="frame-478">
            <LogoWhite logoWhite="/img/logo-white-3.svg" logoWhiteClassName="logo-white-34" />
            <div className="flexcontainer-72">
              <p className="text-i-65">
                <span className="text-wrapper-621">
                  千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                </span>
              </p>
              <p className="text-i-65">
                <span className="text-wrapper-621">幕張ベイパーク　クロスレジデンスＳ２－１</span>
              </p>
            </div>
          </div>
          <div className="frame-479">
            <div className="frame-478">
              <p className="text-wrapper-622">
                理念
                <br />- 企業理念 <br />- 経営理念
                <br />- 社訓
              </p>
              <div className="text-wrapper-623">
                会社概要
                <br />- 代表挨拶 <br />- 概要
              </div>
              <div className="text-wrapper-623">
                制作について
                <br />- 業務内容
                <br />- ご相談の流れ
              </div>
            </div>
            <div className="frame-478">
              <div className="text-wrapper-624">ブログ</div>
              <div className="text-wrapper-625">採用情報</div>
              <div className="text-wrapper-625">よくある質問</div>
              <div className="text-wrapper-625">情報セキュリティ基本方針</div>
              <div className="text-wrapper-625">プライバシーポリシー</div>
            </div>
          </div>
        </div>
        <div className="group-93">
          <div className="text-wrapper-626">©︎ Digital Puzzle Inc</div>
        </div>
      </footer>
    </div>
  );
};
