/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Polygon27 = ({ className }) => {
  return (
    <svg
      className={`polygon-2-7 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 7 18"
      width="7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0.762667 17L6 9L0.762667 1" stroke="#454545" />
    </svg>
  );
};
