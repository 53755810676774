import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading } from "../../components/Heading";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LineWrapper } from "../../components/LineWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import "./style.css";

export const ProfileScreen = () => {
  return (
    <div className="profile-screen">
      <ColorWhite className="header-42" logoLogoClassName="header-43" />
      <div className="profile-2">
        <div className="midashi-12">
          <ElementWrapper
            className="heading-47"
            headingDivClassName="heading-48"
            headingDivClassNameOverride="heading-49"
            headingText="PROFILE"
            headingText1="会社概要"
            one="one"
          />
          <div className="breadcrumbs-13">
            <div className="frame-405">
              <Link className="text-wrapper-507" to="/home-744">
                TOP
              </Link>
              <ImgWrapper className="component-10930-8" img="/img/27.svg" />
              <div className="text-wrapper-508">会社概要</div>
            </div>
          </div>
        </div>
        <div className="greeting-2">
          <Heading className="heading-50" divClassName="heading-51" one="one" text="GREETING" text1="代表挨拶" />
          <div className="flexcontainer-54">
            <p className="text-35">
              <span className="text-wrapper-509">
                テキストテキストテキスト
                <br />
              </span>
            </p>
            <p className="text-35">
              <span className="text-wrapper-509">
                テキストテキストテキスト
                <br />
              </span>
            </p>
            <p className="text-35">
              <span className="text-wrapper-509">テキストテキストテキスト</span>
            </p>
          </div>
        </div>
        <div className="members-2">
          <Heading
            className="heading-50"
            divClassName="heading-52"
            divClassNameOverride="heading-53"
            one="one"
            text="MEMBERS"
            text1="メンバー紹介"
          />
          <div className="frame-406">
            <div className="member-4">
              <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-18.png" />
              <div className="frame-407">
                <div className="frame-408">
                  <div className="text-wrapper-510">名前　名前</div>
                  <LineWrapper line="/img/line-2.svg" />
                  <div className="text-wrapper-511">肩書き　職種</div>
                </div>
                <div className="frame-409">
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-20">
              <div className="grayline-8">
                <img className="line-22" alt="Line" src="/img/line-13-10.svg" />
              </div>
            </div>
            <div className="member-4">
              <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-18-1-2x.png" />
              <div className="frame-407">
                <div className="frame-408">
                  <div className="text-wrapper-510">名前　名前</div>
                  <LineWrapper line="/img/line-2.svg" />
                  <div className="text-wrapper-511">肩書き　職種</div>
                </div>
                <div className="frame-409">
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-20">
              <div className="grayline-8">
                <img className="line-22" alt="Line" src="/img/line-13-10.svg" />
              </div>
            </div>
            <div className="member-4">
              <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-18-2-2x.png" />
              <div className="frame-407">
                <div className="frame-408">
                  <div className="text-wrapper-510">名前　名前</div>
                  <LineWrapper line="/img/line-2.svg" />
                  <div className="text-wrapper-511">肩書き　職種</div>
                </div>
                <div className="frame-409">
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div className="frame-410">
                    <div className="text-wrapper-512">2000年</div>
                    <div className="text-wrapper-513">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CONTACT-32">
          <div className="CONTACT-33">
            <div className="frame-411">
              <div className="group-72">
                <div className="overlap-group-17">
                  <div className="text-wrapper-514">お問い合わせ</div>
                  <div className="text-wrapper-515">CONTACT</div>
                </div>
              </div>
              <div className="group-73">
                <div className="flexcontainer-55">
                  <p className="text-i-54">
                    <span className="text-wrapper-516">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-54">
                    <span className="text-wrapper-516">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-19">
          <div className="frame-412">
            <div className="frame-413">
              <LogoWhite logoWhite="/img/logo-white-23.svg" logoWhiteClassName="logo-white-21" />
              <div className="flexcontainer-56">
                <p className="text-i-55">
                  <span className="text-wrapper-517">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-55">
                  <span className="text-wrapper-517">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-414">
              <div className="frame-413">
                <div className="frame-415">
                  <div className="text-wrapper-518">理念</div>
                  <div className="text-wrapper-519">- 企業理念</div>
                  <div className="text-wrapper-519"> - 経営理念</div>
                  <div className="text-wrapper-519"> - 社訓</div>
                </div>
                <div className="frame-415">
                  <div className="text-wrapper-518">会社概要</div>
                  <div className="text-wrapper-519">- 代表挨拶</div>
                  <div className="text-wrapper-519">- 概要</div>
                </div>
                <div className="frame-415">
                  <div className="text-wrapper-518">制作について</div>
                  <div className="text-wrapper-519">- 業務内容</div>
                  <div className="text-wrapper-519">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-413">
                <div className="text-wrapper-518">ブログ</div>
                <div className="text-wrapper-519">採用情報</div>
                <Link className="text-wrapper-520" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-519">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-520" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-521">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
