import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Blog } from "../../components/Blog";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { SpWhite } from "../../components/SpWhite";
import { Arrow31 } from "../../icons/Arrow31";
import { Arrow38 } from "../../icons/Arrow38";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Noimage } from "../../icons/Noimage";
import { Noimage41 } from "../../icons/Noimage41";
import { Noimage54 } from "../../icons/Noimage54";
import { Polygon27 } from "../../icons/Polygon27";
import { Polygon35 } from "../../icons/Polygon35";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Blogs = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="blogs"
      style={{
        height: screenWidth >= 1280 ? "2379px" : undefined,
        minWidth:
          screenWidth < 700
            ? "320px"
            : screenWidth >= 700 && screenWidth < 1280
            ? "700px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
        <SpWhite className="header-31" icon={<Menu3 className="menu-11" />} />
      )}

      {screenWidth >= 1280 && (
        <ColorWhite className="header-32" groupClassName="header-33" menuStyleOverrideClassName="header-34" />
      )}

      <div className="div-21">
        <div className="midashi-7">
          <ElementWrapper
            className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-118"} ${
              screenWidth >= 1280 && "class-119"
            }`}
            headingDivClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-115"} ${
              screenWidth >= 1280 && "class-116"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-117"
            } ${screenWidth >= 1280 && "class-115"}`}
            headingText="BLOG"
            headingText1="ブログ"
            one={
              (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                ? "two"
                : screenWidth >= 1280
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-10"
            style={{
              padding:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "10px 15px 20px"
                  : screenWidth >= 1280
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-279"
              style={{
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "17px"
                    : screenWidth >= 1280
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 700 && screenWidth < 1280 ? "0px 10px" : screenWidth >= 1280 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                <>
                  <Link className="text-wrapper-344" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-9" />
                </>
              )}

              <div
                className="TOP-5"
                style={{
                  fontFamily:
                    screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1280 && <>TOP</>}

                {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && <>ブログ</>}
              </div>
              {screenWidth >= 1280 && (
                <>
                  <Twelve className="instance-node-9" />
                  <div className="text-wrapper-345">ブログ</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="div-22"
          style={{
            padding:
              screenWidth < 700
                ? "40px 15px"
                : screenWidth >= 700 && screenWidth < 1280
                ? "40px 30px"
                : screenWidth >= 1280
                ? "80px 15px"
                : undefined,
          }}
        >
          <div
            className="frame-280"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              display:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "flex"
                  : screenWidth >= 1280
                  ? "inline-flex"
                  : undefined,
              gap:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "20px"
                  : screenWidth >= 1280
                  ? "40px"
                  : undefined,
              width: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "100%" : undefined,
            }}
          >
            <div
              className="frame-281"
              style={{
                alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
                display:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "flex"
                    : screenWidth >= 1280
                    ? "inline-flex"
                    : undefined,
                flexWrap: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "wrap" : undefined,
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "10px 10px"
                    : screenWidth >= 1280
                    ? "20px"
                    : undefined,
                width: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-282"
                style={{
                  alignItems:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "flex-start" : undefined,
                  display: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "inline-flex" : undefined,
                  flex: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "0 0 auto" : undefined,
                  flexDirection: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "column" : undefined,
                  gap: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "3px" : undefined,
                  height: screenWidth >= 1280 ? "19px" : undefined,
                  width: screenWidth >= 1280 ? "56px" : undefined,
                }}
              >
                <div
                  className="text-wrapper-346"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-font-family)"
                        : screenWidth >= 1280
                        ? "var(--tag-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-font-size)"
                        : screenWidth >= 1280
                        ? "var(--tag-font-size)"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-font-style)"
                        : screenWidth >= 1280
                        ? "var(--tag-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-font-weight)"
                        : screenWidth >= 1280
                        ? "var(--tag-font-weight)"
                        : undefined,
                    left: screenWidth >= 1280 ? "0" : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-letter-spacing)"
                        : screenWidth >= 1280
                        ? "var(--tag-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--smalltextbold-SP-line-height)"
                        : screenWidth >= 1280
                        ? "var(--tag-line-height)"
                        : undefined,
                    marginTop: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "-1.00px" : undefined,
                    position:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "relative"
                        : screenWidth >= 1280
                        ? "absolute"
                        : undefined,
                    top: screenWidth >= 1280 ? "0" : undefined,
                    width: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "fit-content" : undefined,
                  }}
                >
                  カテゴリ
                </div>
                <img
                  className="line-16"
                  style={{
                    alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
                    left: screenWidth >= 1280 ? "0" : undefined,
                    marginBottom:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "-1.50px" : undefined,
                    position:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "relative"
                        : screenWidth >= 1280
                        ? "absolute"
                        : undefined,
                    top: screenWidth >= 1280 ? "18px" : undefined,
                    width:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "100%"
                        : screenWidth >= 1280
                        ? "52px"
                        : undefined,
                  }}
                  alt="Line"
                  src={
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "/img/line-5-1.svg"
                      : screenWidth >= 1280
                      ? "/img/line-5.svg"
                      : undefined
                  }
                />
              </div>
              <div
                className="text-wrapper-347"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-348"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-349"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-350"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-351"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                  marginTop: screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "-1.00px" : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-352"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                  marginTop: screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "-1.00px" : undefined,
                }}
              >
                カテゴリ
              </div>
              <div
                className="text-wrapper-353"
                style={{
                  fontFamily:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-family)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-size)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-style)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-font-weight)"
                      : screenWidth >= 1280
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-letter-spacing)"
                      : screenWidth >= 1280
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "var(--smalltextbold-SP-line-height)"
                      : screenWidth >= 1280
                      ? "var(--tag-line-height)"
                      : undefined,
                  marginTop: screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "-1.00px" : undefined,
                }}
              >
                カテゴリ
              </div>
            </div>
            <div
              className="frame-283"
              style={{
                alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
                display:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "flex"
                    : screenWidth >= 1280
                    ? "inline-flex"
                    : undefined,
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "30px 10px"
                    : screenWidth >= 1280
                    ? "30px 30px"
                    : undefined,
                maxWidth: screenWidth >= 1280 ? "1200px" : undefined,
                width: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "100%" : undefined,
              }}
            >
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? (
                    <Noimage41 className="noimage-5" />
                  ) : screenWidth >= 1280 ? (
                    <Noimage className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? (
                    <Noimage41 className="noimage-5" />
                  ) : screenWidth >= 1280 ? (
                    <Noimage className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  screenWidth < 700 ? (
                    <Noimage54 className="noimage-5" />
                  ) : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? (
                    <Noimage41 className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? (
                    <Noimage41 className="noimage-5" />
                  ) : screenWidth >= 1280 ? (
                    <Noimage className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  screenWidth < 700 ? (
                    <Noimage41 className="noimage-5" />
                  ) : screenWidth >= 700 && screenWidth < 1280 ? (
                    <Noimage54 className="noimage-5" />
                  ) : screenWidth >= 1280 ? (
                    <Noimage className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
              <Blog
                className={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-124"} ${
                  screenWidth >= 1280 && "class-125"
                }`}
                divClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-126"}`}
                divClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-123"
                }`}
                frameClassName={`${((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-120"} ${
                  screenWidth >= 1280 && "class-121"
                }`}
                frameClassNameOverride={`${
                  ((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && "class-122"
                }`}
                icon={
                  screenWidth >= 1280 || screenWidth < 700 ? (
                    <Noimage41 className="noimage-5" />
                  ) : screenWidth >= 700 && screenWidth < 1280 ? (
                    <Noimage54 className="noimage-5" />
                  ) : undefined
                }
                one="one"
                vector={
                  screenWidth < 700
                    ? "/img/vector-38.svg"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "/img/vector-44.svg"
                    : screenWidth >= 1280
                    ? "/img/vector-14.svg"
                    : undefined
                }
              />
            </div>
          </div>
          <div
            className="frame-284"
            style={{
              alignItems: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "center" : undefined,
              display: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "inline-flex" : undefined,
              flex: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "0 0 auto" : undefined,
              gap: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "20px" : undefined,
              height: screenWidth >= 1280 ? "16px" : undefined,
              justifyContent: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "center" : undefined,
              padding: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "0px 3px" : undefined,
              width: screenWidth >= 1280 ? "206.48px" : undefined,
            }}
          >
            {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
              <>
                <Arrow31 className="polygon-3-3" />
                <div className="text-wrapper-354">1</div>
                <div className="text-wrapper-354">2</div>
                <div className="text-wrapper-354">3</div>
                <div className="text-wrapper-354">4</div>
                <div className="text-wrapper-354">5</div>
                <div className="text-wrapper-354">6</div>
                <Arrow38 className="arrow-5" color="#454545" />
              </>
            )}

            {screenWidth >= 1280 && (
              <>
                <div className="text-wrapper-355">1</div>
                <div className="text-wrapper-356">2</div>
                <div className="text-wrapper-357">3</div>
                <div className="text-wrapper-358">4</div>
                <div className="text-wrapper-359">5</div>
                <div className="text-wrapper-360">6</div>
                <Polygon27 className="polygon-2-8" />
                <Polygon35 className="polygon-3-5" />
              </>
            )}
          </div>
        </div>
        <div
          className="CONTACT-22"
          style={{
            alignSelf: screenWidth >= 1280 || screenWidth < 700 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "center" : undefined,
            padding:
              screenWidth < 700
                ? "80px 30px"
                : screenWidth >= 700 && screenWidth < 1280
                ? "40px 50px"
                : screenWidth >= 1280
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1280 || screenWidth < 700
                ? "100%"
                : screenWidth >= 700 && screenWidth < 1280
                ? "700px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-23"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              width:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "100%"
                  : screenWidth >= 1280
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-285"
              style={{
                gap:
                  screenWidth >= 1280 || screenWidth < 700
                    ? "20px 74px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 700 ? "-10px" : undefined,
                padding:
                  screenWidth < 700
                    ? "25px 60px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "40px 20px"
                    : screenWidth >= 1280
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 700 ? "-3px" : screenWidth >= 700 && screenWidth < 1280 ? "14px" : undefined,
                width:
                  screenWidth < 700
                    ? "280px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "600px"
                    : screenWidth >= 1280
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-38"
                style={{
                  height:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "53px"
                      : screenWidth >= 1280
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 700 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 700 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                  <>
                    <div className="text-wrapper-361">お問い合わせ</div>
                    <div className="text-wrapper-362">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1280 && (
                  <div className="overlap-group-12">
                    <div className="text-wrapper-363">お問い合わせ</div>
                    <div className="text-wrapper-364">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-39"
                style={{
                  height:
                    screenWidth < 700
                      ? "43px"
                      : screenWidth >= 700 && screenWidth < 1280
                      ? "48px"
                      : screenWidth >= 1280
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 700 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 700 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "212px"
                      : screenWidth >= 1280
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-37"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "2px"
                        : screenWidth >= 1280
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 700
                        ? "43px"
                        : screenWidth >= 700 && screenWidth < 1280
                        ? "48px"
                        : screenWidth >= 1280
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "210px"
                        : screenWidth >= 1280
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-37"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-38"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1280
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1280
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-12"
          style={{
            padding:
              (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                ? "50px 20px"
                : screenWidth >= 1280
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-286"
            style={{
              alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
              flexDirection: screenWidth < 700 ? "column" : undefined,
              flexWrap: screenWidth >= 1280 ? "wrap" : undefined,
              gap: screenWidth < 700 ? "54px" : screenWidth >= 1280 ? "50px 150px" : undefined,
              justifyContent:
                screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280) ? "space-between" : undefined,
              padding: screenWidth >= 1280 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                  ? "100%"
                  : screenWidth >= 1280
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-287"
              style={{
                alignSelf: screenWidth < 700 ? "stretch" : undefined,
                flex: screenWidth < 700 ? "0 0 auto" : screenWidth >= 1280 ? "1" : undefined,
                flexGrow: screenWidth >= 1280 ? "1" : undefined,
                gap:
                  (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                    ? "24px"
                    : screenWidth >= 1280
                    ? "44px"
                    : undefined,
                width: screenWidth < 700 ? "100%" : screenWidth >= 700 && screenWidth < 1280 ? "205px" : undefined,
              }}
            >
              {((screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700) && (
                <LogoWhite22 className="logo-white-19" />
              )}

              {screenWidth >= 1280 && <LogoWhite30 className="logo-white-14" />}

              <div
                className="flexcontainer-38"
                style={{
                  gap:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "3px"
                      : screenWidth >= 1280
                      ? "5px"
                      : undefined,
                }}
              >
                <p
                  className="text-i-39"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p
                  className="text-i-40"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "'Noto Sans JP', Helvetica"
                        : screenWidth >= 1280
                        ? "var(--text-font-family)"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "13px"
                        : screenWidth >= 1280
                        ? "var(--text-font-size)"
                        : undefined,
                    fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "400"
                        : screenWidth >= 1280
                        ? "var(--text-font-weight)"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "0.26px"
                        : screenWidth >= 1280
                        ? "var(--text-letter-spacing)"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "18px"
                        : screenWidth >= 1280
                        ? "var(--text-line-height)"
                        : undefined,
                  }}
                >
                  <span
                    className="text-wrapper-670"
                    style={{
                      fontFamily: screenWidth >= 1280 ? "var(--text-font-family)" : undefined,
                      fontSize: screenWidth >= 1280 ? "var(--text-font-size)" : undefined,
                      fontStyle: screenWidth >= 1280 ? "var(--text-font-style)" : undefined,
                      fontWeight: screenWidth >= 1280 ? "var(--text-font-weight)" : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "0.03px"
                          : screenWidth >= 1280
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight: screenWidth >= 1280 ? "var(--text-line-height)" : undefined,
                    }}
                  >
                    幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <div
              className="frame-288"
              style={{
                alignSelf: screenWidth < 700 ? "stretch" : undefined,
                display:
                  screenWidth < 700
                    ? "flex"
                    : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                    ? "inline-flex"
                    : undefined,
                gap:
                  screenWidth < 700
                    ? "40px"
                    : screenWidth >= 700 && screenWidth < 1280
                    ? "30px"
                    : screenWidth >= 1280
                    ? "60px"
                    : undefined,
                width: screenWidth < 700 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-289"
                style={{
                  gap:
                    (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                      ? "30px"
                      : screenWidth >= 1280
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-290"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-365"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-366"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-367"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-368"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-291"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-369"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/profile-600"
                  >
                    会社概要
                  </Link>
                  <div
                    className="text-wrapper-370"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-371"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-292"
                  style={{
                    gap:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "4px"
                        : screenWidth >= 1280
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-372"
                    style={{
                      fontFamily:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1280
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280
                          ? "20px"
                          : undefined,
                    }}
                    to="/aboutcontents-744"
                  >
                    制作について
                  </Link>
                  <div
                    className="text-wrapper-373"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-374"
                    style={{
                      fontFamily:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "11px"
                          : screenWidth >= 1280
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 700 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 700
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 700 && screenWidth < 1280
                          ? "0.55px"
                          : screenWidth >= 1280
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 700
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-293"
                style={{
                  alignSelf: (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700 ? "stretch" : undefined,
                  display:
                    screenWidth < 700
                      ? "flex"
                      : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                      ? "inline-flex"
                      : undefined,
                  flex:
                    screenWidth < 700
                      ? "1"
                      : screenWidth >= 1280 || (screenWidth >= 700 && screenWidth < 1280)
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: screenWidth < 700 ? "1" : undefined,
                  padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-375"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-376"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-377"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-378"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-379"
                  style={{
                    fontFamily:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1280
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1280
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1280
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1280
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 700 && screenWidth < 1280) || screenWidth < 700
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1280
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-380">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
