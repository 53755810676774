/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Line2 = ({ line = "/img/line-2.svg" }) => {
  return (
    <div className="line-2">
      <img className="line-5" alt="Line" src={line} />
    </div>
  );
};

Line2.propTypes = {
  line: PropTypes.string,
};
