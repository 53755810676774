/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Menu } from "../../icons/Menu";
import { Logo } from "../Logo";
import "./style.css";

export const ColorWhite = ({
  className,
  groupClassName,
  menuStyleOverrideClassName,
  logoLogoClassName,
  icon = <Menu className={menuStyleOverrideClassName} />,
}) => {
  return (
    <div className={`color-white ${className}`}>
      <div className={`logo-wrapper ${groupClassName}`}>
        <Logo className={logoLogoClassName} />
      </div>
      {icon}
    </div>
  );
};
