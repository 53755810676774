import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Button } from "../../components/Button";
import { Checklist } from "../../components/Checklist";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading } from "../../components/Heading";
import { ImgWrapper } from "../../components/ImgWrapper";
import { InputField } from "../../components/InputField";
import { LogoWhite } from "../../components/LogoWhite";
import { QA } from "../../components/QA";
import { QAicon } from "../../components/QAicon";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Qaicon3 } from "../../icons/Qaicon3";
import { Qaicon7 } from "../../icons/Qaicon7";
import { Triangle12 } from "../../icons/Triangle12";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Contact = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="contact"
      style={{
        backgroundColor: screenWidth >= 1200 || screenWidth < 744 ? "var(--backwhite)" : undefined,
        minWidth:
          screenWidth < 744
            ? "320px"
            : screenWidth >= 744 && screenWidth < 1200
            ? "744px"
            : screenWidth >= 1200
            ? "1200px"
            : undefined,
      }}
    >
      {screenWidth < 744 && (
        <SpWhite className="header" groupClassName="SP-white-instance" icon={<Menu3 className="menu-3" />} />
      )}

      {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
        <ColorWhite
          className="color-white-instance"
          groupClassName={`${screenWidth >= 1200 && "class-6"}`}
          menuStyleOverrideClassName={`${screenWidth >= 1200 && "class-5"}`}
        />
      )}

      <div className="contacts">
        <div className="midashi">
          <ElementWrapper
            className={`${screenWidth < 744 && "class-11"} ${screenWidth >= 744 && screenWidth < 1200 && "class-12"} ${
              screenWidth >= 1200 && "q-a-instance"
            }`}
            headingDivClassName={`${screenWidth < 744 && "class-7"} ${
              (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-8"
            }`}
            headingDivClassNameOverride={`${screenWidth < 744 && "class-9"} ${
              (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-10"
            }`}
            headingText="CONTACT"
            headingText1="お問い合わせ"
            one={
              screenWidth < 744
                ? "two"
                : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs"
            style={{
              padding:
                screenWidth < 744
                  ? "10px 15px 20px"
                  : screenWidth >= 744 && screenWidth < 1200
                  ? "40px 15px"
                  : screenWidth >= 1200
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-38"
              style={{
                gap:
                  screenWidth < 744
                    ? "17px"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "30px"
                    : undefined,
                padding: screenWidth >= 1200 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
                <Link
                  className="text-wrapper-24"
                  style={{
                    fontFamily:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-font-family)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-font-family)"
                        : undefined,
                    fontSize:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-font-size)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-font-size)"
                        : undefined,
                    fontStyle:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-font-style)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-font-style)"
                        : undefined,
                    fontWeight:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-font-weight)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-font-weight)"
                        : undefined,
                    letterSpacing:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-letter-spacing)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-letter-spacing)"
                        : undefined,
                    lineHeight:
                      screenWidth >= 744 && screenWidth < 1200
                        ? "var(--tag-line-height)"
                        : screenWidth < 744
                        ? "var(--smalltext-SP-line-height)"
                        : undefined,
                  }}
                  to="/home-744"
                >
                  TOP
                </Link>
              )}

              {screenWidth < 744 && <TwentyTwo className="instance-node-2" />}

              {screenWidth >= 744 && screenWidth < 1200 && <ImgWrapper className="component-10930" img="/img/24.svg" />}

              <div
                className="div-6"
                style={{
                  fontFamily:
                    screenWidth < 744
                      ? "var(--smalltext-SP-font-family)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth < 744
                      ? "var(--smalltext-SP-font-size)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth < 744
                      ? "var(--smalltext-SP-font-style)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth < 744
                      ? "var(--smalltext-SP-font-weight)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth < 744
                      ? "var(--smalltext-SP-letter-spacing)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth < 744
                      ? "var(--smalltext-SP-line-height)"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "var(--tag-line-height)"
                      : undefined,
                }}
              >
                {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && <>お問い合わせ</>}

                {screenWidth >= 1200 && <>TOP</>}
              </div>
              {screenWidth >= 1200 && (
                <>
                  <Twelve className="instance-node-2" />
                  <div className="text-wrapper-25">お問い合わせ</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="contactform"
          style={{
            padding:
              screenWidth < 744
                ? "40px 15px"
                : screenWidth >= 744 && screenWidth < 1200
                ? "60px 15px"
                : screenWidth >= 1200
                ? "80px 15px"
                : undefined,
          }}
        >
          <div
            className="flexcontainer"
            style={{
              gap:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "8px"
                  : screenWidth >= 1200
                  ? "5px"
                  : undefined,
            }}
          >
            <p
              className="text"
              style={{
                fontFamily:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-family)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-family)"
                    : undefined,
                fontSize:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-size)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-size)"
                    : undefined,
                fontStyle:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-style)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-weight)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-weight)"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-letter-spacing)"
                    : screenWidth >= 1200
                    ? "var(--lead-letter-spacing)"
                    : undefined,
                lineHeight:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-line-height)"
                    : screenWidth >= 1200
                    ? "var(--lead-line-height)"
                    : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-family)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-size)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-style)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--lead-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-line-height)"
                      : screenWidth >= 1200
                      ? "var(--lead-line-height)"
                      : undefined,
                }}
              >
                ご相談・お見積り、サービス内容についてなど、
                <br />
              </span>
            </p>
            <p
              className="span-wrapper"
              style={{
                fontFamily:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-family)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-family)"
                    : undefined,
                fontSize:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-size)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-size)"
                    : undefined,
                fontStyle:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-style)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-font-weight)"
                    : screenWidth >= 1200
                    ? "var(--lead-font-weight)"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-letter-spacing)"
                    : screenWidth >= 1200
                    ? "var(--lead-letter-spacing)"
                    : undefined,
                lineHeight:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "var(--tag-line-height)"
                    : screenWidth >= 1200
                    ? "var(--lead-line-height)"
                    : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-family)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-size)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-style)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-font-weight)"
                      : screenWidth >= 1200
                      ? "var(--lead-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-letter-spacing)"
                      : screenWidth >= 1200
                      ? "var(--lead-letter-spacing)"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "var(--tag-line-height)"
                      : screenWidth >= 1200
                      ? "var(--lead-line-height)"
                      : undefined,
                }}
              >
                お気軽にお問い合わせください。
              </span>
            </p>
          </div>
          <div
            className="frame-39"
            style={{
              alignSelf: screenWidth < 744 ? "stretch" : undefined,
              display:
                screenWidth < 744
                  ? "flex"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "inline-flex"
                  : undefined,
              flexDirection: screenWidth < 744 ? "column" : undefined,
              gap:
                screenWidth < 744
                  ? "16px"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "30px"
                  : undefined,
              width: screenWidth < 744 ? "100%" : undefined,
            }}
          >
            <div
              className="flexcontainer-79"
              style={{
                alignItems: screenWidth < 744 ? "flex-start" : undefined,
                color: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "#6079c1" : undefined,
                display: screenWidth < 744 ? "inline-flex" : undefined,
                flexDirection: screenWidth < 744 ? "column" : undefined,
                fontFamily:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-font-family)"
                    : undefined,
                fontSize:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-font-size)"
                    : undefined,
                fontStyle:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-font-style)"
                    : undefined,
                fontWeight:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-font-weight)"
                    : undefined,
                gap: screenWidth < 744 ? "2px" : undefined,
                letterSpacing:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-letter-spacing)"
                    : undefined,
                lineHeight:
                  screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "var(--text-bold-line-height)"
                    : undefined,
                position: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "relative" : undefined,
                textAlign: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "right" : undefined,
                width: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "fit-content" : undefined,
              }}
            >
              {screenWidth < 744 && (
                <>
                  <p className="p">
                    <span className="text-wrapper-26">
                      お問い合わせ後、制作までの流れに <br />
                    </span>
                  </p>
                  <p className="p">
                    <span className="text-wrapper-26">ついてはこちらをご確認ください。</span>
                  </p>
                </>
              )}

              {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
                <>
                  お問い合わせ後、制作までの流れに <br />
                  ついてはこちらをご確認ください。
                </>
              )}
            </div>
            <Button
              arrow38StyleOverrideClassName={`${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-15"
              }`}
              color={
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "white"
                  : screenWidth >= 1200
                  ? "seven"
                  : undefined
              }
              device={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "PC"
                  : undefined
              }
              divClassName={`${screenWidth < 744 && "class-13"}`}
              frameClassName={`${screenWidth < 744 && "class-14"}`}
              text="制作までの流れ"
            />
          </div>
          <div
            className="frame-40"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              gap:
                screenWidth < 744
                  ? "30px"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "40px"
                  : undefined,
              padding:
                screenWidth < 744
                  ? "20px 15px 40px"
                  : screenWidth >= 744 && screenWidth < 1200
                  ? "50px 20px"
                  : screenWidth >= 1200
                  ? "50px 60px"
                  : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1000px"
                  : undefined,
            }}
          >
            <div
              className="frame-41"
              style={{
                alignSelf: screenWidth < 744 ? "stretch" : undefined,
                display:
                  screenWidth < 744
                    ? "flex"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "inline-flex"
                    : undefined,
                flexWrap: screenWidth < 744 ? "wrap" : undefined,
                gap:
                  screenWidth < 744
                    ? "7px 30px"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "60px"
                    : undefined,
                width: screenWidth < 744 ? "100%" : undefined,
              }}
            >
              <Checklist
                className={`${screenWidth < 744 && "class-17"} ${
                  (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "checklist-instance"
                }`}
                divClassName={`${screenWidth < 744 && "class-16"}`}
                text={screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "お問い合わせ" : undefined}
              />
              <Checklist
                className="checklist-instance"
                divClassName={`${screenWidth < 744 && "class-16"}`}
                text="お見積もり"
              />
              <Checklist
                className="checklist-instance"
                divClassName={`${screenWidth < 744 && "class-16"}`}
                text="その他"
              />
            </div>
            <InputField
              className="input-field-instance"
              divClassName={`${screenWidth < 744 && "class-18"}`}
              frameClassName={`${screenWidth < 744 && "class-21"}`}
              prop="one"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              requiredRequiredClassName={`${screenWidth >= 744 && screenWidth < 1200 && "class-19"} ${
                screenWidth >= 1200 && "class-20"
              }`}
              text={screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "お名前" : undefined}
              text1={
                screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "お名前を入れてください" : undefined
              }
            />
            <InputField
              className="input-field-instance"
              divClassName={`${screenWidth < 744 && "class-18"}`}
              frameClassName={`${screenWidth < 744 && "class-21"}`}
              prop="one"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              requiredRequiredClassName={`${screenWidth >= 744 && screenWidth < 1200 && "class-19"} ${
                screenWidth >= 1200 && "class-22"
              }`}
              text="会社名"
              text1="会社名を入れてください"
            />
            <InputField
              className="input-field-instance"
              divClassName={`${screenWidth < 744 && "class-18"}`}
              frameClassName={`${screenWidth < 744 && "class-21"}`}
              prop="three"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              text="部署名"
              text1="部署名を入れてください"
            />
            <InputField
              className="input-field-instance"
              divClassName={`${screenWidth < 744 && "class-18"}`}
              frameClassName={`${screenWidth < 744 && "class-21"}`}
              prop="one"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              requiredRequiredClassName={`${screenWidth >= 744 && screenWidth < 1200 && "class-19"} ${
                screenWidth >= 1200 && "class-23"
              }`}
              text="メールアドレス"
              text1="sample@dpi.com"
            />
            <InputField
              className="input-field-instance"
              divClassName={`${screenWidth < 744 && "class-18"}`}
              frameClassName={`${screenWidth < 744 && "class-21"}`}
              prop="one"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              requiredRequiredClassName={`${screenWidth >= 744 && screenWidth < 1200 && "class-19"} ${
                screenWidth >= 1200 && "class-24"
              }`}
              text="電話番号"
              text1="000-0000-0000"
            />
            <InputField
              className={`${screenWidth < 744 && "class-25"} ${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-26"
              }`}
              divClassName={`${screenWidth < 744 && "class-27"}`}
              frameClassName="input-field-2"
              prop="one"
              prop1={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "two"
                  : undefined
              }
              requiredRequiredClassName={`${screenWidth >= 744 && screenWidth < 1200 && "class-19"} ${
                screenWidth >= 1200 && "class-28"
              }`}
              text="お問い合わせ内容"
              text1="お問い合わせ内容を入れてください"
            />
            <Button
              arrow38StyleOverrideClassName={`${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-15"
              }`}
              className={`${screenWidth < 744 && "class-30"} ${
                (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "q-a-instance"
              }`}
              color={
                screenWidth < 744
                  ? "purple"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "one"
                  : undefined
              }
              device={
                screenWidth < 744
                  ? "SP"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "PC"
                  : undefined
              }
              divClassName={`${screenWidth < 744 && "class-29"}`}
              text="問い合わせる"
            />
          </div>
        </div>
        <div
          className="q-a-2"
          style={{
            gap:
              screenWidth < 744
                ? "20px"
                : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                ? "40px"
                : undefined,
            padding:
              screenWidth < 744
                ? "20px 15px 40px"
                : screenWidth >= 744 && screenWidth < 1200
                ? "60px 15px"
                : screenWidth >= 1200
                ? "80px 15px"
                : undefined,
          }}
        >
          <Heading
            className="heading-2"
            divClassName={`${screenWidth < 744 && "class-33"} ${
              (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-34"
            }`}
            divClassNameOverride={`${screenWidth < 744 && "class-31"} ${
              (screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && "class-32"
            }`}
            one={
              screenWidth < 744
                ? "SP"
                : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                ? "one"
                : undefined
            }
            text="Q&amp;A"
            text1="よくある質問"
          />
          <div
            className="frame-42"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              flexDirection: screenWidth < 744 ? "column" : undefined,
              flexWrap: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "wrap" : undefined,
              gap:
                screenWidth < 744
                  ? "12px"
                  : screenWidth >= 744 && screenWidth < 1200
                  ? "12px 12px"
                  : screenWidth >= 1200
                  ? "20px 20px"
                  : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1000px"
                  : undefined,
            }}
          >
            <div
              className="frame-43"
              style={{
                alignSelf: screenWidth < 744 ? "stretch" : undefined,
                flex:
                  screenWidth < 744
                    ? "0 0 auto"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "1"
                    : undefined,
                flexGrow: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "1" : undefined,
                gap:
                  screenWidth < 744
                    ? "15px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "20px"
                    : screenWidth >= 1200
                    ? "30px"
                    : undefined,
                width: screenWidth < 744 ? "100%" : undefined,
              }}
            >
              {(screenWidth >= 1200 || screenWidth < 744) && (
                <>
                  <QA
                    className={`${screenWidth < 744 && "class-37"} ${screenWidth >= 1200 && "checklist-instance"}`}
                    device={screenWidth < 744 ? "SP" : screenWidth >= 1200 ? "PC" : undefined}
                    divClassName={`${screenWidth < 744 && "class-35"}`}
                    frameClassName={`${screenWidth < 744 && "class-35"}`}
                    frameClassNameOverride={`${screenWidth < 744 && "class-36"}`}
                    icon={<Qaicon3 className="q-aicon-2" />}
                    open="open"
                    override={<Qaicon7 className="q-aicon-2" />}
                    triangle12StyleOverrideClassName={`${screenWidth < 744 && "class-38"} ${
                      screenWidth >= 1200 && "triangle"
                    }`}
                  />
                  <QA
                    className={`${screenWidth < 744 && "class-37"} ${screenWidth >= 1200 && "checklist-instance"}`}
                    device={screenWidth < 744 ? "SP" : screenWidth >= 1200 ? "PC" : undefined}
                    divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                    frameClassName1={`${screenWidth < 744 && "class-39"}`}
                    icon1={<Qaicon3 className="q-aicon-2" />}
                    open="one"
                  />
                  <QA
                    className={`${screenWidth < 744 && "class-37"} ${screenWidth >= 1200 && "checklist-instance"}`}
                    device={screenWidth < 744 ? "SP" : screenWidth >= 1200 ? "PC" : undefined}
                    divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                    frameClassName1={`${screenWidth < 744 && "class-39"}`}
                    icon1={<Qaicon3 className="q-aicon-2" />}
                    open="one"
                  />
                  <QA
                    className={`${screenWidth < 744 && "class-37"} ${screenWidth >= 1200 && "checklist-instance"}`}
                    device={screenWidth < 744 ? "SP" : screenWidth >= 1200 ? "PC" : undefined}
                    divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                    frameClassName1={`${screenWidth < 744 && "class-39"}`}
                    icon1={<Qaicon3 className="q-aicon-2" />}
                    open="one"
                  />
                </>
              )}

              {screenWidth >= 744 && screenWidth < 1200 && (
                <>
                  <div className="q-a-3">
                    <div className="frame-44">
                      <div className="frame-45">
                        <Qaicon3 className="q-aicon-2" />
                        <div className="frame-46">
                          <div className="text-wrapper-27">着手前に料金は発生しますか？</div>
                        </div>
                      </div>
                      <Triangle12 className="triangle" />
                    </div>
                    <div className="frame-47">
                      <div className="frame-48">
                        <QAicon one="a" />
                        <div className="frame-49">
                          <div className="text-wrapper-28">
                            テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <QA className="q-a-instance" device="PC" icon1={<Qaicon3 className="q-aicon-2" />} open="one" />
                  <QA className="q-a-instance" device="PC" icon1={<Qaicon3 className="q-aicon-2" />} open="one" />
                  <QA className="q-a-instance" device="PC" icon1={<Qaicon3 className="q-aicon-2" />} open="one" />
                </>
              )}
            </div>
            <div
              className="frame-50"
              style={{
                alignSelf: screenWidth < 744 ? "stretch" : undefined,
                flex:
                  screenWidth < 744
                    ? "0 0 auto"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "1"
                    : undefined,
                flexGrow: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "1" : undefined,
                gap:
                  screenWidth < 744
                    ? "15px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "20px"
                    : screenWidth >= 1200
                    ? "30px"
                    : undefined,
                width: screenWidth < 744 ? "100%" : undefined,
              }}
            >
              <QA
                className={`${screenWidth < 744 && "class-37"} ${
                  screenWidth >= 744 && screenWidth < 1200 && "q-a-instance"
                } ${screenWidth >= 1200 && "checklist-instance"}`}
                device={
                  screenWidth < 744
                    ? "SP"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "PC"
                    : undefined
                }
                divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                frameClassName1={`${screenWidth < 744 && "class-39"}`}
                icon1={<Qaicon3 className="q-aicon-2" />}
                open="one"
              />
              <QA
                className={`${screenWidth < 744 && "class-37"} ${
                  screenWidth >= 744 && screenWidth < 1200 && "q-a-instance"
                } ${screenWidth >= 1200 && "checklist-instance"}`}
                device={
                  screenWidth < 744
                    ? "SP"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "PC"
                    : undefined
                }
                divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                frameClassName1={`${screenWidth < 744 && "class-39"}`}
                icon1={<Qaicon3 className="q-aicon-2" />}
                open="one"
              />
              <QA
                className={`${screenWidth < 744 && "class-37"} ${
                  screenWidth >= 744 && screenWidth < 1200 && "q-a-instance"
                } ${screenWidth >= 1200 && "checklist-instance"}`}
                device={
                  screenWidth < 744
                    ? "SP"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "PC"
                    : undefined
                }
                divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                frameClassName1={`${screenWidth < 744 && "class-39"}`}
                icon1={<Qaicon3 className="q-aicon-2" />}
                open="one"
              />
              <QA
                className={`${screenWidth < 744 && "class-37"} ${
                  screenWidth >= 744 && screenWidth < 1200 && "q-a-instance"
                } ${screenWidth >= 1200 && "checklist-instance"}`}
                device={
                  screenWidth < 744
                    ? "SP"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "PC"
                    : undefined
                }
                divClassNameOverride={`${screenWidth < 744 && "class-35"}`}
                frameClassName1={`${screenWidth < 744 && "class-39"}`}
                icon1={<Qaicon3 className="q-aicon-2" />}
                open="one"
              />
            </div>
          </div>
        </div>
        <div
          className="CONTACT"
          style={{
            justifyContent: screenWidth < 744 ? "center" : undefined,
            padding:
              screenWidth < 744
                ? "80px 30px"
                : screenWidth >= 744 && screenWidth < 1200
                ? "100px 20px"
                : screenWidth >= 1200
                ? "100px 15px"
                : undefined,
          }}
        >
          <div
            className="CONTACT-2"
            style={{
              alignSelf: screenWidth < 744 ? "stretch" : undefined,
              width:
                screenWidth < 744
                  ? "100%"
                  : screenWidth >= 744 && screenWidth < 1200
                  ? "704px"
                  : screenWidth >= 1200
                  ? "700px"
                  : undefined,
            }}
          >
            <div
              className="frame-51"
              style={{
                left: screenWidth < 744 ? "-10px" : screenWidth >= 744 && screenWidth < 1200 ? "2px" : undefined,
                padding:
                  screenWidth < 744
                    ? "25px 60px"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 744 ? "-3px" : undefined,
                width:
                  screenWidth < 744
                    ? "280px"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-4"
                style={{
                  height:
                    screenWidth < 744
                      ? "53px"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 744 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 744 ? "-30.00px" : undefined,
                }}
              >
                {screenWidth < 744 && (
                  <>
                    <div className="text-wrapper-29">お問い合わせ</div>
                    <div className="text-wrapper-30">CONTACT</div>
                  </>
                )}

                {(screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)) && (
                  <div className="overlap-group-3">
                    <div className="text-wrapper-31">お問い合わせ</div>
                    <div className="text-wrapper-32">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="flexcontainer-wrapper"
                style={{
                  height:
                    screenWidth < 744
                      ? "43px"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 744 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 744 ? "-27.00px" : undefined,
                  width:
                    screenWidth < 744
                      ? "212px"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-i"
                  style={{
                    gap:
                      screenWidth < 744
                        ? "2px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "5px"
                        : undefined,
                    height:
                      screenWidth < 744
                        ? "43px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "55px"
                        : undefined,
                    width:
                      screenWidth < 744
                        ? "210px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i"
                    style={{
                      fontFamily:
                        screenWidth < 744
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 744
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "15px"
                          : undefined,
                      fontStyle: screenWidth < 744 ? "var(--text-SP-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 744
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "400"
                          : undefined,
                      letterSpacing:
                        screenWidth < 744
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        screenWidth < 744
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily: screenWidth < 744 ? "var(--text-SP-font-family)" : undefined,
                        fontSize: screenWidth < 744 ? "var(--text-SP-font-size)" : undefined,
                        fontStyle: screenWidth < 744 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight: screenWidth < 744 ? "var(--text-SP-font-weight)" : undefined,
                        letterSpacing:
                          screenWidth < 744
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                            ? "0.11px"
                            : undefined,
                        lineHeight: screenWidth < 744 ? "var(--text-SP-line-height)" : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-2"
                    style={{
                      fontFamily:
                        screenWidth < 744
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 744
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "15px"
                          : undefined,
                      fontStyle: screenWidth < 744 ? "var(--text-SP-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 744
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "400"
                          : undefined,
                      letterSpacing:
                        screenWidth < 744
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        screenWidth < 744
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily: screenWidth < 744 ? "var(--text-SP-font-family)" : undefined,
                        fontSize: screenWidth < 744 ? "var(--text-SP-font-size)" : undefined,
                        fontStyle: screenWidth < 744 ? "var(--text-SP-font-style)" : undefined,
                        fontWeight: screenWidth < 744 ? "var(--text-SP-font-weight)" : undefined,
                        letterSpacing:
                          screenWidth < 744
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                            ? "0.11px"
                            : undefined,
                        lineHeight: screenWidth < 744 ? "var(--text-SP-line-height)" : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="footer"
          style={{
            padding:
              screenWidth < 744
                ? "50px 20px"
                : screenWidth >= 744 && screenWidth < 1200
                ? "70px 20px 50px"
                : screenWidth >= 1200
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-52"
            style={{
              alignSelf: (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744 ? "stretch" : undefined,
              flexDirection: screenWidth < 744 ? "column" : undefined,
              flexWrap: screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "wrap" : undefined,
              gap:
                screenWidth < 744
                  ? "54px"
                  : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                  ? "50px 150px"
                  : undefined,
              justifyContent:
                screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200) ? "space-between" : undefined,
              marginLeft: screenWidth >= 1200 ? "-40.00px" : undefined,
              marginRight: screenWidth >= 1200 ? "-40.00px" : undefined,
              padding: screenWidth >= 1200 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-53"
              style={{
                alignSelf: screenWidth < 744 ? "stretch" : undefined,
                display:
                  screenWidth >= 1200 || screenWidth < 744
                    ? "flex"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "inline-flex"
                    : undefined,
                flex:
                  (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                    ? "0 0 auto"
                    : screenWidth >= 1200
                    ? "1"
                    : undefined,
                flexGrow: screenWidth >= 1200 ? "1" : undefined,
                gap:
                  screenWidth < 744
                    ? "24px"
                    : screenWidth >= 744 && screenWidth < 1200
                    ? "30px"
                    : screenWidth >= 1200
                    ? "44px"
                    : undefined,
                width: screenWidth < 744 ? "100%" : undefined,
              }}
            >
              {screenWidth < 744 && <LogoWhite22 className="logo-white-22" />}

              {screenWidth >= 744 && screenWidth < 1200 && (
                <LogoWhite logoWhite="/img/logo-white-21.svg" logoWhiteClassName="logo-white-instance" />
              )}

              {((screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744) && (
                <div
                  className="flexcontainer-2"
                  style={{
                    gap: screenWidth < 744 ? "3px" : screenWidth >= 744 && screenWidth < 1200 ? "5px" : undefined,
                  }}
                >
                  <p
                    className="text-i-3"
                    style={{
                      fontFamily:
                        screenWidth < 744
                          ? "'Noto Sans JP', Helvetica"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth < 744
                          ? "13px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 744
                          ? "400"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth < 744
                          ? "0.26px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth < 744
                          ? "18px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-family)" : undefined,
                        fontSize: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-size)" : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-style)" : undefined,
                        fontWeight: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-weight)" : undefined,
                        letterSpacing:
                          screenWidth < 744
                            ? "0.03px"
                            : screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-line-height)" : undefined,
                      }}
                    >
                      千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                    </span>
                  </p>
                  <p
                    className="text-i-4"
                    style={{
                      fontFamily:
                        screenWidth < 744
                          ? "'Noto Sans JP', Helvetica"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        screenWidth < 744
                          ? "13px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 744
                          ? "400"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        screenWidth < 744
                          ? "0.26px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        screenWidth < 744
                          ? "18px"
                          : screenWidth >= 744 && screenWidth < 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-family)" : undefined,
                        fontSize: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-size)" : undefined,
                        fontStyle: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-style)" : undefined,
                        fontWeight: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-font-weight)" : undefined,
                        letterSpacing:
                          screenWidth < 744
                            ? "0.03px"
                            : screenWidth >= 744 && screenWidth < 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight: screenWidth >= 744 && screenWidth < 1200 ? "var(--text-line-height)" : undefined,
                      }}
                    >
                      幕張ベイパーク　クロスレジデンスＳ２－１
                    </span>
                  </p>
                </div>
              )}

              {screenWidth >= 1200 && (
                <>
                  <LogoWhite30 className="logo-white-30" />
                  <div className="flexcontainer-3">
                    <p className="text-i-5">
                      <span className="text-wrapper-33">
                        千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                      </span>
                    </p>
                    <p className="text-i-5">
                      <span className="text-wrapper-33">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="frame-54"
              style={{
                alignSelf: screenWidth < 744 ? "stretch" : undefined,
                display:
                  screenWidth < 744
                    ? "flex"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "inline-flex"
                    : undefined,
                gap:
                  screenWidth < 744
                    ? "40px"
                    : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                    ? "60px"
                    : undefined,
                width: screenWidth < 744 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-55"
                style={{
                  gap:
                    (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                      ? "30px"
                      : screenWidth >= 1200
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-56"
                  style={{
                    gap:
                      screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-34"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-35"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-36"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-37"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-57"
                  style={{
                    gap:
                      screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-38"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    会社概要
                  </div>
                  <div
                    className="text-wrapper-39"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-40"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-58"
                  style={{
                    gap:
                      screenWidth < 744
                        ? "4px"
                        : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                        ? "6px"
                        : undefined,
                  }}
                >
                  <div
                    className="text-wrapper-41"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    制作について
                  </div>
                  <div
                    className="text-wrapper-42"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-43"
                    style={{
                      fontFamily:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-59"
                style={{
                  alignSelf: screenWidth < 744 ? "stretch" : undefined,
                  display:
                    screenWidth < 744
                      ? "flex"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "inline-flex"
                      : undefined,
                  flex:
                    screenWidth < 744
                      ? "1"
                      : screenWidth >= 1200 || (screenWidth >= 744 && screenWidth < 1200)
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: screenWidth < 744 ? "1" : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-44"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-45"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-46"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-47"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-48"
                  style={{
                    fontFamily:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 744 && screenWidth < 1200) || screenWidth < 744
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-49">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
