/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Arrow4 = ({ color = "#454545", className }) => {
  return (
    <svg
      className={`arrow-4 ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 7 9"
      width="7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M1.39156 7.75L5 4.625L1.39156 1.5" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

Arrow4.propTypes = {
  color: PropTypes.string,
};
