import React from "react";
import { Link } from "react-router-dom";
import { Frame36 } from "../../components/Frame36";
import { Grayline } from "../../components/Grayline";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Logo } from "../../components/Logo";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Polygon } from "../../components/Polygon";
import { Polygon27 } from "../../icons/Polygon27";
import "./style.css";

export const Recruit = () => {
  return (
    <div className="recruit">
      <div className="headrer">
        <div className="logo-3">
          <div className="group-44">
            <Logo className="logo-4" />
          </div>
          <img className="menu-13" alt="Menu" src="/img/menu-11.svg" />
        </div>
        <div className="component-wrapper">
          <div className="component">
            <div className="text-wrapper-408">採用情報</div>
            <div className="TEXTEXT">RECRUIT</div>
          </div>
        </div>
        <div className="frame-308">
          <div className="text-wrapper-409">TOP</div>
          <ImgWrapper className="component-10930-4" img="/img/8.svg" />
          <div className="text-wrapper-409">ブログ</div>
          <ImgWrapper className="component-10930-4" img="/img/9.svg" />
          <div className="text-wrapper-409">記事名</div>
        </div>
      </div>
      <div className="view-4">
        <div className="frame-309">
          <div className="frame-310">
            <div className="frame-311">
              <div className="frame-312">
                <div className="group-45">
                  <div className="text-wrapper-410">2024/00/00</div>
                </div>
                <div className="frame-313">
                  <div className="text-wrapper-411">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                  <div className="text-wrapper-412">♯タグ</div>
                </div>
              </div>
              <p className="div-23">
                <span className="text-wrapper-413">見出し</span>
                <span className="text-wrapper-414">
                  見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し
                </span>
              </p>
              <Grayline className="grayline-5" />
              <div className="frame-314">
                <div className="group-46">
                  <NoimageWrapper className="noimage-6" noimage="/img/noimage-31.svg" />
                </div>
                <div className="group-47">
                  <NoimageWrapper className="noimage-7" noimage="/img/noimage-32.svg" />
                </div>
              </div>
              <div className="text-wrapper-415">
                テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
              </div>
            </div>
            <div className="frame-315">
              <div className="group-48">
                <div className="text-wrapper-416">前の記事へ</div>
                <Polygon className="polygon-3" element="/img/polygon-2-8.svg" one="one" />
              </div>
              <Frame36 className="frame-316" divClassName="frame-317" one="one" />
              <div className="group-49">
                <div className="text-wrapper-417">次の記事へ</div>
                <Polygon className="polygon-4" element="/img/polygon-2-9.svg" one="one" />
              </div>
            </div>
          </div>
          <div className="view-5">
            <div className="frame-318">
              <div className="text-wrapper-418">カテゴリ</div>
            </div>
            <div className="frame-319">
              <div className="frame-320">
                <div className="text-wrapper-419">カテゴリ名</div>
                <Polygon27 className="polygon-2-10" />
              </div>
              <img className="line-17" alt="Line" src="/img/line-7.svg" />
              <div className="frame-320">
                <div className="text-wrapper-419">カテゴリ名</div>
                <Polygon27 className="polygon-2-10" />
              </div>
              <img className="line-17" alt="Line" src="/img/line-7.svg" />
              <div className="frame-320">
                <div className="text-wrapper-419">カテゴリ名</div>
                <Polygon27 className="polygon-2-10" />
              </div>
              <img className="line-17" alt="Line" src="/img/line-7.svg" />
              <div className="frame-320">
                <div className="text-wrapper-419">カテゴリ名</div>
                <Polygon27 className="polygon-2-10" />
              </div>
              <img className="line-17" alt="Line" src="/img/line-7.svg" />
              <div className="frame-320">
                <div className="text-wrapper-419">カテゴリ名</div>
                <Polygon27 className="polygon-2-10" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CONTACT-26">
        <div className="CONTACT-27">
          <div className="frame-321">
            <div className="group-50">
              <div className="overlap-group-14">
                <div className="text-wrapper-420">お問い合わせ</div>
                <div className="text-wrapper-421">CONTACT</div>
              </div>
            </div>
            <div className="group-51">
              <div className="flexcontainer-43">
                <p className="text-i-45">
                  <span className="text-wrapper-422">
                    制作のご依頼・ご相談・ご質問など
                    <br />
                  </span>
                </p>
                <p className="text-i-45">
                  <span className="text-wrapper-422">お気軽にお問い合わせください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-14">
        <div className="frame-322">
          <div className="frame-323">
            <LogoWhite logoWhite="/img/logo-white-10.svg" logoWhiteClassName="logo-white-8" />
            <div className="flexcontainer-44">
              <p className="text-i-46">
                <span className="text-wrapper-423">
                  千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                </span>
              </p>
              <p className="text-i-46">
                <span className="text-wrapper-423">幕張ベイパーク　クロスレジデンスＳ２－１</span>
              </p>
            </div>
          </div>
          <div className="frame-324">
            <div className="frame-325">
              <div className="frame-326">
                <div className="text-wrapper-424">理念</div>
                <div className="text-wrapper-425">- 企業理念</div>
                <div className="text-wrapper-425"> - 経営理念</div>
                <div className="text-wrapper-425"> - 社訓</div>
              </div>
              <div className="frame-326">
                <div className="text-wrapper-424">会社概要</div>
                <div className="text-wrapper-425">- 代表挨拶</div>
                <div className="text-wrapper-425">- 概要</div>
              </div>
              <div className="frame-326">
                <div className="text-wrapper-424">制作について</div>
                <div className="text-wrapper-425">- 業務内容</div>
                <div className="text-wrapper-425">- ご相談の流れ</div>
              </div>
            </div>
            <div className="frame-325">
              <div className="text-wrapper-424">ブログ</div>
              <div className="text-wrapper-425">採用情報</div>
              <Link className="text-wrapper-426" to="/contact-1200">
                よくある質問
              </Link>
              <div className="text-wrapper-425">情報セキュリティ基本方針</div>
              <Link className="text-wrapper-426" to="/privacypolicy-1200">
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </div>
        <div className="text-wrapper-427">©︎ Digital Puzzle Inc</div>
      </footer>
    </div>
  );
};
