/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Noimage = ({ className }) => {
  return (
    <svg
      className={`noimage ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 192 26"
      width="192"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.1499 19.78C24.3035 19.78 26.8599 17.2236 26.8599 14.07C26.8599 10.9165 24.3035 8.36002 21.1499 8.36002C17.9964 8.36002 15.4399 10.9165 15.4399 14.07C15.4399 17.2236 17.9964 19.78 21.1499 19.78Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M37.7298 2.36H11.5698V0.0400085H4.7998V2.36H4.5798C2.3498 2.36 0.549805 4.17001 0.549805 6.39001V21.93C0.549805 24.16 2.3598 25.96 4.5798 25.96H37.7398C39.9698 25.96 41.7698 24.15 41.7698 21.93V6.39001C41.7698 4.16001 39.9598 2.36 37.7398 2.36H37.7298ZM21.1498 21.33C17.1398 21.33 13.8898 18.08 13.8898 14.07C13.8898 10.06 17.1398 6.81001 21.1498 6.81001C25.1598 6.81001 28.4098 10.06 28.4098 14.07C28.4098 18.08 25.1598 21.33 21.1498 21.33ZM37.5098 7.78001H30.7398V5.84001H37.5098V7.78001Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M182.38 21.41V8.00003H191.42V10.34H185.22V13.54H190.96V15.88H185.22V19.08H191.45V21.42H182.38V21.41Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M170.24 12.34C170.15 12.02 170.02 11.74 169.85 11.5C169.68 11.25 169.48 11.04 169.24 10.87C169.01 10.7 168.74 10.56 168.43 10.47C168.13 10.38 167.79 10.33 167.43 10.33C166.74 10.33 166.14 10.5 165.62 10.84C165.1 11.18 164.7 11.68 164.41 12.33C164.12 12.98 163.98 13.77 163.98 14.7C163.98 15.63 164.12 16.43 164.41 17.08C164.7 17.73 165.1 18.23 165.61 18.58C166.13 18.92 166.74 19.09 167.45 19.09C168.09 19.09 168.64 18.98 169.09 18.75C169.55 18.52 169.9 18.19 170.14 17.77C170.38 17.35 170.51 16.86 170.51 16.28L171.09 16.37H167.63V14.24H173.24V15.93C173.24 17.11 172.99 18.12 172.49 18.97C171.99 19.81 171.31 20.46 170.43 20.92C169.56 21.37 168.56 21.6 167.43 21.6C166.17 21.6 165.07 21.32 164.12 20.77C163.17 20.21 162.43 19.42 161.9 18.39C161.37 17.36 161.11 16.14 161.11 14.72C161.11 13.63 161.27 12.66 161.58 11.81C161.9 10.95 162.34 10.23 162.92 9.64003C163.49 9.05003 164.16 8.59003 164.92 8.28003C165.68 7.97003 166.5 7.81003 167.39 7.81003C168.15 7.81003 168.86 7.92003 169.51 8.14003C170.17 8.36003 170.75 8.67002 171.25 9.07002C171.76 9.47002 172.18 9.95003 172.5 10.5C172.82 11.05 173.03 11.66 173.12 12.32H170.24V12.34Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M143.48 21.41H140.44L145.07 8.00003H148.72L153.34 21.41H150.3L146.94 11.06H146.84L143.47 21.41H143.48ZM143.29 16.14H150.47V18.35H143.29V16.14Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M117.29 8.00003H120.79L124.48 17.01H124.64L128.33 8.00003H131.83V21.41H129.08V12.68H128.97L125.5 21.34H123.63L120.16 12.64H120.05V21.4H117.3V7.99002L117.29 8.00003Z"
        fill="#ADADAE"
      />
      <path className="path" d="M107.95 8.00003V21.41H105.11V8.00003H107.95Z" fill="#ADADAE" />
      <path
        className="path"
        d="M90.4899 14.7101C90.4899 16.1701 90.2099 17.42 89.6599 18.44C89.1099 19.47 88.3599 20.2501 87.4099 20.7901C86.4599 21.3301 85.3999 21.6001 84.2099 21.6001C83.0199 21.6001 81.9499 21.3301 80.9999 20.7901C80.0499 20.2501 79.2999 19.47 78.7499 18.44C78.1999 17.41 77.9199 16.1701 77.9199 14.7101C77.9199 13.2501 78.1999 12.0001 78.7499 10.9801C79.2999 9.95006 80.0499 9.17006 80.9999 8.64006C81.9499 8.10006 83.0199 7.83005 84.2099 7.83005C85.3999 7.83005 86.4599 8.10006 87.4099 8.64006C88.3599 9.18006 89.1099 9.96006 89.6599 10.9801C90.2099 12.0101 90.4899 13.2501 90.4899 14.7101ZM87.6099 14.7101C87.6099 13.7601 87.4699 12.9601 87.1799 12.3101C86.8999 11.6601 86.5099 11.17 85.9899 10.83C85.4799 10.49 84.8799 10.33 84.1999 10.33C83.5199 10.33 82.9199 10.5 82.4099 10.83C81.8999 11.17 81.4999 11.6601 81.2199 12.3101C80.9399 12.9601 80.7999 13.7601 80.7999 14.7101C80.7999 15.6601 80.9399 16.46 81.2199 17.11C81.4999 17.76 81.8999 18.2501 82.4099 18.5901C82.9199 18.9301 83.5199 19.0901 84.1999 19.0901C84.8799 19.0901 85.4799 18.9201 85.9899 18.5901C86.4999 18.2501 86.8999 17.76 87.1799 17.11C87.4599 16.46 87.6099 15.6601 87.6099 14.7101Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M68.8096 8.00003V21.41H66.3596L60.5196 12.97H60.4196V21.41H57.5796V8.00003H60.0696L65.8596 16.44H65.9796V8.00003H68.7996H68.8096Z"
        fill="#ADADAE"
      />
    </svg>
  );
};
