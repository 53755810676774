/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Arrow38 = ({ color = "#6079C1", className }) => {
  return (
    <svg
      className={`arrow-38 ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 9 12"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M1.2265 11.0792L7 6.07922L1.2265 1.07922" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

Arrow38.propTypes = {
  color: PropTypes.string,
};
