/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const LineWrapper = ({ className, lineClassName, line = "/img/line-2.svg" }) => {
  return (
    <div className={`line-wrapper ${className}`}>
      <img className={`line-4 ${lineClassName}`} alt="Line" src={line} />
    </div>
  );
};

LineWrapper.propTypes = {
  line: PropTypes.string,
};
