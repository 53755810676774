import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./screens/Home";
import { Contact } from "./screens/Contact";
import { BlogScreen } from "./screens/BlogScreen";
import { MenuopenSp } from "./screens/MenuopenSp";
import { MenuopenPc } from "./screens/MenuopenPc";
import { ContactScreen } from "./screens/ContactScreen";
import { Aboutcontents } from "./screens/Aboutcontents";
import { Recruits } from "./screens/Recruits";
import { MenuopenTab } from "./screens/MenuopenTab";
import { Philosophy } from "./screens/Philosophy";
import { Profile } from "./screens/Profile";
import { Privacypolicy } from "./screens/Privacypolicy";
import { Blogs } from "./screens/Blogs";
import { InformationSecurity } from "./screens/InformationSecurity";
import { Recruit } from "./screens/Recruit";
import { RecruitScreen } from "./screens/RecruitScreen";
import { HomeScreen } from "./screens/HomeScreen";
import { RecruitWrapper } from "./screens/RecruitWrapper";
import { RecruitsScreen } from "./screens/RecruitsScreen";
import { ProfileScreen } from "./screens/ProfileScreen";
import { PhilosophyScreen } from "./screens/PhilosophyScreen";
import { BlogsScreen } from "./screens/BlogsScreen";
import { InformationSecurityScreen } from "./screens/InformationSecurityScreen";
import { PrivacypolicyScreen } from "./screens/PrivacypolicyScreen";
import { Screen } from "./screens/Screen";
import { ScreenScreen } from "./screens/ScreenScreen";
import { ScreenWrapper } from "./screens/ScreenWrapper";
import { HomeWrapper } from "./screens/HomeWrapper";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Home />,
  },
  {
    path: "/home-320",
    element: <Home />,
  },
  {
    path: "/contact-1200",
    element: <Contact />,
  },
  {
    path: "/blog-1200",
    element: <BlogScreen />,
  },
  {
    path: "/menuopen-sp",
    element: <MenuopenSp />,
  },
  {
    path: "/menuopen-pc",
    element: <MenuopenPc />,
  },
  {
    path: "/contact-600",
    element: <ContactScreen />,
  },
  {
    path: "/aboutcontents-744",
    element: <Aboutcontents />,
  },
  {
    path: "/recruits-320",
    element: <Recruits />,
  },
  {
    path: "/menuopen-tab",
    element: <MenuopenTab />,
  },
  {
    path: "/philosophy-320",
    element: <Philosophy />,
  },
  {
    path: "/profile-600",
    element: <Profile />,
  },
  {
    path: "/privacypolicy-1200",
    element: <Privacypolicy />,
  },
  {
    path: "/blogs-320",
    element: <Blogs />,
  },
  {
    path: "/information-security-basic-policy-320",
    element: <InformationSecurity />,
  },
  {
    path: "/recruit-1",
    element: <Recruit />,
  },
  {
    path: "/recruit-2",
    element: <RecruitScreen />,
  },
  {
    path: "/home-744",
    element: <HomeScreen />,
  },
  {
    path: "/recruit-3",
    element: <RecruitWrapper />,
  },
  {
    path: "/recruits-744",
    element: <RecruitsScreen />,
  },
  {
    path: "/profile-744",
    element: <ProfileScreen />,
  },
  {
    path: "/philosophy-744",
    element: <PhilosophyScreen />,
  },
  {
    path: "/blogs-744",
    element: <BlogsScreen />,
  },
  {
    path: "/information-security-basic-policy-744",
    element: <InformationSecurityScreen />,
  },
  {
    path: "/privacypolicy-320",
    element: <PrivacypolicyScreen />,
  },
  {
    path: "/u29702u24565-1",
    element: <Screen />,
  },
  {
    path: "/u29702u24565-2",
    element: <ScreenScreen />,
  },
  {
    path: "/u29702u24565-3",
    element: <ScreenWrapper />,
  },
  {
    path: "/home",
    element: <HomeWrapper />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
