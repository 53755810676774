import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { Noimage41 } from "../../icons/Noimage41";
import { Noimage54 } from "../../icons/Noimage54";
import { Polygon27 } from "../../icons/Polygon27";
import { Polygon35 } from "../../icons/Polygon35";
import "./style.css";

export const BlogsScreen = () => {
  return (
    <div className="blogs-screen">
      <ColorWhite className="header-46" logoLogoClassName="header-47" />
      <div className="blogs-2">
        <div className="midashi-14">
          <ElementWrapper
            className="heading-63"
            headingDivClassName="heading-64"
            headingDivClassNameOverride="heading-65"
            headingText="BLOG"
            headingText1="ブログ"
            one="one"
          />
          <div className="breadcrumbs-15">
            <div className="frame-424">
              <Link className="text-wrapper-537" to="/home-744">
                TOP
              </Link>
              <ImgWrapper className="component-10930-10" img="/img/30.svg" />
              <div className="text-wrapper-538">ブログ</div>
            </div>
          </div>
        </div>
        <div className="blogs-3">
          <div className="frame-425">
            <div className="frame-426">
              <div className="group-76">
                <div className="text-wrapper-539">カテゴリ</div>
                <img className="line-23" alt="Line" src="/img/line-5.svg" />
              </div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
              <div className="text-wrapper-540">カテゴリ</div>
            </div>
            <div className="frame-427">
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage41 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage41 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage41 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage41 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage54 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
              <div className="frame-428">
                <div className="frame-429">
                  <div className="frame-430">
                    <Noimage54 className="noimage-14" />
                  </div>
                  <div className="text-wrapper-541">2024/00/00</div>
                  <div className="text-wrapper-542">
                    見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                  </div>
                  <img className="vector-5" alt="Vector" src="/img/vector-40.svg" />
                  <div className="text-wrapper-543">
                    #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-431">
            <div className="text-wrapper-544">1</div>
            <div className="text-wrapper-545">2</div>
            <div className="text-wrapper-546">3</div>
            <div className="text-wrapper-547">4</div>
            <div className="text-wrapper-548">5</div>
            <div className="text-wrapper-549">6</div>
            <Polygon27 className="polygon-2-8-instance" />
            <Polygon35 className="polygon-3-5-instance" />
          </div>
        </div>
        <div className="CONTACT-36">
          <div className="CONTACT-37">
            <div className="frame-432">
              <div className="group-77">
                <div className="overlap-group-19">
                  <div className="text-wrapper-550">お問い合わせ</div>
                  <div className="text-wrapper-551">CONTACT</div>
                </div>
              </div>
              <div className="group-78">
                <div className="flexcontainer-61">
                  <p className="text-i-58">
                    <span className="text-wrapper-552">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-58">
                    <span className="text-wrapper-552">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-21">
          <div className="frame-433">
            <div className="frame-434">
              <LogoWhite logoWhite="/img/logo-white-27.svg" logoWhiteClassName="logo-white-29" />
              <div className="flexcontainer-62">
                <p className="text-i-59">
                  <span className="text-wrapper-553">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-59">
                  <span className="text-wrapper-553">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-435">
              <div className="frame-434">
                <div className="frame-436">
                  <div className="text-wrapper-554">理念</div>
                  <div className="text-wrapper-555">- 企業理念</div>
                  <div className="text-wrapper-555"> - 経営理念</div>
                  <div className="text-wrapper-555"> - 社訓</div>
                </div>
                <div className="frame-436">
                  <div className="text-wrapper-554">会社概要</div>
                  <div className="text-wrapper-555">- 代表挨拶</div>
                  <div className="text-wrapper-555">- 概要</div>
                </div>
                <div className="frame-436">
                  <div className="text-wrapper-554">制作について</div>
                  <div className="text-wrapper-555">- 業務内容</div>
                  <div className="text-wrapper-555">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-434">
                <div className="text-wrapper-554">ブログ</div>
                <div className="text-wrapper-555">採用情報</div>
                <Link className="text-wrapper-556" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-555">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-556" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-557">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
