import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Frame } from "../../components/Frame";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { Noimage41 } from "../../icons/Noimage41";
import { Noimage54 } from "../../icons/Noimage54";
import { Polygon27 } from "../../icons/Polygon27";
import { Polygon35 } from "../../icons/Polygon35";
import "./style.css";

export const RecruitsScreen = () => {
  return (
    <div className="recruits-screen">
      <ColorWhite className="header-40" logoLogoClassName="header-41" />
      <div className="recruits-2">
        <div className="midashi-11">
          <ElementWrapper
            className="heading-44"
            headingDivClassName="heading-45"
            headingDivClassNameOverride="heading-46"
            headingText="RECRUIT"
            headingText1="採用情報"
            one="one"
          />
          <div className="breadcrumbs-12">
            <div className="frame-389">
              <Link className="text-wrapper-489" to="/home-744">
                TOP
              </Link>
              <ImgWrapper className="component-10930-7" img="/img/18.svg" />
              <div className="text-wrapper-490">採用情報</div>
              <ImgWrapper className="component-10930-7" img="/img/19.png" />
            </div>
          </div>
        </div>
        <div className="recruit-list-2">
          <div className="frame-390">
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage41 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage41 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage41 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage41 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage54 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
            <Link className="frame-391" to="/recruit-2">
              <div className="frame-392">
                <Noimage54 className="noimage-12" />
              </div>
              <div className="frame-393">
                <Frame className="frame-394" divClassName="frame-395" one="one" />
                <div className="frame-396">
                  <div className="text-wrapper-491">2024/00/00</div>
                </div>
              </div>
              <img className="vector-4" alt="Vector" src="/img/vector-28.svg" />
              <div className="text-wrapper-492">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </Link>
          </div>
          <div className="frame-397">
            <div className="text-wrapper-493">1</div>
            <div className="text-wrapper-494">2</div>
            <div className="text-wrapper-495">3</div>
            <div className="text-wrapper-496">4</div>
            <div className="text-wrapper-497">5</div>
            <div className="text-wrapper-498">6</div>
            <Polygon27 className="polygon-2-7-instance" />
            <Polygon35 className="polygon-3-2" />
          </div>
        </div>
        <div className="frame-398">
          <div className="group-69">
            <div className="frame-399">
              <div className="group-70">
                <div className="text-wrapper-499">お問い合わせ</div>
                <div className="text-wrapper-500">CONTACT</div>
              </div>
              <div className="group-71">
                <div className="flexcontainer-52">
                  <p className="text-34">
                    <span className="text-wrapper-501">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-34">
                    <span className="text-wrapper-501">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-18">
          <div className="frame-400">
            <div className="frame-401">
              <LogoWhite logoWhite="/img/logo-white-19.svg" logoWhiteClassName="logo-white-16" />
              <div className="flexcontainer-53">
                <p className="text-i-53">
                  <span className="text-wrapper-502">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-53">
                  <span className="text-wrapper-502">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-402">
              <div className="frame-403">
                <div className="frame-404">
                  <div className="text-wrapper-503">理念</div>
                  <div className="text-wrapper-504">- 企業理念</div>
                  <div className="text-wrapper-504"> - 経営理念</div>
                  <div className="text-wrapper-504"> - 社訓</div>
                </div>
                <div className="frame-404">
                  <div className="text-wrapper-503">会社概要</div>
                  <div className="text-wrapper-504">- 代表挨拶</div>
                  <div className="text-wrapper-504">- 概要</div>
                </div>
                <div className="frame-404">
                  <div className="text-wrapper-503">制作について</div>
                  <div className="text-wrapper-504">- 業務内容</div>
                  <div className="text-wrapper-504">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-403">
                <div className="text-wrapper-503">ブログ</div>
                <div className="text-wrapper-504">採用情報</div>
                <Link className="text-wrapper-505" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-504">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-505" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-506">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
