/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Freeicon } from "../Freeicon";
import "./style.css";

export const Flow = ({
  prop,
  className,
  frameClassName,
  frameClassNameOverride,
  divClassName,
  text = "1",
  overlapGroupClassName,
  rectangleClassName,
  imageClassName,
  image = "/img/image-1-10.png",
  line = "/img/line-19.svg",
  text1 = "お問い合わせ",
  text2 = "ホームページのお問い合せフォームからご連絡ください。折り返しメールにて面談日時を調整させていただきます。",
  divClassNameOverride,
  text3 = "返信まで5営業日以内",
  freeiconText = "無料",
  freeiconElementClassName,
}) => {
  return (
    <div className={`flow prop-2-${prop} ${className}`}>
      {prop === "one" && (
        <>
          <div className="frame-24">
            <div className="element">{text}</div>
          </div>
          <div className="group-wrapper">
            <div className="overlap-group-wrapper">
              <div className="overlap-group">
                <img className="image-2" alt="Image" />
                <div className="rectangle" />
              </div>
            </div>
          </div>
          <div className="frame-25">
            <div className="frame-26">
              <Freeicon className="freeicon-instance" one="one" text="無料" />
              <div className="text-wrapper-17">{text1}</div>
            </div>
            <div className="text-wrapper-18">{text2}</div>
          </div>
          <div className="frame-27">
            <div className="frame-28">
              <div className="text-wrapper-19">所要時間</div>
            </div>
            <div className="element-2">{text3}</div>
          </div>
        </>
      )}

      {prop === "SP" && (
        <div className={`frame-29 ${frameClassName}`}>
          <div className={`frame-30 ${frameClassNameOverride}`}>
            <div className="frame-31">
              <div className={`element-3 ${divClassName}`}>{text}</div>
            </div>
            <div className="frame-32">
              <div className="frame-33">
                <div className="group-3">
                  <div className={`overlap-group-2 ${overlapGroupClassName}`}>
                    <div className={`rectangle-2 ${rectangleClassName}`} />
                    <img className={`image-3 ${imageClassName}`} alt="Image" src={image} />
                  </div>
                </div>
              </div>
              <img className="line-3" alt="Line" src={line} />
            </div>
          </div>
          <div className="frame-34">
            <div className="frame-35">
              <div className="frame-26">
                <Freeicon
                  className={freeiconElementClassName}
                  divClassName="freeicon-2"
                  one="one"
                  text={freeiconText}
                />
                <div className="text-wrapper-20">{text1}</div>
              </div>
              <div className="text-wrapper-21">{text2}</div>
            </div>
            <div className="frame-37">
              <div className="frame-28">
                <div className="text-wrapper-22">所要時間</div>
              </div>
              <div className={`element-4 ${divClassNameOverride}`}>{text3}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Flow.propTypes = {
  prop: PropTypes.oneOf(["SP", "one"]),
  text: PropTypes.string,
  image: PropTypes.string,
  line: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  freeiconText: PropTypes.string,
};
