import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { Checklist } from "../../components/Checklist";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading } from "../../components/Heading";
import { InputField } from "../../components/InputField";
import { QA } from "../../components/QA";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { Menu3 } from "../../icons/Menu3";
import { Qaicon3 } from "../../icons/Qaicon3";
import { Qaicon7 } from "../../icons/Qaicon7";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const ContactScreen = () => {
  return (
    <div className="contact-screen">
      <SpWhite className="header-8" groupClassName="header-9" icon={<Menu3 className="menu-24" />} />
      <div className="contacts-2">
        <div className="midashi-2">
          <ElementWrapper
            className="heading-20"
            headingDivClassName="heading-21"
            headingDivClassNameOverride="heading-22"
            headingText="CONTACT"
            headingText1="お問い合わせ"
            one="two"
          />
          <div className="breadcrumbs-4">
            <div className="frame-157">
              <Link className="text-wrapper-150" to="/home-320">
                TOP
              </Link>
              <TwentyTwo className="instance-22" />
              <div className="text-wrapper-151">お問い合わせ</div>
            </div>
          </div>
        </div>
        <div className="contactform-2">
          <div className="flexcontainer-16">
            <p className="text-7">
              <span className="text-wrapper-152">
                ご相談・お見積り、サービス内容についてなど、
                <br />
              </span>
            </p>
            <p className="text-7">
              <span className="text-wrapper-152">お気軽にお問い合わせください。</span>
            </p>
          </div>
          <div className="frame-158">
            <div className="flexcontainer-17">
              <p className="text-8">
                <span className="text-wrapper-153">
                  お問い合わせ後、制作までの流れに <br />
                </span>
              </p>
              <p className="text-8">
                <span className="text-wrapper-153">ついてはこちらをご確認ください。</span>
              </p>
            </div>
            <Button
              color="white"
              device="SP"
              divClassName="button-12"
              frameClassName="button-11"
              text="制作までの流れ"
            />
          </div>
          <div className="frame-159">
            <div className="frame-160">
              <Checklist className="checklist-2" divClassName="checklist-3" text="お問い合わせ" />
              <Checklist className="checklist-4" divClassName="checklist-3" text="お見積もり" />
              <Checklist className="checklist-4" divClassName="checklist-3" text="その他" />
            </div>
            <InputField
              className="input-field-3"
              divClassName="input-field-6"
              frameClassName="input-field-5"
              prop="one"
              prop1="SP"
              requiredRequiredClassName="input-field-4"
              text="お名前"
              text1="お名前を入れてください"
            />
            <InputField
              className="input-field-3"
              divClassName="input-field-6"
              frameClassName="input-field-5"
              prop="one"
              prop1="SP"
              requiredRequiredClassName="input-field-4"
              text="会社名"
              text1="会社名を入れてください"
            />
            <InputField
              className="input-field-3"
              divClassName="input-field-6"
              frameClassName="input-field-5"
              prop="three"
              prop1="SP"
              text="部署名"
              text1="部署名を入れてください"
            />
            <InputField
              className="input-field-3"
              divClassName="input-field-6"
              frameClassName="input-field-5"
              prop="one"
              prop1="SP"
              requiredRequiredClassName="input-field-4"
              text="メールアドレス"
              text1="sample@dpi.com"
            />
            <InputField
              className="input-field-3"
              divClassName="input-field-6"
              frameClassName="input-field-5"
              prop="one"
              prop1="SP"
              requiredRequiredClassName="input-field-4"
              text="電話番号"
              text1="000-0000-0000"
            />
            <InputField
              className="input-field-7"
              divClassName="input-field-9"
              frameClassName="input-field-8"
              prop="one"
              prop1="SP"
              requiredRequiredClassName="input-field-4"
              text="お問い合わせ内容"
              text1="お問い合わせ内容を入れてください"
            />
            <Button className="button-13" color="purple" device="SP" divClassName="button-14" text="問い合わせる" />
          </div>
        </div>
        <div className="q-a-4">
          <Heading
            className="heading-23"
            divClassName="heading-24"
            divClassNameOverride="heading-25"
            one="SP"
            text="Q&amp;A"
            text1="よくある質問"
          />
          <div className="frame-161">
            <div className="frame-162">
              <QA
                className="q-a-5"
                device="SP"
                divClassName="q-a-6"
                frameClassName="q-a-6"
                frameClassNameOverride="q-a-7"
                icon={<Qaicon3 className="icon-instance-node" />}
                open="open"
                override={<Qaicon7 className="icon-instance-node" />}
                triangle12StyleOverrideClassName="q-a-8"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
            </div>
            <div className="frame-162">
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
              <QA
                className="q-a-5"
                device="SP"
                divClassNameOverride="q-a-6"
                frameClassName1="q-a-9"
                icon1={<Qaicon3 className="icon-instance-node" />}
                open="one"
              />
            </div>
          </div>
        </div>
        <div className="CONTACT-10">
          <div className="CONTACT-11">
            <div className="frame-163">
              <div className="group-21">
                <div className="text-wrapper-154">お問い合わせ</div>
                <div className="text-wrapper-155">CONTACT</div>
              </div>
              <div className="group-22">
                <div className="flexcontainer-18">
                  <p className="text-i-17">
                    <span className="text-wrapper-156">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-17">
                    <span className="text-wrapper-156">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-6">
          <div className="frame-164">
            <div className="frame-165">
              <LogoWhite22 className="logo-white-18" />
              <div className="flexcontainer-19">
                <p className="text-i-18">
                  <span className="text-wrapper-157">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-18">
                  <span className="text-wrapper-157">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-166">
              <div className="frame-167">
                <div className="frame-168">
                  <Link className="text-wrapper-158" to="/philosophy-320">
                    理念
                  </Link>
                  <div className="text-wrapper-159">- 企業理念</div>
                  <div className="text-wrapper-159"> - 経営理念</div>
                  <div className="text-wrapper-159"> - 社訓</div>
                </div>
                <div className="frame-168">
                  <div className="text-wrapper-160">会社概要</div>
                  <div className="text-wrapper-159">- 代表挨拶</div>
                  <div className="text-wrapper-159">- 概要</div>
                </div>
                <div className="frame-168">
                  <div className="text-wrapper-160">制作について</div>
                  <div className="text-wrapper-159">- 業務内容</div>
                  <div className="text-wrapper-159">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-169">
                <Link className="text-wrapper-158" to="/blogs-320">
                  ブログ
                </Link>
                <Link className="text-wrapper-161" to="/recruits-320">
                  採用情報
                </Link>
                <div className="text-wrapper-159">よくある質問</div>
                <Link className="text-wrapper-161" to="/information-security-basic-policy-320">
                  情報セキュリティ基本方針
                </Link>
                <Link className="text-wrapper-161" to="/privacypolicy-320">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-162">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
