/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Heading } from "../Heading";
import "./style.css";

export const ElementWrapper = ({
  one,
  className,
  headingDivClassName,
  headingText = "PHILOSOPHY",
  headingDivClassNameOverride,
  headingText1 = "理念",
}) => {
  return (
    <div className={`element-wrapper one-1-${one} ${className}`}>
      <Heading
        className="heading-instance"
        divClassName={headingDivClassNameOverride}
        divClassNameOverride={headingDivClassName}
        one={one === "two" ? "SP" : "one"}
        text={headingText}
        text1={headingText1}
      />
    </div>
  );
};

ElementWrapper.propTypes = {
  one: PropTypes.oneOf(["two", "one"]),
  headingText: PropTypes.string,
  headingText1: PropTypes.string,
};
