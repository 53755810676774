/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { OneA } from "../../icons/OneA";
import { Qaicon } from "../../icons/Qaicon";
import "./style.css";

export const QAicon = ({ one }) => {
  return (
    <>
      {one === "q" && <Qaicon className="instance-node" />}

      {one === "a" && <OneA className="instance-node" />}
    </>
  );
};

QAicon.propTypes = {
  one: PropTypes.oneOf(["q", "a"]),
};
