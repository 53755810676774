/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Menu } from "../../icons/Menu";
import { Logo } from "../Logo";
import "./style.css";

export const ColorTransparent = ({ className, groupClassName, menuStyleOverrideClassName }) => {
  return (
    <div className={`color-transparent ${className}`}>
      <div className={`group-2 ${groupClassName}`}>
        <Logo className="logo-2" />
      </div>
      <Menu className={menuStyleOverrideClassName} />
    </div>
  );
};
