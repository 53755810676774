/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { DivWrapper } from "../DivWrapper";
import "./style.css";

export const Required = ({ className, divWrapperDivClassName }) => {
  return (
    <div className={`required ${className}`}>
      <DivWrapper className="component-10923" divClassName={divWrapperDivClassName} />
    </div>
  );
};
