/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Noimage54 = ({ className }) => {
  return (
    <svg
      className={`noimage-54 ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 192 26"
      width="192"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21.1495 19.78C24.303 19.78 26.8595 17.2235 26.8595 14.07C26.8595 10.9164 24.303 8.35999 21.1495 8.35999C17.9959 8.35999 15.4395 10.9164 15.4395 14.07C15.4395 17.2235 17.9959 19.78 21.1495 19.78Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M37.7298 2.35997H11.5698V0.039978H4.7998V2.35997H4.5798C2.3498 2.35997 0.549805 4.16998 0.549805 6.38998V21.93C0.549805 24.16 2.3598 25.96 4.5798 25.96H37.7398C39.9698 25.96 41.7698 24.15 41.7698 21.93V6.38998C41.7698 4.15998 39.9598 2.35997 37.7398 2.35997H37.7298ZM21.1498 21.33C17.1398 21.33 13.8898 18.08 13.8898 14.07C13.8898 10.06 17.1398 6.80998 21.1498 6.80998C25.1598 6.80998 28.4098 10.06 28.4098 14.07C28.4098 18.08 25.1598 21.33 21.1498 21.33ZM37.5098 7.77998H30.7398V5.83998H37.5098V7.77998Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M182.38 21.41V8H191.42V10.34H185.22V13.54H190.96V15.88H185.22V19.08H191.45V21.42H182.38V21.41Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M170.239 12.34C170.149 12.02 170.019 11.74 169.849 11.5C169.679 11.25 169.479 11.04 169.239 10.87C169.009 10.7 168.739 10.56 168.429 10.47C168.129 10.38 167.789 10.33 167.429 10.33C166.739 10.33 166.139 10.5 165.619 10.84C165.099 11.18 164.699 11.68 164.409 12.33C164.119 12.98 163.979 13.77 163.979 14.7C163.979 15.63 164.119 16.43 164.409 17.08C164.699 17.73 165.099 18.23 165.609 18.58C166.129 18.92 166.739 19.09 167.449 19.09C168.089 19.09 168.639 18.98 169.089 18.75C169.549 18.52 169.899 18.19 170.139 17.77C170.379 17.35 170.509 16.86 170.509 16.28L171.089 16.37H167.629V14.24H173.239V15.93C173.239 17.11 172.989 18.12 172.489 18.97C171.989 19.81 171.309 20.46 170.429 20.92C169.559 21.37 168.559 21.6 167.429 21.6C166.169 21.6 165.069 21.32 164.119 20.77C163.169 20.21 162.429 19.42 161.899 18.39C161.369 17.36 161.109 16.14 161.109 14.72C161.109 13.63 161.269 12.66 161.579 11.81C161.899 10.95 162.339 10.23 162.919 9.64C163.489 9.05 164.159 8.59 164.919 8.28C165.679 7.97 166.499 7.81 167.389 7.81C168.149 7.81 168.859 7.92 169.509 8.14C170.169 8.36 170.749 8.66999 171.249 9.06999C171.759 9.46999 172.179 9.95 172.499 10.5C172.819 11.05 173.029 11.66 173.119 12.32H170.239V12.34Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M143.479 21.41H140.439L145.069 8H148.719L153.339 21.41H150.299L146.939 11.06H146.839L143.469 21.41H143.479ZM143.289 16.14H150.469V18.35H143.289V16.14Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M117.29 8H120.79L124.48 17.01H124.64L128.33 8H131.83V21.41H129.08V12.68H128.97L125.5 21.34H123.63L120.16 12.64H120.05V21.4H117.3V7.98999L117.29 8Z"
        fill="#ADADAE"
      />
      <path className="path" d="M107.949 8V21.41H105.109V8H107.949Z" fill="#ADADAE" />
      <path
        className="path"
        d="M90.4899 14.71C90.4899 16.17 90.2099 17.42 89.6599 18.44C89.1099 19.47 88.3599 20.25 87.4099 20.79C86.4599 21.33 85.3999 21.6 84.2099 21.6C83.0199 21.6 81.9499 21.33 80.9999 20.79C80.0499 20.25 79.2999 19.47 78.7499 18.44C78.1999 17.41 77.9199 16.17 77.9199 14.71C77.9199 13.25 78.1999 12 78.7499 10.98C79.2999 9.95003 80.0499 9.17003 80.9999 8.64003C81.9499 8.10003 83.0199 7.83002 84.2099 7.83002C85.3999 7.83002 86.4599 8.10003 87.4099 8.64003C88.3599 9.18003 89.1099 9.96003 89.6599 10.98C90.2099 12.01 90.4899 13.25 90.4899 14.71ZM87.6099 14.71C87.6099 13.76 87.4699 12.96 87.1799 12.31C86.8999 11.66 86.5099 11.17 85.9899 10.83C85.4799 10.49 84.8799 10.33 84.1999 10.33C83.5199 10.33 82.9199 10.5 82.4099 10.83C81.8999 11.17 81.4999 11.66 81.2199 12.31C80.9399 12.96 80.7999 13.76 80.7999 14.71C80.7999 15.66 80.9399 16.46 81.2199 17.11C81.4999 17.76 81.8999 18.25 82.4099 18.59C82.9199 18.93 83.5199 19.09 84.1999 19.09C84.8799 19.09 85.4799 18.92 85.9899 18.59C86.4999 18.25 86.8999 17.76 87.1799 17.11C87.4599 16.46 87.6099 15.66 87.6099 14.71Z"
        fill="#ADADAE"
      />
      <path
        className="path"
        d="M68.8101 8V21.41H66.3601L60.5201 12.97H60.4201V21.41H57.5801V8H60.0701L65.8601 16.44H65.9801V8H68.8001H68.8101Z"
        fill="#ADADAE"
      />
    </svg>
  );
};
