import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Frame36 } from "../../components/Frame36";
import { Grayline } from "../../components/Grayline";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Polygon } from "../../components/Polygon";
import { SpWhite } from "../../components/SpWhite";
import { Arrow31 } from "../../icons/Arrow31";
import { Arrow38 } from "../../icons/Arrow38";
import { Eight } from "../../icons/Eight";
import { LogoWhite12 } from "../../icons/LogoWhite12";
import { LogoWhite7 } from "../../icons/LogoWhite7";
import { Menu3 } from "../../icons/Menu3";
import { Noimage23 } from "../../icons/Noimage23";
import { Noimage24 } from "../../icons/Noimage24";
import { Polygon27 } from "../../icons/Polygon27";
import { Three } from "../../icons/Three";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const BlogScreen = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="blog-screen"
      style={{
        backgroundColor: screenWidth >= 1280 || screenWidth < 744 ? "var(--backwhite)" : undefined,
        minWidth:
          screenWidth < 744
            ? "320px"
            : screenWidth >= 744 && screenWidth < 1280
            ? "744px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      {screenWidth < 744 && (
        <>
          <SpWhite className="header-5" groupClassName="header-6" icon={<Menu3 className="menu-2" />} />
          <div className="blog">
            <div className="div-11">
              <ElementWrapper
                className="heading-16"
                headingDivClassName="heading-17"
                headingDivClassNameOverride="heading-18"
                headingText="BLOG"
                headingText1="ブログ"
                one="two"
              />
              <div className="breadcrumbs-2">
                <div className="frame-94">
                  <Link className="text-wrapper-81" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-4" />
                  <div className="text-wrapper-82">ブログ</div>
                  <Eight className="instance-node-4" />
                  <div className="text-wrapper-82">記事タイトル</div>
                </div>
              </div>
            </div>
            <div className="view">
              <div className="frame-95">
                <div className="frame-96">
                  <div className="frame-97">
                    <div className="group-11">
                      <div className="text-wrapper-83">2024/00/00</div>
                    </div>
                    <div className="frame-98">
                      <div className="text-wrapper-84">♯タグ</div>
                      <div className="text-wrapper-85">♯タグ</div>
                      <div className="text-wrapper-85">♯タグ</div>
                      <div className="text-wrapper-85">♯タグ</div>
                      <div className="text-wrapper-85">♯タグ</div>
                      <div className="text-wrapper-86">♯タグ</div>
                      <div className="text-wrapper-86">♯タグ</div>
                      <div className="text-wrapper-86">♯タグ</div>
                    </div>
                  </div>
                  <div className="text-wrapper-87">
                    見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し
                  </div>
                  <div className="frame-99">
                    <Grayline className="grayline-instance" />
                    <div className="frame-99">
                      <div className="noimage-23-wrapper">
                        <Noimage23 className="noimage-3" />
                      </div>
                      <div className="noimage-24-wrapper">
                        <Noimage24 className="noimage-3" />
                      </div>
                    </div>
                    <div className="text-wrapper-88">
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
                <div className="frame-100">
                  <div className="group-12">
                    <div className="overlap-group-5">
                      <div className="text-wrapper-89">前の記事へ</div>
                      <Arrow31 className="arrow-31" />
                    </div>
                  </div>
                  <Frame36 className="frame-instance" divClassName="frame-36-instance" one="one" />
                  <div className="group-13">
                    <div className="text-wrapper-90">次の記事へ</div>
                    <Arrow38 className="arrow-38" color="#454545" />
                  </div>
                </div>
              </div>
              <div className="div-11">
                <div className="frame-101">
                  <div className="text-wrapper-91">カテゴリ</div>
                </div>
                <div className="frame-102">
                  <div className="frame-103">
                    <div className="text-wrapper-92">カテゴリ名</div>
                    <Arrow38 className="arrow-6" color="#454545" />
                  </div>
                  <img className="line-6" alt="Line" src="/img/line-18.svg" />
                  <div className="frame-104">
                    <div className="text-wrapper-92">カテゴリ名</div>
                    <Arrow38 className="arrow-6" color="#454545" />
                  </div>
                  <img className="line-6" alt="Line" src="/img/line-18.svg" />
                  <div className="frame-105">
                    <div className="text-wrapper-92">カテゴリ名</div>
                    <Arrow38 className="arrow-6" color="#454545" />
                  </div>
                  <img className="line-6" alt="Line" src="/img/line-18.svg" />
                  <div className="frame-106">
                    <div className="text-wrapper-92">カテゴリ名</div>
                    <Arrow38 className="arrow-6" color="#454545" />
                  </div>
                  <img className="line-6" alt="Line" src="/img/line-18.svg" />
                  <div className="frame-107">
                    <div className="text-wrapper-92">カテゴリ名</div>
                    <Arrow38 className="arrow-6" color="#454545" />
                  </div>
                </div>
              </div>
            </div>
            <div className="CONTACT-6">
              <div className="CONTACT-7">
                <div className="frame-108">
                  <div className="group-14">
                    <div className="text-wrapper-93">お問い合わせ</div>
                    <div className="text-wrapper-94">CONTACT</div>
                  </div>
                  <div className="group-15">
                    <div className="flexcontainer-12">
                      <p className="text-i-13">
                        <span className="text-wrapper-95">
                          制作のご依頼・ご相談・ご質問など
                          <br />
                        </span>
                      </p>
                      <p className="text-i-13">
                        <span className="text-wrapper-95">お気軽にお問い合わせください。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer-4">
              <div className="frame-109">
                <div className="frame-110">
                  <LogoWhite12 className="logo-white-12" />
                  <div className="flexcontainer-13">
                    <p className="text-i-14">
                      <span className="text-wrapper-96">
                        千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                      </span>
                    </p>
                    <p className="text-i-14">
                      <span className="text-wrapper-96">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                    </p>
                  </div>
                </div>
                <div className="frame-111">
                  <div className="frame-112">
                    <div className="frame-113">
                      <Link className="text-wrapper-97" to="/philosophy-320">
                        理念
                      </Link>
                      <div className="text-wrapper-98">- 企業理念</div>
                      <div className="text-wrapper-98"> - 経営理念</div>
                      <div className="text-wrapper-98"> - 社訓</div>
                    </div>
                    <div className="frame-113">
                      <div className="text-wrapper-99">会社概要</div>
                      <div className="text-wrapper-98">- 代表挨拶</div>
                      <div className="text-wrapper-98">- 概要</div>
                    </div>
                    <div className="frame-113">
                      <div className="text-wrapper-99">制作について</div>
                      <div className="text-wrapper-98">- 業務内容</div>
                      <div className="text-wrapper-98">- ご相談の流れ</div>
                    </div>
                  </div>
                  <div className="frame-114">
                    <Link className="text-wrapper-97" to="/blogs-320">
                      ブログ
                    </Link>
                    <Link className="text-wrapper-100" to="/recruits-320">
                      採用情報
                    </Link>
                    <div className="text-wrapper-98">よくある質問</div>
                    <Link className="text-wrapper-100" to="/information-security-basic-policy-320">
                      情報セキュリティ基本方針
                    </Link>
                    <Link className="text-wrapper-100" to="/privacypolicy-320">
                      プライバシーポリシー
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-101">©︎ Digital Puzzle Inc</div>
            </footer>
          </div>
        </>
      )}

      {(screenWidth >= 1280 || (screenWidth >= 744 && screenWidth < 1280)) && (
        <>
          <ColorWhite
            className="header-7"
            groupClassName={`${screenWidth >= 1280 && "class-45"}`}
            menuStyleOverrideClassName={`${screenWidth >= 1280 && "class-44"}`}
          />
          <div className="blog-2">
            <div className="div-11">
              <ElementWrapper
                className={`${screenWidth >= 744 && screenWidth < 1280 && "class-46"} ${
                  screenWidth >= 1280 && "class-47"
                }`}
                headingDivClassName="heading-19"
                headingDivClassNameOverride="heading-17"
                headingText="BLOG"
                headingText1="ブログ"
                one="one"
              />
              <div
                className="breadcrumbs-3"
                style={{
                  padding:
                    screenWidth >= 744 && screenWidth < 1280
                      ? "40px 15px"
                      : screenWidth >= 1280
                      ? "20px 15px 40px"
                      : undefined,
                }}
              >
                <div
                  className="frame-115"
                  style={{
                    padding: screenWidth >= 1280 ? "0px 50px" : undefined,
                  }}
                >
                  {screenWidth >= 744 && screenWidth < 1280 && (
                    <>
                      <Link className="text-wrapper-102" to="/home-744">
                        TOP
                      </Link>
                      <ImgWrapper className="component-10930-instance" img="/img/14.svg" />
                    </>
                  )}

                  <div className="div-12">
                    {screenWidth >= 1280 && <>TOP</>}

                    {screenWidth >= 744 && screenWidth < 1280 && <>ブログ</>}
                  </div>
                  {screenWidth >= 744 && screenWidth < 1280 && (
                    <ImgWrapper className="component-10930-instance" img="/img/15.svg" />
                  )}

                  {screenWidth >= 1280 && <Twelve className="instance-node-4" />}

                  <div className="div-12">
                    {screenWidth >= 1280 && <>ブログ</>}

                    {screenWidth >= 744 && screenWidth < 1280 && <>記事タイトル</>}
                  </div>
                  {screenWidth >= 1280 && (
                    <>
                      <Three className="instance-node-4" />
                      <div className="div-12">記事タイトル</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="view-2">
              <div
                className="frame-116"
                style={{
                  alignItems:
                    screenWidth >= 744 && screenWidth < 1280
                      ? "center"
                      : screenWidth >= 1280
                      ? "flex-start"
                      : undefined,
                  alignSelf: screenWidth >= 744 && screenWidth < 1280 ? "stretch" : undefined,
                  flexDirection: screenWidth >= 744 && screenWidth < 1280 ? "column" : undefined,
                  gap: screenWidth >= 744 && screenWidth < 1280 ? "70px" : screenWidth >= 1280 ? "60px" : undefined,
                  maxWidth:
                    screenWidth >= 744 && screenWidth < 1280 ? "1200px" : screenWidth >= 1280 ? "1100px" : undefined,
                  width: screenWidth >= 744 && screenWidth < 1280 ? "100%" : screenWidth >= 1280 ? "1100px" : undefined,
                }}
              >
                <div
                  className="frame-117"
                  style={{
                    alignSelf: screenWidth >= 744 && screenWidth < 1280 ? "stretch" : undefined,
                    flex: screenWidth >= 1280 ? "1" : undefined,
                    flexGrow: screenWidth >= 1280 ? "1" : undefined,
                    gap: screenWidth >= 744 && screenWidth < 1280 ? "20px" : screenWidth >= 1280 ? "18px" : undefined,
                    height: screenWidth >= 744 && screenWidth < 1280 ? "815.92px" : undefined,
                    width: screenWidth >= 744 && screenWidth < 1280 ? "100%" : undefined,
                  }}
                >
                  <div
                    className="frame-118"
                    style={{
                      alignSelf: screenWidth >= 744 && screenWidth < 1280 ? "stretch" : undefined,
                      gap: screenWidth >= 744 && screenWidth < 1280 ? "32px" : screenWidth >= 1280 ? "30px" : undefined,
                      marginTop: screenWidth >= 744 && screenWidth < 1280 ? "-21.37px" : undefined,
                      padding:
                        screenWidth >= 744 && screenWidth < 1280
                          ? "50px 20px"
                          : screenWidth >= 1280
                          ? "40px 25px"
                          : undefined,
                      width:
                        screenWidth >= 744 && screenWidth < 1280 ? "100%" : screenWidth >= 1280 ? "800px" : undefined,
                    }}
                  >
                    {screenWidth >= 744 && screenWidth < 1280 && (
                      <div className="frame-119">
                        <div className="group-11">
                          <div className="text-wrapper-103">2024/00/00</div>
                        </div>
                        <div className="frame-120">
                          <div className="text-wrapper-104">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                        </div>
                      </div>
                    )}

                    {screenWidth >= 1280 && (
                      <div className="frame-121">
                        <div className="group-11">
                          <div className="text-wrapper-106">2024/00/00</div>
                        </div>
                        <div className="frame-120">
                          <div className="text-wrapper-104">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                          <div className="text-wrapper-105">♯タグ</div>
                        </div>
                      </div>
                    )}

                    <p className="div-13">
                      <span className="text-wrapper-107">見出し</span>
                      <span className="text-wrapper-108">
                        見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し見出し
                      </span>
                    </p>
                    {screenWidth >= 1280 && (
                      <div className="frame-122">
                        <Grayline className="grayline-2" />
                        <div className="frame-123">
                          <div className="noimage-9-wrapper">
                            <Noimage23 className="noimage-23" />
                          </div>
                          <div className="group-16">
                            <Noimage23 className="noimage-9" />
                          </div>
                        </div>
                        <div className="text-wrapper-109">
                          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                        </div>
                      </div>
                    )}

                    {screenWidth >= 744 && screenWidth < 1280 && (
                      <>
                        <Grayline className="grayline-3" />
                        <div className="frame-124">
                          <div className="noimage-instance-wrapper">
                            <NoimageWrapper className="noimage-instance" noimage="/img/noimage-37.svg" />
                          </div>
                          <div className="frame-125">
                            <NoimageWrapper className="noimage-instance" noimage="/img/noimage-38.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-109">
                          テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className="frame-126"
                    style={{
                      flex: screenWidth >= 744 && screenWidth < 1280 ? "0 0 auto" : undefined,
                      height: screenWidth >= 1280 ? "60px" : undefined,
                      marginBottom: screenWidth >= 744 && screenWidth < 1280 ? "-21.37px" : undefined,
                    }}
                  >
                    <div
                      className="group-17"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height:
                          screenWidth >= 744 && screenWidth < 1280 ? "25px" : screenWidth >= 1280 ? "23px" : undefined,
                        width: screenWidth >= 1280 ? "82.68px" : undefined,
                      }}
                    >
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <>
                          <div className="text-wrapper-110">前の記事へ</div>
                          <Polygon className="polygon-2" element="/img/polygon-2-29.svg" one="one" />
                        </>
                      )}

                      {screenWidth >= 1280 && (
                        <div className="overlap-group-5">
                          <div className="text-wrapper-89">前の記事へ</div>
                          <Arrow31 className="arrow-31" />
                        </div>
                      )}
                    </div>
                    <Frame36
                      className={`${screenWidth >= 744 && screenWidth < 1280 && "class-48"} ${
                        screenWidth >= 1280 && "class-49"
                      }`}
                      divClassName={`${screenWidth >= 744 && screenWidth < 1280 && "class-50"} ${
                        screenWidth >= 1280 && "class-51"
                      }`}
                      one="one"
                    />
                    <div
                      className="group-18"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height:
                          screenWidth >= 744 && screenWidth < 1280 ? "25px" : screenWidth >= 1280 ? "23px" : undefined,
                        marginLeft: screenWidth >= 744 && screenWidth < 1280 ? "-2px" : undefined,
                        width: screenWidth >= 1280 ? "84px" : undefined,
                      }}
                    >
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <>
                          <div className="text-wrapper-111">次の記事へ</div>
                          <Polygon className="polygon-instance" element="/img/polygon-2-30.svg" one="one" />
                        </>
                      )}

                      {screenWidth >= 1280 && (
                        <>
                          <div className="text-wrapper-90">次の記事へ</div>
                          <Arrow38 className="arrow-38" color="#454545" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="view-3"
                  style={{
                    alignSelf: screenWidth >= 744 && screenWidth < 1280 ? "stretch" : undefined,
                    flex: screenWidth >= 744 && screenWidth < 1280 ? "0 0 auto" : undefined,
                    width:
                      screenWidth >= 744 && screenWidth < 1280 ? "100%" : screenWidth >= 1280 ? "240px" : undefined,
                  }}
                >
                  <div
                    className="frame-127"
                    style={{
                      padding:
                        screenWidth >= 744 && screenWidth < 1280
                          ? "20px 15px"
                          : screenWidth >= 1280
                          ? "15px"
                          : undefined,
                    }}
                  >
                    <div className="text-wrapper-112">カテゴリ</div>
                  </div>
                  <div
                    className="frame-128"
                    style={{
                      flex: screenWidth >= 1280 ? "0 0 auto" : undefined,
                      gap: screenWidth >= 744 && screenWidth < 1280 ? "30px" : screenWidth >= 1280 ? "22px" : undefined,
                      height: screenWidth >= 744 && screenWidth < 1280 ? "430px" : undefined,
                      padding:
                        screenWidth >= 744 && screenWidth < 1280
                          ? "30px 15px 45px"
                          : screenWidth >= 1280
                          ? "30px 15px"
                          : undefined,
                    }}
                  >
                    <div className="frame-129">
                      <div
                        className="text-wrapper-113"
                        style={{
                          fontWeight:
                            screenWidth >= 1280 ? "700" : screenWidth >= 744 && screenWidth < 1280 ? "600" : undefined,
                        }}
                      >
                        カテゴリ名
                      </div>
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <Polygon className="polygon-2-instance" element="/img/polygon-2-17.svg" one="one" />
                      )}

                      {screenWidth >= 1280 && <Polygon27 className="polygon-2-7" />}
                    </div>
                    <img
                      className="line-7"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height: screenWidth >= 1280 ? "2px" : undefined,
                      }}
                      alt="Line"
                      src={
                        screenWidth >= 744 && screenWidth < 1280
                          ? "/img/line-18-3.svg"
                          : screenWidth >= 1280
                          ? "/img/line-7.svg"
                          : undefined
                      }
                    />
                    <div className="frame-130">
                      <div
                        className="text-wrapper-114"
                        style={{
                          fontWeight:
                            screenWidth >= 1280 ? "700" : screenWidth >= 744 && screenWidth < 1280 ? "600" : undefined,
                        }}
                      >
                        カテゴリ名
                      </div>
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <Polygon className="polygon-2-instance" element="/img/polygon-2-17.svg" one="one" />
                      )}

                      {screenWidth >= 1280 && <Polygon27 className="polygon-2-7" />}
                    </div>
                    <img
                      className="line-9"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height: screenWidth >= 1280 ? "2px" : undefined,
                      }}
                      alt="Line"
                      src={
                        screenWidth >= 744 && screenWidth < 1280
                          ? "/img/line-18-3.svg"
                          : screenWidth >= 1280
                          ? "/img/line-7.svg"
                          : undefined
                      }
                    />
                    <div className="frame-131">
                      <div
                        className="text-wrapper-115"
                        style={{
                          fontWeight:
                            screenWidth >= 1280 ? "700" : screenWidth >= 744 && screenWidth < 1280 ? "600" : undefined,
                        }}
                      >
                        カテゴリ名
                      </div>
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <Polygon className="polygon-2-instance" element="/img/polygon-2-17.svg" one="one" />
                      )}

                      {screenWidth >= 1280 && <Polygon27 className="polygon-2-7" />}
                    </div>
                    <img
                      className="line-10"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height: screenWidth >= 1280 ? "2px" : undefined,
                      }}
                      alt="Line"
                      src={
                        screenWidth >= 744 && screenWidth < 1280
                          ? "/img/line-18-3.svg"
                          : screenWidth >= 1280
                          ? "/img/line-7.svg"
                          : undefined
                      }
                    />
                    <div className="frame-132">
                      <div
                        className="text-wrapper-116"
                        style={{
                          fontWeight:
                            screenWidth >= 1280 ? "700" : screenWidth >= 744 && screenWidth < 1280 ? "600" : undefined,
                        }}
                      >
                        カテゴリ名
                      </div>
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <Polygon className="polygon-2-instance" element="/img/polygon-2-17.svg" one="one" />
                      )}

                      {screenWidth >= 1280 && <Polygon27 className="polygon-2-7" />}
                    </div>
                    <img
                      className="line-11"
                      style={{
                        flex: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        flexGrow: screenWidth >= 744 && screenWidth < 1280 ? "1" : undefined,
                        height: screenWidth >= 1280 ? "2px" : undefined,
                      }}
                      alt="Line"
                      src={
                        screenWidth >= 744 && screenWidth < 1280
                          ? "/img/line-18-3.svg"
                          : screenWidth >= 1280
                          ? "/img/line-7.svg"
                          : undefined
                      }
                    />
                    <div
                      className="frame-133"
                      style={{
                        marginBottom: screenWidth >= 744 && screenWidth < 1280 ? "-19.00px" : undefined,
                      }}
                    >
                      <div
                        className="text-wrapper-117"
                        style={{
                          fontWeight:
                            screenWidth >= 1280 ? "700" : screenWidth >= 744 && screenWidth < 1280 ? "600" : undefined,
                        }}
                      >
                        カテゴリ名
                      </div>
                      {screenWidth >= 744 && screenWidth < 1280 && (
                        <Polygon className="polygon-2-instance" element="/img/polygon-2-17.svg" one="one" />
                      )}

                      {screenWidth >= 1280 && <Polygon27 className="polygon-2-7" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="CONTACT-8"
              style={{
                padding:
                  screenWidth >= 744 && screenWidth < 1280
                    ? "100px 20px"
                    : screenWidth >= 1280
                    ? "100px 15px"
                    : undefined,
              }}
            >
              <div
                className="CONTACT-9"
                style={{
                  width: screenWidth >= 744 && screenWidth < 1280 ? "704px" : screenWidth >= 1280 ? "700px" : undefined,
                }}
              >
                <div
                  className="frame-134"
                  style={{
                    left: screenWidth >= 744 && screenWidth < 1280 ? "2px" : undefined,
                  }}
                >
                  <div className="group-19">
                    <div className="overlap-group-6">
                      <div className="text-wrapper-118">お問い合わせ</div>
                      <div className="text-wrapper-119">CONTACT</div>
                    </div>
                  </div>
                  <div className="group-20">
                    <div className="flexcontainer-14">
                      <p className="text-i-15">
                        <span className="text-wrapper-120">
                          制作のご依頼・ご相談・ご質問など
                          <br />
                        </span>
                      </p>
                      <p className="text-i-15">
                        <span className="text-wrapper-120">お気軽にお問い合わせください。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer
              className="footer-5"
              style={{
                padding:
                  screenWidth >= 744 && screenWidth < 1280
                    ? "70px 20px 50px"
                    : screenWidth >= 1280
                    ? "70px 40px 50px"
                    : undefined,
              }}
            >
              <div
                className="frame-135"
                style={{
                  alignSelf: screenWidth >= 744 && screenWidth < 1280 ? "stretch" : undefined,
                  padding: screenWidth >= 1280 ? "0px 50px" : undefined,
                  width: screenWidth >= 744 && screenWidth < 1280 ? "100%" : screenWidth >= 1280 ? "1200px" : undefined,
                }}
              >
                <div
                  className="frame-136"
                  style={{
                    display:
                      screenWidth >= 744 && screenWidth < 1280
                        ? "inline-flex"
                        : screenWidth >= 1280
                        ? "flex"
                        : undefined,
                    flex: screenWidth >= 744 && screenWidth < 1280 ? "0 0 auto" : screenWidth >= 1280 ? "1" : undefined,
                    flexGrow: screenWidth >= 1280 ? "1" : undefined,
                    gap: screenWidth >= 744 && screenWidth < 1280 ? "30px" : screenWidth >= 1280 ? "44px" : undefined,
                  }}
                >
                  {screenWidth >= 744 && screenWidth < 1280 && (
                    <LogoWhite logoWhite="/img/logo-white-15.svg" logoWhiteClassName="logo-white-2" />
                  )}

                  {screenWidth >= 1280 && <LogoWhite7 className="logo-white-7" />}

                  <div className="flexcontainer-15">
                    <p className="text-i-16">
                      <span className="text-wrapper-121">
                        千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                      </span>
                    </p>
                    <p className="text-i-16">
                      <span className="text-wrapper-121">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                    </p>
                  </div>
                </div>
                <div className="frame-137">
                  <div
                    className="frame-138"
                    style={{
                      gap: screenWidth >= 744 && screenWidth < 1280 ? "30px" : screenWidth >= 1280 ? "40px" : undefined,
                      padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                    }}
                  >
                    <div className="frame-139">
                      <div
                        className="text-wrapper-122"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "15px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.75px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        理念
                      </div>
                      <div
                        className="text-wrapper-123"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 企業理念
                      </div>
                      <div
                        className="text-wrapper-124"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        {" "}
                        - 経営理念
                      </div>
                      <div
                        className="text-wrapper-125"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        {" "}
                        - 社訓
                      </div>
                    </div>
                    <div className="frame-139">
                      <div
                        className="text-wrapper-126"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "15px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.75px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        会社概要
                      </div>
                      <div
                        className="text-wrapper-127"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 代表挨拶
                      </div>
                      <div
                        className="text-wrapper-128"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 概要
                      </div>
                    </div>
                    <div className="frame-139">
                      <div
                        className="text-wrapper-129"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "15px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.75px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        制作について
                      </div>
                      <div
                        className="text-wrapper-130"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 業務内容
                      </div>
                      <div
                        className="text-wrapper-131"
                        style={{
                          fontFamily:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 1280
                              ? "13px"
                              : undefined,
                          fontStyle:
                            screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 1280
                              ? "0.65px"
                              : undefined,
                          lineHeight:
                            screenWidth >= 744 && screenWidth < 1280
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - ご相談の流れ
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-140"
                    style={{
                      padding: screenWidth >= 1280 ? "0px 20px" : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-132"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-family)"
                            : screenWidth >= 1280
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-size)"
                            : screenWidth >= 1280
                            ? "15px"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-weight)"
                            : screenWidth >= 1280
                            ? "700"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-line-height)"
                            : screenWidth >= 1280
                            ? "20px"
                            : undefined,
                      }}
                    >
                      ブログ
                    </div>
                    <div
                      className="text-wrapper-133"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-family)"
                            : screenWidth >= 1280
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-size)"
                            : screenWidth >= 1280
                            ? "15px"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-weight)"
                            : screenWidth >= 1280
                            ? "700"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-line-height)"
                            : screenWidth >= 1280
                            ? "20px"
                            : undefined,
                      }}
                    >
                      採用情報
                    </div>
                    <Link
                      className="text-wrapper-134"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-family)"
                            : screenWidth >= 1280
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-size)"
                            : screenWidth >= 1280
                            ? "15px"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-weight)"
                            : screenWidth >= 1280
                            ? "700"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-line-height)"
                            : screenWidth >= 1280
                            ? "20px"
                            : undefined,
                      }}
                      to="/contact-1200"
                    >
                      よくある質問
                    </Link>
                    <div
                      className="text-wrapper-135"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-family)"
                            : screenWidth >= 1280
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-size)"
                            : screenWidth >= 1280
                            ? "15px"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-weight)"
                            : screenWidth >= 1280
                            ? "700"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-line-height)"
                            : screenWidth >= 1280
                            ? "20px"
                            : undefined,
                      }}
                    >
                      情報セキュリティ基本方針
                    </div>
                    <Link
                      className="text-wrapper-136"
                      style={{
                        fontFamily:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-family)"
                            : screenWidth >= 1280
                            ? "'Noto Sans JP', Helvetica"
                            : undefined,
                        fontSize:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-size)"
                            : screenWidth >= 1280
                            ? "15px"
                            : undefined,
                        fontStyle:
                          screenWidth >= 744 && screenWidth < 1280 ? "var(--text-bold-mini-font-style)" : undefined,
                        fontWeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-font-weight)"
                            : screenWidth >= 1280
                            ? "700"
                            : undefined,
                        letterSpacing:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-letter-spacing)"
                            : screenWidth >= 1280
                            ? "0.75px"
                            : undefined,
                        lineHeight:
                          screenWidth >= 744 && screenWidth < 1280
                            ? "var(--text-bold-mini-line-height)"
                            : screenWidth >= 1280
                            ? "20px"
                            : undefined,
                      }}
                      to="/privacypolicy-1200"
                    >
                      プライバシーポリシー
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-101">©︎ Digital Puzzle Inc</div>
            </footer>
          </div>
        </>
      )}
    </div>
  );
};
