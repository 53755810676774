/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Eight = ({ className }) => {
  return (
    <svg
      className={`eight ${className}`}
      fill="none"
      height="10"
      viewBox="0 0 6 10"
      width="6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_232_8938)">
        <path
          className="path"
          d="M1.07031 1.42999L4.64031 4.99999L1.07031 8.56999"
          stroke="#8A8A8A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_232_8938">
          <rect className="rect" fill="white" height="8.14" transform="translate(0.570312 0.929993)" width="4.57" />
        </clipPath>
      </defs>
    </svg>
  );
};
