/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoWhite12 = ({ className }) => {
  return (
    <svg
      className={`logo-white-12 ${className}`}
      fill="none"
      height="41"
      viewBox="0 0 121 41"
      width="121"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_399_5006)">
        <path
          className="path"
          d="M19.2713 23.8892C20.8715 23.8892 22.1686 22.734 22.1686 21.3091C22.1686 19.8841 20.8715 18.7289 19.2713 18.7289C17.6712 18.7289 16.374 19.8841 16.374 21.3091C16.374 22.734 17.6712 23.8892 19.2713 23.8892Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2713 14.1656C20.5925 14.1656 21.6636 13.2118 21.6636 12.0351C21.6636 10.8585 20.5925 9.90466 19.2713 9.90466C17.95 9.90466 16.8789 10.8585 16.8789 12.0351C16.8789 13.2118 17.95 14.1656 19.2713 14.1656Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2781 18.9132C29.5994 18.9132 30.6704 17.9593 30.6704 16.7827C30.6704 15.6061 29.5994 14.6522 28.2781 14.6522C26.9568 14.6522 25.8857 15.6061 25.8857 16.7827C25.8857 17.9593 26.9568 18.9132 28.2781 18.9132Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2781 28.1944C29.5994 28.1944 30.6704 27.2406 30.6704 26.064C30.6704 24.8873 29.5994 23.9335 28.2781 23.9335C26.9568 23.9335 25.8857 24.8873 25.8857 26.064C25.8857 27.2406 26.9568 28.1944 28.2781 28.1944Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2713 32.7208C20.5925 32.7208 21.6636 31.767 21.6636 30.5903C21.6636 29.4137 20.5925 28.4598 19.2713 28.4598C17.95 28.4598 16.8789 29.4137 16.8789 30.5903C16.8789 31.767 17.95 32.7208 19.2713 32.7208Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.2644 18.9132C11.5857 18.9132 12.6568 17.9593 12.6568 16.7827C12.6568 15.6061 11.5857 14.6522 10.2644 14.6522C8.94316 14.6522 7.87207 15.6061 7.87207 16.7827C7.87207 17.9593 8.94316 18.9132 10.2644 18.9132Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.5633 28.1944C11.8845 28.1944 12.9556 27.2406 12.9556 26.064C12.9556 24.8873 11.8845 23.9335 10.5633 23.9335C9.24199 23.9335 8.1709 24.8873 8.1709 26.064C8.1709 27.2406 9.24199 28.1944 10.5633 28.1944Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.1386 3.9851C19.9341 3.9851 20.579 3.41081 20.579 2.70239C20.579 1.99397 19.9341 1.41968 19.1386 1.41968C18.3431 1.41968 17.6982 1.99397 17.6982 2.70239C17.6982 3.41081 18.3431 3.9851 19.1386 3.9851Z"
          fill="white"
        />
        <path
          className="path"
          d="M19.2714 40.5792C20.0669 40.5792 20.7118 40.0049 20.7118 39.2965C20.7118 38.5881 20.0669 38.0138 19.2714 38.0138C18.4759 38.0138 17.8311 38.5881 17.8311 39.2965C17.8311 40.0049 18.4759 40.5792 19.2714 40.5792Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.9775 13.3179C37.773 13.3179 38.4179 12.7436 38.4179 12.0352C38.4179 11.3267 37.773 10.7524 36.9775 10.7524C36.182 10.7524 35.5371 11.3267 35.5371 12.0352C35.5371 12.7436 36.182 13.3179 36.9775 13.3179Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.9775 31.7624C37.773 31.7624 38.4179 31.1882 38.4179 30.4797C38.4179 29.7713 37.773 29.197 36.9775 29.197C36.182 29.197 35.5371 29.7713 35.5371 30.4797C35.5371 31.1882 36.182 31.7624 36.9775 31.7624Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.66401 13.3179C2.45951 13.3179 3.10439 12.7436 3.10439 12.0352C3.10439 11.3267 2.45951 10.7524 1.66401 10.7524C0.868512 10.7524 0.223633 11.3267 0.223633 12.0352C0.223633 12.7436 0.868512 13.3179 1.66401 13.3179Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.76362 31.873C2.55912 31.873 3.204 31.2988 3.204 30.5903C3.204 29.8819 2.55912 29.3076 1.76362 29.3076C0.968121 29.3076 0.323242 29.8819 0.323242 30.5903C0.323242 31.2988 0.968121 31.873 1.76362 31.873Z"
          fill="white"
        />
        <path
          className="path"
          d="M36.8787 22.6508C37.7977 22.6508 38.5426 21.9874 38.5426 21.169C38.5426 20.3507 37.7977 19.6873 36.8787 19.6873C35.9598 19.6873 35.2148 20.3507 35.2148 21.169C35.2148 21.9874 35.9598 22.6508 36.8787 22.6508Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2781 36.1782C29.1971 36.1782 29.942 35.5148 29.942 34.6965C29.942 33.8781 29.1971 33.2147 28.2781 33.2147C27.3592 33.2147 26.6143 33.8781 26.6143 34.6965C26.6143 35.5148 27.3592 36.1782 28.2781 36.1782Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.5623 36.1782C11.4813 36.1782 12.2262 35.5148 12.2262 34.6965C12.2262 33.8781 11.4813 33.2147 10.5623 33.2147C9.64338 33.2147 8.89844 33.8781 8.89844 34.6965C8.89844 35.5148 9.64338 36.1782 10.5623 36.1782Z"
          fill="white"
        />
        <path
          className="path"
          d="M10.2645 8.97584C11.1834 8.97584 11.9284 8.31243 11.9284 7.49408C11.9284 6.67573 11.1834 6.01233 10.2645 6.01233C9.34553 6.01233 8.60059 6.67573 8.60059 7.49408C8.60059 8.31243 9.34553 8.97584 10.2645 8.97584Z"
          fill="white"
        />
        <path
          className="path"
          d="M1.66388 22.6508C2.58282 22.6508 3.32777 21.9874 3.32777 21.169C3.32777 20.3507 2.58282 19.6873 1.66388 19.6873C0.744946 19.6873 0 20.3507 0 21.169C0 21.9874 0.744946 22.6508 1.66388 22.6508Z"
          fill="white"
        />
        <path
          className="path"
          d="M28.2781 8.97584C29.1971 8.97584 29.942 8.31243 29.942 7.49408C29.942 6.67573 29.1971 6.01233 28.2781 6.01233C27.3592 6.01233 26.6143 6.67573 26.6143 7.49408C26.6143 8.31243 27.3592 8.97584 28.2781 8.97584Z"
          fill="white"
        />
        <path
          className="path"
          d="M20.2811 23.0488C21.8812 23.0488 23.1784 21.8936 23.1784 20.4686C23.1784 19.0436 21.8812 17.8884 20.2811 17.8884C18.681 17.8884 17.3838 19.0436 17.3838 20.4686C17.3838 21.8936 18.681 23.0488 20.2811 23.0488Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.281 13.3179C21.6023 13.3179 22.6734 12.364 22.6734 11.1874C22.6734 10.0107 21.6023 9.05688 20.281 9.05688C18.9598 9.05688 17.8887 10.0107 17.8887 11.1874C17.8887 12.364 18.9598 13.3179 20.281 13.3179Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2879 18.0727C30.6091 18.0727 31.6802 17.1189 31.6802 15.9422C31.6802 14.7656 30.6091 13.8118 29.2879 13.8118C27.9666 13.8118 26.8955 14.7656 26.8955 15.9422C26.8955 17.1189 27.9666 18.0727 29.2879 18.0727Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2879 27.354C30.6091 27.354 31.6802 26.4001 31.6802 25.2235C31.6802 24.0469 30.6091 23.093 29.2879 23.093C27.9666 23.093 26.8955 24.0469 26.8955 25.2235C26.8955 26.4001 27.9666 27.354 29.2879 27.354Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.281 31.8804C21.6023 31.8804 22.6734 30.9265 22.6734 29.7499C22.6734 28.5732 21.6023 27.6194 20.281 27.6194C18.9598 27.6194 17.8887 28.5732 17.8887 29.7499C17.8887 30.9265 18.9598 31.8804 20.281 31.8804Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.2752 18.0727C12.5964 18.0727 13.6675 17.1189 13.6675 15.9422C13.6675 14.7656 12.5964 13.8118 11.2752 13.8118C9.9539 13.8118 8.88281 14.7656 8.88281 15.9422C8.88281 17.1189 9.9539 18.0727 11.2752 18.0727Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.573 27.354C12.8943 27.354 13.9654 26.4001 13.9654 25.2235C13.9654 24.0469 12.8943 23.093 11.573 23.093C10.2518 23.093 9.18066 24.0469 9.18066 25.2235C9.18066 26.4001 10.2518 27.354 11.573 27.354Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.1484 3.14465C20.9439 3.14465 21.5888 2.57036 21.5888 1.86194C21.5888 1.15351 20.9439 0.579224 20.1484 0.579224C19.3529 0.579224 18.708 1.15351 18.708 1.86194C18.708 2.57036 19.3529 3.14465 20.1484 3.14465Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M20.2812 39.7388C21.0767 39.7388 21.7216 39.1645 21.7216 38.4561C21.7216 37.7476 21.0767 37.1733 20.2812 37.1733C19.4857 37.1733 18.8408 37.7476 18.8408 38.4561C18.8408 39.1645 19.4857 39.7388 20.2812 39.7388Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.9882 12.4701C38.7837 12.4701 39.4286 11.8958 39.4286 11.1874C39.4286 10.479 38.7837 9.90466 37.9882 9.90466C37.1927 9.90466 36.5479 10.479 36.5479 11.1874C36.5479 11.8958 37.1927 12.4701 37.9882 12.4701Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.9882 30.922C38.7837 30.922 39.4286 30.3477 39.4286 29.6393C39.4286 28.9309 38.7837 28.3566 37.9882 28.3566C37.1927 28.3566 36.5479 28.9309 36.5479 29.6393C36.5479 30.3477 37.1927 30.922 37.9882 30.922Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.68257 12.4701C3.47806 12.4701 4.12294 11.8958 4.12294 11.1874C4.12294 10.479 3.47806 9.90466 2.68257 9.90466C1.88707 9.90466 1.24219 10.479 1.24219 11.1874C1.24219 11.8958 1.88707 12.4701 2.68257 12.4701Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.7812 31.0326C3.5767 31.0326 4.22158 30.4583 4.22158 29.7499C4.22158 29.0415 3.5767 28.4672 2.7812 28.4672C1.9857 28.4672 1.34082 29.0415 1.34082 29.7499C1.34082 30.4583 1.9857 31.0326 2.7812 31.0326Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M37.8885 21.8103C38.8074 21.8103 39.5524 21.1469 39.5524 20.3286C39.5524 19.5102 38.8074 18.8468 37.8885 18.8468C36.9696 18.8468 36.2246 19.5102 36.2246 20.3286C36.2246 21.1469 36.9696 21.8103 37.8885 21.8103Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2879 35.3378C30.2068 35.3378 30.9518 34.6744 30.9518 33.856C30.9518 33.0377 30.2068 32.3743 29.2879 32.3743C28.369 32.3743 27.624 33.0377 27.624 33.856C27.624 34.6744 28.369 35.3378 29.2879 35.3378Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.5731 35.3378C12.492 35.3378 13.2369 34.6744 13.2369 33.856C13.2369 33.0377 12.492 32.3743 11.5731 32.3743C10.6541 32.3743 9.90918 33.0377 9.90918 33.856C9.90918 34.6744 10.6541 35.3378 11.5731 35.3378Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M11.2742 8.12806C12.1932 8.12806 12.9381 7.46466 12.9381 6.64631C12.9381 5.82795 12.1932 5.16455 11.2742 5.16455C10.3553 5.16455 9.61035 5.82795 9.61035 6.64631C9.61035 7.46466 10.3553 8.12806 11.2742 8.12806Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M2.68244 21.8103C3.60138 21.8103 4.34632 21.1469 4.34632 20.3286C4.34632 19.5102 3.60138 18.8468 2.68244 18.8468C1.7635 18.8468 1.01855 19.5102 1.01855 20.3286C1.01855 21.1469 1.7635 21.8103 2.68244 21.8103Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M29.2879 8.12806C30.2068 8.12806 30.9518 7.46466 30.9518 6.64631C30.9518 5.82795 30.2068 5.16455 29.2879 5.16455C28.369 5.16455 27.624 5.82795 27.624 6.64631C27.624 7.46466 28.369 8.12806 29.2879 8.12806Z"
          fill="#326291"
        />
        <path
          className="path"
          d="M51.3408 2.10522H56.1835C58.253 2.10522 59.8258 2.51805 60.9102 3.35108C61.9946 4.1841 62.5327 5.38573 62.5327 6.96332C62.5327 8.5409 61.9533 9.71304 60.8109 10.5534C59.6602 11.3938 58.0212 11.8214 55.8937 11.8214H51.3408V2.10522ZM60.2149 6.9412C60.2149 4.81809 58.8738 3.75653 56.2 3.75653H53.5759V10.148H55.96C58.7993 10.148 60.2231 9.07905 60.2231 6.9412H60.2149Z"
          fill="white"
        />
        <path className="path" d="M64.7344 2.10522H66.9943V11.814H64.7344V2.10522Z" fill="white" />
        <path
          className="path"
          d="M69.1875 7.00016C69.1875 5.92386 69.4441 5.00237 69.9656 4.23569C70.4871 3.46902 71.2073 2.87926 72.1428 2.47381C73.0782 2.06835 74.1543 1.86194 75.3712 1.86194C76.8281 1.86194 77.9953 2.12733 78.8645 2.66548C79.7337 3.20363 80.3297 3.9187 80.636 4.8107L78.3761 5.06872C78.1526 4.53794 77.7884 4.13986 77.2834 3.88184C76.7785 3.6312 76.1162 3.4985 75.305 3.4985C74.0715 3.4985 73.1278 3.80812 72.4822 4.41999C71.8282 5.03923 71.5053 5.887 71.5053 6.9633C71.5053 8.0396 71.8613 8.9316 72.5649 9.52136C73.2686 10.1111 74.2868 10.406 75.6112 10.406C76.853 10.406 77.9043 10.2217 78.7735 9.86046V8.09121H75.6444V6.55048H80.8678V10.7156C80.1973 11.1432 79.4026 11.4675 78.4837 11.7034C77.5649 11.932 76.6129 12.0499 75.6112 12.0499C74.2785 12.0499 73.1278 11.8509 72.1676 11.4454C71.2073 11.04 70.4706 10.465 69.9574 9.71303C69.4441 8.96109 69.1875 8.05435 69.1875 6.99279V7.00016Z"
          fill="white"
        />
        <path className="path" d="M83.1611 2.10522H85.421V11.814H83.1611V2.10522Z" fill="white" />
        <path
          className="path"
          d="M91.3153 3.79339H86.9941V2.10522H97.8798V3.79339H93.5752V11.814H91.3153V3.79339Z"
          fill="white"
        />
        <path
          className="path"
          d="M99.7338 11.814H97.3994L102.441 2.10522H104.957L110.015 11.814H107.606L106.447 9.514H100.876L99.7421 11.814H99.7338ZM103.666 3.81551L101.605 7.95115H105.711L103.666 3.81551Z"
          fill="white"
        />
        <path
          className="path"
          d="M111.596 2.10522H113.856V10.1259H120.602L120.312 11.814H111.596V2.10522Z"
          fill="white"
        />
        <path
          className="path"
          d="M51.3408 15.699H56.6056C59.6188 15.699 61.1255 16.7532 61.1255 18.8542C61.1255 19.9452 60.7364 20.7487 59.9583 21.2648C59.1801 21.7882 58.0046 22.0462 56.4235 22.0462H53.5511V25.4078H51.3408V15.699ZM58.8656 18.8394C58.8656 18.3086 58.6752 17.9179 58.2861 17.6673C57.897 17.4166 57.2927 17.2987 56.4566 17.2987H53.5593V20.4539H56.4318C57.2679 20.4539 57.8805 20.3212 58.2778 20.0558C58.6752 19.7904 58.8738 19.3849 58.8738 18.832L58.8656 18.8394Z"
          fill="white"
        />
        <path
          className="path"
          d="M63.3848 21.3607V15.7064H65.6447V21.486C65.6447 22.3411 65.8847 22.9678 66.3814 23.3658C66.8698 23.7713 67.6314 23.9703 68.6744 23.9703C69.7175 23.9703 70.479 23.7713 70.9674 23.3658C71.4558 22.9604 71.7042 22.3338 71.7042 21.486V15.7064H73.9641V21.3607C73.9641 22.8351 73.5171 23.9261 72.6231 24.6191C71.729 25.312 70.4128 25.6585 68.6827 25.6585C66.9526 25.6585 65.6281 25.312 64.7341 24.6191C63.8401 23.9261 63.4013 22.8424 63.4013 21.3607H63.3848Z"
          fill="white"
        />
        <path
          className="path"
          d="M75.9844 23.9408L83.12 17.3356H76.1251V15.7064H85.9594V17.1734L78.832 23.7787H86.2409L85.9511 25.4079H75.9927V23.9408H75.9844Z"
          fill="white"
        />
        <path
          className="path"
          d="M87.6641 23.9408L94.7997 17.3356H87.8048V15.7064H97.6391V17.1734L90.5117 23.7787H97.9205L97.6308 25.4079H87.6723V23.9408H87.6641Z"
          fill="white"
        />
        <path className="path" d="M100.554 15.699H102.814V23.7196H109.56L109.27 25.4078H100.554V15.699Z" fill="white" />
        <path
          className="path"
          d="M111.629 15.699H120.669V17.2766H113.856V19.6429H120.329V21.2205H113.856V23.8302H121L120.726 25.4078H111.629V15.699Z"
          fill="white"
        />
        <path className="path" d="M51.3408 29.3002H53.6007V39.009H51.3408V29.3002Z" fill="white" />
        <path
          className="path"
          d="M56.9609 29.3002H59.0387L65.5287 36.2593V29.3002H67.6975V39.009H65.6198L59.1298 32.0351V39.009H56.9609V29.3002Z"
          fill="white"
        />
        <path
          className="path"
          d="M70.1973 34.1582C70.1973 33.163 70.4373 32.2784 70.9175 31.5117C71.3976 30.7451 72.0847 30.1479 72.9952 29.713C73.9058 29.278 74.982 29.0569 76.2485 29.0569C79.2286 29.0569 81.0498 30.1553 81.7037 32.3595L79.4769 32.6028C79.2203 31.9393 78.8395 31.4675 78.3346 31.1726C77.8213 30.8851 77.126 30.7377 76.2402 30.7377C75.0979 30.7377 74.2038 31.0399 73.5416 31.6444C72.8876 32.2489 72.5565 33.0819 72.5565 34.1509C72.5565 35.2198 72.8876 36.0602 73.5499 36.6573C74.2121 37.2618 75.131 37.5641 76.2982 37.5641C77.2584 37.5641 78.0034 37.4093 78.5415 37.0923C79.0796 36.7826 79.4355 36.2887 79.6094 35.6326H81.8858C81.712 36.79 81.1408 37.6894 80.1557 38.3086C79.1707 38.9279 77.8793 39.2449 76.2651 39.2449C74.982 39.2449 73.8893 39.0311 72.987 38.6035C72.0847 38.1759 71.3976 37.5788 70.9175 36.8121C70.4373 36.0455 70.1973 35.1535 70.1973 34.1435V34.1582Z"
          fill="white"
        />
        <path className="path" d="M83.666 36.9448H85.8514V39.009H83.666V36.9448Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_399_5006">
          <rect className="rect" fill="white" height="40" transform="translate(0 0.579224)" width="121" />
        </clipPath>
      </defs>
    </svg>
  );
};
