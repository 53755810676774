/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Arrow38 } from "../../icons/Arrow38";
import { Arrow4 } from "../../icons/Arrow4";
import "./style.css";

export const Button = ({
  color,
  device,
  frameClassName,
  divClassName,
  text = "ボタン",
  className,
  arrow38StyleOverrideClassName,
  to,
}) => {
  return (
    <Link className={`button color-0-${color} device-${device} ${className}`} to={to}>
      {device === "PC" && (
        <div className={`frame-2 ${frameClassName}`}>
          <div className={`text-wrapper ${divClassName}`}>{text}</div>
        </div>
      )}

      {(color === "one" ||
        color === "seven" ||
        (color === "gray" && device === "PC") ||
        (color === "white" && device === "PC")) && (
        <Arrow38
          className={arrow38StyleOverrideClassName}
          color={color === "white" ? "#454545" : color === "seven" ? "#6079C1" : "white"}
        />
      )}

      {["eight", "g-hover", "p-hover", "w-hover"].includes(color) && (
        <img
          className="img"
          alt="Polygon"
          src={
            color === "p-hover"
              ? "/img/polygon-1.svg"
              : color === "eight"
              ? "/img/polygon-1-3.svg"
              : "/img/polygon-1-1.svg"
          }
        />
      )}

      {device === "SP" && (
        <>
          <div className={`frame-3 ${frameClassName}`}>
            <div className={`text-wrapper-2 ${divClassName}`}>{text}</div>
          </div>
          <Arrow4
            className="arrow"
            color={color === "white" ? "#6079C1" : color === "white-textgray" ? "#454545" : "white"}
          />
        </>
      )}
    </Link>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "seven",
    "gray",
    "p-hover",
    "w-hover",
    "one",
    "purple",
    "eight",
    "white-textgray",
    "g-hover",
  ]),
  device: PropTypes.oneOf(["PC", "SP"]),
  text: PropTypes.string,
  to: PropTypes.string,
};
