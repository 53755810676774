import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { GraylineWrapper } from "../../components/GraylineWrapper";
import { Heading } from "../../components/Heading";
import { LineWrapper } from "../../components/LineWrapper";
import { SpWhite } from "../../components/SpWhite";
import { LogoWhite12 } from "../../icons/LogoWhite12";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite30 } from "../../icons/LogoWhite30";
import { Menu3 } from "../../icons/Menu3";
import { Twelve } from "../../icons/Twelve";
import { TwentyTwo } from "../../icons/TwentyTwo";
import "./style.css";

export const Profile = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="profile"
      style={{
        minWidth:
          screenWidth < 600
            ? "320px"
            : screenWidth >= 600 && screenWidth < 1200
            ? "600px"
            : screenWidth >= 1200
            ? "1200px"
            : undefined,
      }}
    >
      {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
        <SpWhite className="header-24" icon={<Menu3 className="menu-8" />} />
      )}

      {screenWidth >= 1200 && (
        <ColorWhite className="header-25" groupClassName="header-26" menuStyleOverrideClassName="header-27" />
      )}

      <div className="div-18">
        <div className="midashi-5">
          <ElementWrapper
            className={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-107"} ${
              screenWidth >= 1200 && "class-108"
            }`}
            headingDivClassName={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-103"} ${
              screenWidth >= 1200 && "class-104"
            }`}
            headingDivClassNameOverride={`${
              ((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-105"
            } ${screenWidth >= 1200 && "class-106"}`}
            headingText="PROFILE"
            headingText1="会社概要"
            one={
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "two"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
          />
          <div
            className="breadcrumbs-8"
            style={{
              padding:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "10px 15px 20px"
                  : screenWidth >= 1200
                  ? "20px 15px 40px"
                  : undefined,
            }}
          >
            <div
              className="frame-237"
              style={{
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "17px"
                    : screenWidth >= 1200
                    ? "30px"
                    : undefined,
                padding:
                  screenWidth >= 600 && screenWidth < 1200 ? "0px 10px" : screenWidth >= 1200 ? "0px 50px" : undefined,
              }}
            >
              {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                <>
                  <Link className="text-wrapper-275" to="/home-320">
                    TOP
                  </Link>
                  <TwentyTwo className="instance-node-8" />
                </>
              )}

              <div
                className="TOP-4"
                style={{
                  fontFamily:
                    screenWidth >= 1200
                      ? "var(--tag-font-family)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-family)"
                      : undefined,
                  fontSize:
                    screenWidth >= 1200
                      ? "var(--tag-font-size)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-size)"
                      : undefined,
                  fontStyle:
                    screenWidth >= 1200
                      ? "var(--tag-font-style)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-style)"
                      : undefined,
                  fontWeight:
                    screenWidth >= 1200
                      ? "var(--tag-font-weight)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    screenWidth >= 1200
                      ? "var(--tag-letter-spacing)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-letter-spacing)"
                      : undefined,
                  lineHeight:
                    screenWidth >= 1200
                      ? "var(--tag-line-height)"
                      : (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--smalltext-SP-line-height)"
                      : undefined,
                }}
              >
                {screenWidth >= 1200 && <>TOP</>}

                {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && <>会社概要</>}
              </div>
              {screenWidth >= 1200 && (
                <>
                  <Twelve className="instance-node-8" />
                  <div className="text-wrapper-276">会社概要</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className="greeting"
          style={{
            gap:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "20px"
                : screenWidth >= 1200
                ? "40px"
                : undefined,
            padding:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "40px 15px"
                : screenWidth >= 1200
                ? "80px 15px"
                : undefined,
          }}
        >
          <Heading
            className="heading-31"
            divClassName={`${screenWidth >= 1200 && "class-110"}`}
            divClassNameOverride={`${((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-109"}`}
            one={
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "SP"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
            text="GREETING"
            text1="代表挨拶"
          />
          <div
            className="flexcontainer-31"
            style={{
              display:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "flex"
                  : screenWidth >= 1200
                  ? "inline-flex"
                  : undefined,
            }}
          >
            <p
              className="text-19"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-family)"
                    : screenWidth >= 1200
                    ? "'Noto Sans JP', Helvetica"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-size)"
                    : screenWidth >= 1200
                    ? "25px"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-weight)"
                    : screenWidth >= 1200
                    ? "700"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-letter-spacing)"
                    : screenWidth >= 1200
                    ? "1.25px"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-line-height)"
                    : screenWidth >= 1200
                    ? "normal"
                    : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "0.31px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : undefined,
                }}
              >
                テキストテキストテキスト
                <br />
              </span>
            </p>
            <p
              className="text-20"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-family)"
                    : screenWidth >= 1200
                    ? "'Noto Sans JP', Helvetica"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-size)"
                    : screenWidth >= 1200
                    ? "25px"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-weight)"
                    : screenWidth >= 1200
                    ? "700"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-letter-spacing)"
                    : screenWidth >= 1200
                    ? "1.25px"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-line-height)"
                    : screenWidth >= 1200
                    ? "normal"
                    : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "0.31px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : undefined,
                }}
              >
                テキストテキストテキスト
                <br />
              </span>
            </p>
            <p
              className="text-21"
              style={{
                fontFamily:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-family)"
                    : screenWidth >= 1200
                    ? "'Noto Sans JP', Helvetica"
                    : undefined,
                fontSize:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-size)"
                    : screenWidth >= 1200
                    ? "25px"
                    : undefined,
                fontStyle:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-style)"
                    : undefined,
                fontWeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-font-weight)"
                    : screenWidth >= 1200
                    ? "700"
                    : undefined,
                letterSpacing:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-letter-spacing)"
                    : screenWidth >= 1200
                    ? "1.25px"
                    : undefined,
                lineHeight:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "var(--bold-SP-line-height)"
                    : screenWidth >= 1200
                    ? "normal"
                    : undefined,
              }}
            >
              <span
                className="text-wrapper-670"
                style={{
                  fontFamily:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-family)"
                      : undefined,
                  fontSize:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-size)"
                      : undefined,
                  fontStyle:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-style)"
                      : undefined,
                  fontWeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-font-weight)"
                      : undefined,
                  letterSpacing:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-letter-spacing)"
                      : screenWidth >= 1200
                      ? "0.31px"
                      : undefined,
                  lineHeight:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "var(--bold-SP-line-height)"
                      : undefined,
                }}
              >
                テキストテキストテキスト
              </span>
            </p>
          </div>
        </div>
        <div
          className="members"
          style={{
            gap:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "20px"
                : screenWidth >= 1200
                ? "50px"
                : undefined,
            padding:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "40px 20px"
                : screenWidth >= 1200
                ? "80px 15px"
                : undefined,
          }}
        >
          <Heading
            className="heading-32"
            divClassName={`${screenWidth >= 1200 && "class-112"}`}
            divClassNameOverride={`${
              ((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && "class-110"
            } ${screenWidth >= 1200 && "class-111"}`}
            one={
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "SP"
                : screenWidth >= 1200
                ? "one"
                : undefined
            }
            text="MEMBERS"
            text1="メンバー紹介"
          />
          <div
            className="frame-238"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              display:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "flex"
                  : screenWidth >= 1200
                  ? "inline-flex"
                  : undefined,
              maxWidth: screenWidth >= 1200 ? "1000px" : undefined,
              overflow: screenWidth < 600 ? "hidden" : undefined,
              width: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "100%" : undefined,
            }}
          >
            <div
              className="member"
              style={{
                alignItems:
                  screenWidth < 600
                    ? "center"
                    : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                    ? "flex-start"
                    : undefined,
                alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                flexDirection: screenWidth < 600 ? "column" : undefined,
                gap:
                  screenWidth < 600
                    ? "10px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "15px"
                    : screenWidth >= 1200
                    ? "40px"
                    : undefined,
                justifyContent: screenWidth >= 600 && screenWidth < 1200 ? "center" : undefined,
                padding: screenWidth >= 1200 ? "0px 10px" : undefined,
                width:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "100%"
                    : screenWidth >= 1200
                    ? "1000px"
                    : undefined,
              }}
            >
              <img
                className="rectangle-5"
                style={{
                  height:
                    screenWidth < 600
                      ? "179.1px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "149.09px"
                      : screenWidth >= 1200
                      ? "200px"
                      : undefined,
                  width:
                    screenWidth < 600
                      ? "180px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "150px"
                      : screenWidth >= 1200
                      ? "201px"
                      : undefined,
                }}
                alt="Rectangle"
                src="/img/rectangle-18-2x.png"
              />
              <div
                className="frame-239"
                style={{
                  alignSelf: screenWidth < 600 ? "stretch" : undefined,
                  flex:
                    screenWidth < 600
                      ? "0 0 auto"
                      : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                      ? "1"
                      : undefined,
                  flexGrow: screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200) ? "1" : undefined,
                  width: screenWidth < 600 ? "100%" : undefined,
                }}
              >
                <div className="frame-240">
                  <div
                    className="text-wrapper-277"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-size)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "1.00px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-line-height)"
                          : screenWidth >= 1200
                          ? "37px"
                          : undefined,
                      whiteSpace: screenWidth >= 1200 ? "nowrap" : undefined,
                    }}
                  >
                    名前　名前
                  </div>
                  <LineWrapper
                    line={
                      screenWidth < 600
                        ? "/img/line-2-22.svg"
                        : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                        ? "/img/line-2.svg"
                        : undefined
                    }
                  />
                  <div
                    className="text-wrapper-278"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    肩書き　職種
                  </div>
                </div>
                <div
                  className="frame-241"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "14px"
                        : screenWidth >= 1200
                        ? "8px"
                        : undefined,
                  }}
                >
                  <div
                    className="frame-242"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-279"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-280"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-243"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-281"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-282"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-244"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-283"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-284"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-245"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-285"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-286"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="line-13"
              style={{
                alignSelf: screenWidth >= 600 && screenWidth < 1200 ? "stretch" : undefined,
                marginLeft: screenWidth < 600 ? "-360.00px" : undefined,
                marginRight: screenWidth < 600 ? "-360.00px" : undefined,
                padding: screenWidth >= 1200 || screenWidth < 600 ? "0px 20px" : undefined,
                width:
                  screenWidth >= 1200 || screenWidth < 600
                    ? "1000px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "100%"
                    : undefined,
              }}
            >
              {screenWidth < 600 && (
                <div className="grayline-4">
                  <img className="line-14" alt="Line" src="/img/line-13-6.svg" />
                </div>
              )}

              {(screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)) && (
                <GraylineWrapper
                  className={`${screenWidth >= 600 && screenWidth < 1200 && "class-113"} ${
                    screenWidth >= 1200 && "class-114"
                  }`}
                />
              )}
            </div>
            <div
              className="member-2"
              style={{
                alignItems:
                  screenWidth < 600
                    ? "center"
                    : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                    ? "flex-start"
                    : undefined,
                alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                flexDirection: screenWidth < 600 ? "column" : undefined,
                gap:
                  screenWidth < 600
                    ? "10px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "15px"
                    : screenWidth >= 1200
                    ? "40px"
                    : undefined,
                justifyContent: screenWidth >= 600 && screenWidth < 1200 ? "center" : undefined,
                padding: screenWidth >= 1200 ? "0px 10px" : undefined,
                width:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "100%"
                    : screenWidth >= 1200
                    ? "1000px"
                    : undefined,
              }}
            >
              <img
                className="rectangle-6"
                style={{
                  height:
                    screenWidth < 600
                      ? "179.1px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "149.25px"
                      : screenWidth >= 1200
                      ? "200px"
                      : undefined,
                  width:
                    screenWidth < 600
                      ? "180px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "150px"
                      : screenWidth >= 1200
                      ? "201px"
                      : undefined,
                }}
                alt="Rectangle"
                src="/img/rectangle-18-1.png"
              />
              <div
                className="frame-246"
                style={{
                  alignSelf: screenWidth < 600 ? "stretch" : undefined,
                  flex:
                    screenWidth < 600
                      ? "0 0 auto"
                      : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                      ? "1"
                      : undefined,
                  flexGrow: screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200) ? "1" : undefined,
                  width: screenWidth < 600 ? "100%" : undefined,
                }}
              >
                <div className="frame-240">
                  <div
                    className="text-wrapper-287"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-size)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "1.00px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-line-height)"
                          : screenWidth >= 1200
                          ? "37px"
                          : undefined,
                      whiteSpace: screenWidth >= 1200 ? "nowrap" : undefined,
                    }}
                  >
                    名前　名前
                  </div>
                  <LineWrapper
                    line={
                      screenWidth < 600
                        ? "/img/line-2-23.svg"
                        : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                        ? "/img/line-2.svg"
                        : undefined
                    }
                  />
                  <div
                    className="text-wrapper-288"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    肩書き　職種
                  </div>
                </div>
                <div
                  className="frame-247"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "14px"
                        : screenWidth >= 1200
                        ? "8px"
                        : undefined,
                  }}
                >
                  <div
                    className="frame-248"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-289"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-290"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-249"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-291"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-292"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-250"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-293"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-294"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-251"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-295"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-296"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="line-15"
              style={{
                alignSelf: screenWidth >= 600 && screenWidth < 1200 ? "stretch" : undefined,
                marginLeft: screenWidth < 600 ? "-360.00px" : undefined,
                marginRight: screenWidth < 600 ? "-360.00px" : undefined,
                padding: screenWidth >= 1200 || screenWidth < 600 ? "0px 20px" : undefined,
                width:
                  screenWidth >= 1200 || screenWidth < 600
                    ? "1000px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "100%"
                    : undefined,
              }}
            >
              {screenWidth < 600 && (
                <div className="grayline-4">
                  <img className="line-14" alt="Line" src="/img/line-13-7.svg" />
                </div>
              )}

              {(screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)) && (
                <GraylineWrapper
                  className={`${screenWidth >= 600 && screenWidth < 1200 && "class-113"} ${
                    screenWidth >= 1200 && "class-114"
                  }`}
                />
              )}
            </div>
            <div
              className="member-3"
              style={{
                alignItems:
                  screenWidth < 600
                    ? "center"
                    : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                    ? "flex-start"
                    : undefined,
                alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                flexDirection: screenWidth < 600 ? "column" : undefined,
                gap:
                  screenWidth < 600
                    ? "10px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "15px"
                    : screenWidth >= 1200
                    ? "40px"
                    : undefined,
                justifyContent: screenWidth >= 600 && screenWidth < 1200 ? "center" : undefined,
                padding: screenWidth >= 1200 ? "0px 10px" : undefined,
                width:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "100%"
                    : screenWidth >= 1200
                    ? "1000px"
                    : undefined,
              }}
            >
              <img
                className="rectangle-7"
                style={{
                  height:
                    screenWidth < 600
                      ? "179.1px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "149.25px"
                      : screenWidth >= 1200
                      ? "200px"
                      : undefined,
                  width:
                    screenWidth < 600
                      ? "180px"
                      : screenWidth >= 600 && screenWidth < 1200
                      ? "150px"
                      : screenWidth >= 1200
                      ? "201px"
                      : undefined,
                }}
                alt="Rectangle"
                src="/img/rectangle-18-2.png"
              />
              <div
                className="frame-252"
                style={{
                  alignSelf: screenWidth < 600 ? "stretch" : undefined,
                  flex:
                    screenWidth < 600
                      ? "0 0 auto"
                      : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                      ? "1"
                      : undefined,
                  flexGrow: screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200) ? "1" : undefined,
                  width: screenWidth < 600 ? "100%" : undefined,
                }}
              >
                <div className="frame-240">
                  <div
                    className="text-wrapper-297"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-size)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "1.00px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--bold-SP-line-height)"
                          : screenWidth >= 1200
                          ? "37px"
                          : undefined,
                      whiteSpace: screenWidth >= 1200 ? "nowrap" : undefined,
                    }}
                  >
                    名前　名前
                  </div>
                  <LineWrapper
                    line={
                      screenWidth < 600
                        ? "/img/line-2-24.svg"
                        : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                        ? "/img/line-2.svg"
                        : undefined
                    }
                  />
                  <div
                    className="text-wrapper-298"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "var(--text-font-family)"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "var(--text-font-size)"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : screenWidth >= 1200
                          ? "var(--text-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "var(--text-font-weight)"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "var(--text-letter-spacing)"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "var(--text-line-height)"
                          : undefined,
                    }}
                  >
                    肩書き　職種
                  </div>
                </div>
                <div
                  className="frame-253"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "14px"
                        : screenWidth >= 1200
                        ? "8px"
                        : undefined,
                  }}
                >
                  <div
                    className="frame-254"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-299"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-300"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-255"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-301"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-302"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-256"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-303"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-304"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                  <div
                    className="frame-257"
                    style={{
                      gap:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "30px 30px"
                          : screenWidth >= 1200
                          ? "41px 41px"
                          : undefined,
                    }}
                  >
                    <div
                      className="text-wrapper-305"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      2000年
                    </div>
                    <div
                      className="text-wrapper-306"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : screenWidth >= 1200
                            ? "var(--text-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : screenWidth >= 1200
                            ? "var(--text-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : screenWidth >= 1200
                            ? "var(--text-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : screenWidth >= 1200
                            ? "var(--text-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "var(--text-letter-spacing)"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : screenWidth >= 1200
                            ? "var(--text-line-height)"
                            : undefined,
                      }}
                    >
                      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="CONTACT-18"
          style={{
            alignSelf: screenWidth >= 1200 || screenWidth < 600 ? "stretch" : undefined,
            justifyContent: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "center" : undefined,
            padding:
              screenWidth < 600
                ? "80px 30px"
                : screenWidth >= 600 && screenWidth < 1200
                ? "40px 50px"
                : screenWidth >= 1200
                ? "100px 15px"
                : undefined,
            width:
              screenWidth >= 1200 || screenWidth < 600
                ? "100%"
                : screenWidth >= 600 && screenWidth < 1200
                ? "600px"
                : undefined,
          }}
        >
          <Link
            className="CONTACT-19"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1200
                  ? "700px"
                  : undefined,
            }}
            to="/contact-600"
          >
            <div
              className="frame-258"
              style={{
                gap:
                  screenWidth >= 1200 || screenWidth < 600
                    ? "20px 74px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "20px 20px"
                    : undefined,
                left: screenWidth < 600 ? "-10px" : undefined,
                padding:
                  screenWidth < 600
                    ? "25px 60px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "40px 20px"
                    : screenWidth >= 1200
                    ? "50px 60px"
                    : undefined,
                top: screenWidth < 600 ? "-5px" : screenWidth >= 600 && screenWidth < 1200 ? "14px" : undefined,
                width:
                  screenWidth < 600
                    ? "280px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "500px"
                    : screenWidth >= 1200
                    ? "700px"
                    : undefined,
              }}
            >
              <div
                className="group-33"
                style={{
                  height:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "53px"
                      : screenWidth >= 1200
                      ? "60px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-26.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-30.00px" : undefined,
                }}
              >
                {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                  <>
                    <div className="text-wrapper-307">お問い合わせ</div>
                    <div className="text-wrapper-308">CONTACT</div>
                  </>
                )}

                {screenWidth >= 1200 && (
                  <div className="overlap-group-10">
                    <div className="text-wrapper-309">お問い合わせ</div>
                    <div className="text-wrapper-310">CONTACT</div>
                  </div>
                )}
              </div>
              <div
                className="group-34"
                style={{
                  height:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "48px"
                      : screenWidth >= 1200
                      ? "55px"
                      : undefined,
                  marginLeft: screenWidth < 600 ? "-25.00px" : undefined,
                  marginRight: screenWidth < 600 ? "-27.00px" : undefined,
                  width:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "212px"
                      : screenWidth >= 1200
                      ? "254px"
                      : undefined,
                }}
              >
                <div
                  className="flexcontainer-32"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "2px"
                        : screenWidth >= 1200
                        ? "5px"
                        : undefined,
                    height:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "48px"
                        : screenWidth >= 1200
                        ? "55px"
                        : undefined,
                    width:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "210px"
                        : screenWidth >= 1200
                        ? "252px"
                        : undefined,
                  }}
                >
                  <p
                    className="text-i-31"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-i-32"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-font-weight)"
                          : screenWidth >= 1200
                          ? "400"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-SP-line-height)"
                          : screenWidth >= 1200
                          ? "25px"
                          : undefined,
                    }}
                  >
                    <span
                      className="text-wrapper-670"
                      style={{
                        fontFamily:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-family)"
                            : undefined,
                        fontSize:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-size)"
                            : undefined,
                        fontStyle:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-style)"
                            : undefined,
                        fontWeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-font-weight)"
                            : undefined,
                        letterSpacing:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-letter-spacing)"
                            : screenWidth >= 1200
                            ? "0.11px"
                            : undefined,
                        lineHeight:
                          (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                            ? "var(--text-SP-line-height)"
                            : undefined,
                      }}
                    >
                      お気軽にお問い合わせください。
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <footer
          className="footer-10"
          style={{
            padding:
              (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                ? "50px 20px"
                : screenWidth >= 1200
                ? "70px 40px 50px"
                : undefined,
          }}
        >
          <div
            className="frame-259"
            style={{
              alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
              flexDirection: screenWidth < 600 ? "column" : undefined,
              flexWrap: screenWidth >= 1200 ? "wrap" : undefined,
              gap: screenWidth < 600 ? "54px" : screenWidth >= 1200 ? "50px 150px" : undefined,
              justifyContent:
                screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200) ? "space-between" : undefined,
              marginLeft: screenWidth >= 1200 ? "-40.00px" : undefined,
              marginRight: screenWidth >= 1200 ? "-40.00px" : undefined,
              padding: screenWidth >= 1200 ? "0px 50px" : undefined,
              width:
                (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                  ? "100%"
                  : screenWidth >= 1200
                  ? "1200px"
                  : undefined,
            }}
          >
            <div
              className="frame-260"
              style={{
                alignSelf: screenWidth < 600 ? "stretch" : undefined,
                flex: screenWidth < 600 ? "0 0 auto" : screenWidth >= 1200 ? "1" : undefined,
                flexGrow: screenWidth >= 1200 ? "1" : undefined,
                gap:
                  (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                    ? "24px"
                    : screenWidth >= 1200
                    ? "44px"
                    : undefined,
                width: screenWidth < 600 ? "100%" : screenWidth >= 600 && screenWidth < 1200 ? "205px" : undefined,
              }}
            >
              {screenWidth < 600 && <LogoWhite12 className="logo-white-4" />}

              {screenWidth >= 600 && screenWidth < 1200 && <LogoWhite22 className="logo-white-4" />}

              {((screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600) && (
                <div className="flexcontainer-33">
                  <p className="text-i-33">
                    <span className="text-wrapper-311">
                      千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                    </span>
                  </p>
                  <p className="text-i-33">
                    <span className="text-wrapper-311">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                  </p>
                </div>
              )}

              {screenWidth >= 1200 && (
                <>
                  <LogoWhite30 className="logo-white-20" />
                  <div className="flexcontainer-34">
                    <p className="text-i-34">
                      <span className="text-wrapper-312">
                        千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                      </span>
                    </p>
                    <p className="text-i-34">
                      <span className="text-wrapper-312">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              className="frame-261"
              style={{
                alignSelf: screenWidth < 600 ? "stretch" : undefined,
                display:
                  screenWidth < 600
                    ? "flex"
                    : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                    ? "inline-flex"
                    : undefined,
                gap:
                  screenWidth < 600
                    ? "40px"
                    : screenWidth >= 600 && screenWidth < 1200
                    ? "30px"
                    : screenWidth >= 1200
                    ? "60px"
                    : undefined,
                width: screenWidth < 600 ? "100%" : undefined,
              }}
            >
              <div
                className="frame-262"
                style={{
                  gap:
                    (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                      ? "30px"
                      : screenWidth >= 1200
                      ? "40px"
                      : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <div
                  className="frame-263"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-313"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/philosophy-320"
                  >
                    理念
                  </Link>
                  <div
                    className="text-wrapper-314"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 企業理念
                  </div>
                  <div
                    className="text-wrapper-315"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 経営理念
                  </div>
                  <div
                    className="text-wrapper-316"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    {" "}
                    - 社訓
                  </div>
                </div>
                <div
                  className="frame-264"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-317"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/profile-600"
                  >
                    会社概要
                  </Link>
                  <div
                    className="text-wrapper-318"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 代表挨拶
                  </div>
                  <div
                    className="text-wrapper-319"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 概要
                  </div>
                </div>
                <div
                  className="frame-265"
                  style={{
                    gap:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "4px"
                        : screenWidth >= 1200
                        ? "6px"
                        : undefined,
                  }}
                >
                  <Link
                    className="text-wrapper-320"
                    style={{
                      fontFamily:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 1200
                          ? "15px"
                          : undefined,
                      fontStyle:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-style)"
                          : undefined,
                      fontWeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200
                          ? "700"
                          : undefined,
                      letterSpacing:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 1200
                          ? "0.75px"
                          : undefined,
                      lineHeight:
                        (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200
                          ? "20px"
                          : undefined,
                    }}
                    to="/aboutcontents-744"
                  >
                    制作について
                  </Link>
                  <div
                    className="text-wrapper-321"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - 業務内容
                  </div>
                  <div
                    className="text-wrapper-322"
                    style={{
                      fontFamily:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-family)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "'Noto Sans JP', Helvetica"
                          : undefined,
                      fontSize:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-size)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "11px"
                          : screenWidth >= 1200
                          ? "13px"
                          : undefined,
                      fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                      fontWeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-font-weight)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "700"
                          : undefined,
                      letterSpacing:
                        screenWidth < 600
                          ? "var(--text-bold-mini-letter-spacing)"
                          : screenWidth >= 600 && screenWidth < 1200
                          ? "0.55px"
                          : screenWidth >= 1200
                          ? "0.65px"
                          : undefined,
                      lineHeight:
                        screenWidth < 600
                          ? "var(--text-bold-mini-line-height)"
                          : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                          ? "20px"
                          : undefined,
                    }}
                  >
                    - ご相談の流れ
                  </div>
                </div>
              </div>
              <div
                className="frame-266"
                style={{
                  alignSelf: (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600 ? "stretch" : undefined,
                  display:
                    screenWidth < 600
                      ? "flex"
                      : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                      ? "inline-flex"
                      : undefined,
                  flex:
                    screenWidth < 600
                      ? "1"
                      : screenWidth >= 1200 || (screenWidth >= 600 && screenWidth < 1200)
                      ? "0 0 auto"
                      : undefined,
                  flexGrow: screenWidth < 600 ? "1" : undefined,
                  padding: screenWidth >= 1200 ? "0px 20px" : undefined,
                }}
              >
                <Link
                  className="text-wrapper-323"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/blogs-320"
                >
                  ブログ
                </Link>
                <Link
                  className="text-wrapper-324"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/recruits-320"
                >
                  採用情報
                </Link>
                <Link
                  className="text-wrapper-325"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/contact-1200"
                >
                  よくある質問
                </Link>
                <Link
                  className="text-wrapper-326"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/information-security-basic-policy-320"
                >
                  情報セキュリティ基本方針
                </Link>
                <Link
                  className="text-wrapper-327"
                  style={{
                    fontFamily:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-family)"
                        : screenWidth >= 1200
                        ? "'Noto Sans JP', Helvetica"
                        : undefined,
                    fontSize:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-size)"
                        : screenWidth >= 1200
                        ? "15px"
                        : undefined,
                    fontStyle:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-style)"
                        : undefined,
                    fontWeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-font-weight)"
                        : screenWidth >= 1200
                        ? "700"
                        : undefined,
                    letterSpacing:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-letter-spacing)"
                        : screenWidth >= 1200
                        ? "0.75px"
                        : undefined,
                    lineHeight:
                      (screenWidth >= 600 && screenWidth < 1200) || screenWidth < 600
                        ? "var(--text-bold-mini-line-height)"
                        : screenWidth >= 1200
                        ? "20px"
                        : undefined,
                  }}
                  to="/privacypolicy-320"
                >
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-328">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
