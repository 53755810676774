import React from "react";
import { Link } from "react-router-dom";
import { ColorWhite } from "../../components/ColorWhite";
import { ElementWrapper } from "../../components/ElementWrapper";
import { Heading } from "../../components/Heading";
import { ImgWrapper } from "../../components/ImgWrapper";
import { LineWrapper } from "../../components/LineWrapper";
import { LogoWhite } from "../../components/LogoWhite";
import "./style.css";

export const PhilosophyScreen = () => {
  return (
    <div className="philosophy-screen">
      <ColorWhite className="header-44" logoLogoClassName="header-45" />
      <div className="philosophy-2">
        <div className="midashi-13">
          <ElementWrapper
            className="heading-54"
            headingDivClassName="heading-55"
            headingDivClassNameOverride="heading-56"
            headingText="PHILOSOPHY"
            headingText1="理念"
            one="one"
          />
          <div className="breadcrumbs-14">
            <div className="frame-416">
              <Link className="text-wrapper-522" to="/home-744">
                TOP
              </Link>
              <ImgWrapper className="component-10930-9" img="/img/28.svg" />
              <div className="text-wrapper-523">理念</div>
            </div>
          </div>
        </div>
        <div className="corporate-2">
          <div className="frame-417">
            <Heading
              className="heading-57"
              divClassName="heading-58"
              divClassNameOverride="heading-59"
              one="one"
              text="CORPORATE PHILOSOPHY"
              text1="企業理念"
            />
            <div className="flexcontainer-57">
              <p className="text-36">
                <span className="text-wrapper-524">
                  ITテクノロジーの本来の役割は、
                  <br />
                </span>
              </p>
              <p className="text-36">
                <span className="text-wrapper-524">
                  人々の暮らしの格差を無くすこと。
                  <br />
                </span>
              </p>
              <p className="text-36">
                <span className="text-wrapper-524">
                  私たちデジタルパズルはDX化の支援をすることで、
                  <br />
                </span>
              </p>
              <p className="text-36">
                <span className="text-wrapper-524">全ての人が快適に暮らせる社会創成を目指します。</span>
              </p>
            </div>
            <div className="flexcontainer-58">
              <p className="text-37">
                <span className="text-wrapper-525">
                  世界中の全ての人たちがストレスなくITを使いこなし、
                  <br />
                </span>
              </p>
              <p className="text-37">
                <span className="text-wrapper-525">
                  快適に暮らせる世の中にするため、足りないピースを探し繋げていくのが
                  <br />
                </span>
              </p>
              <p className="text-37">
                <span className="text-wrapper-525">デジタルパズルの役目と考えています。</span>
              </p>
            </div>
          </div>
        </div>
        <div className="management-2">
          <Heading
            className="heading-57"
            divClassName="heading-58"
            divClassNameOverride="heading-60"
            one="one"
            text="MANAGEMENT PHILOSOPHY"
            text1="経営理念"
          />
          <div className="text-wrapper-526">晴耕雨読に生きる</div>
          <div className="flexcontainer-58">
            <p className="text-38">
              <span className="text-wrapper-525">
                状況や環境に合わせてのびのびと心穏やかに業務を遂行することが、
                <br />
              </span>
            </p>
            <p className="text-38">
              <span className="text-wrapper-525">結果として正しい判断を生み、ひいては成功につながる。</span>
            </p>
          </div>
          <img className="ill-2" alt="Ill" src="/img/ill-03-1.png" />
        </div>
        <div className="motto-2">
          <Heading
            className="heading-57"
            divClassName="heading-61"
            divClassNameOverride="heading-62"
            one="one"
            text="MOTTO"
            text1="社訓"
          />
          <div className="mottos-2">
            <div className="frame-418">
              <div className="text-wrapper-527">不平不満は互いに伝え合い調整できる</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                厳重なルールを作らなくとも社員が不満を一人で抱えず率直に提案・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-418">
              <div className="text-wrapper-529">謙虚であること</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                謙虚でないと得られない学びは世の中にたくさんある。たとえ相手が子供だろうが赤ちゃんだろうが、何事からでも学ぶという姿勢が大事。・修正ができる、レベルの高い組織作りを目指そう。
              </div>
            </div>
            <div className="frame-418">
              <div className="text-wrapper-529">大事な判断が必要な時こそ睡眠をしっかりとること</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                時間を惜しんで徹夜で対応しても決して良い結果にはならない。大事な判断をする時はしっかり睡眠をとって適切な判断ができる状態で対応するべきである。
              </div>
            </div>
            <div className="frame-418">
              <div className="text-wrapper-529">健康第一</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                正しい判断をするためには心身ともに健康であること。人として根本的に大切にするべきことを忘れないで欲しい。
              </div>
            </div>
            <div className="frame-418">
              <div className="text-wrapper-529">隣人を大切にすること</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                隣にいるからこそ気づいてあげられることがある。目の届く範囲にいる人に対して思いやりを持って接することでお互いが幸せになれる。
              </div>
            </div>
            <div className="frame-418">
              <div className="text-wrapper-529">無知は罪</div>
              <LineWrapper className="line-2-instance" line="/img/line-2-7.svg" lineClassName="line-2-instance" />
              <div className="text-wrapper-528">
                学問に限らず、世の中のあらゆる情報を積極的に得ておくことが非常に重要。自分には関係ないことと思い込み、見過ごしてしまうとお客様だけでなく自分自身をも守れなくなる。
              </div>
            </div>
          </div>
        </div>
        <div className="CONTACT-34">
          <div className="CONTACT-35">
            <div className="frame-419">
              <div className="group-74">
                <div className="overlap-group-18">
                  <div className="text-wrapper-530">お問い合わせ</div>
                  <div className="text-wrapper-531">CONTACT</div>
                </div>
              </div>
              <div className="group-75">
                <div className="flexcontainer-59">
                  <p className="text-i-56">
                    <span className="text-wrapper-532">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-56">
                    <span className="text-wrapper-532">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-20">
          <div className="frame-420">
            <div className="frame-421">
              <LogoWhite logoWhite="/img/logo-white-25.svg" logoWhiteClassName="logo-white-23" />
              <div className="flexcontainer-60">
                <p className="text-i-57">
                  <span className="text-wrapper-525">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-57">
                  <span className="text-wrapper-525">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-422">
              <div className="frame-421">
                <div className="frame-423">
                  <div className="text-wrapper-533">理念</div>
                  <div className="text-wrapper-534">- 企業理念</div>
                  <div className="text-wrapper-534"> - 経営理念</div>
                  <div className="text-wrapper-534"> - 社訓</div>
                </div>
                <div className="frame-423">
                  <div className="text-wrapper-533">会社概要</div>
                  <div className="text-wrapper-534">- 代表挨拶</div>
                  <div className="text-wrapper-534">- 概要</div>
                </div>
                <div className="frame-423">
                  <div className="text-wrapper-533">制作について</div>
                  <div className="text-wrapper-534">- 業務内容</div>
                  <div className="text-wrapper-534">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-421">
                <div className="text-wrapper-533">ブログ</div>
                <div className="text-wrapper-534">採用情報</div>
                <Link className="text-wrapper-535" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-534">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-535" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-536">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
