import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { ColorTransparent } from "../../components/ColorTransparent";
import { Frame } from "../../components/Frame";
import { Heading } from "../../components/Heading";
import { LeadIll } from "../../components/LeadIll";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Process } from "../../components/Process";
import { Noimage } from "../../icons/Noimage";
import "./style.css";

export const HomeScreen = () => {
  return (
    <div className="home-screen">
      <ColorTransparent className="z" groupClassName="z-2" />
      <div className="home-2">
        <div className="FV-wrapper">
          <img className="FV-3" alt="Fv" src="/img/fv-2.png" />
        </div>
        <div className="LEAD-3">
          <LeadIll className="lead-ill-2" />
          <div className="lead-text-3">
            <div className="flexcontainer-47">
              <p className="text-32">
                <span className="text-wrapper-449">
                  経験値の高い一人一人が、
                  <br />
                </span>
              </p>
              <p className="text-32">
                <span className="text-wrapper-449">パズルのように組み合わさった集合知</span>
              </p>
            </div>
            <div className="text-wrapper-450">
              単純にすべてにおいてDX化を推進するのではなく、高いスキルを持ったスタッフがそれぞれの知識や経験を基に、お客様が抱えている課題や問題の“整理整頓”のお手伝いから始めます。課題解決のために必要なピースを見極め、様々な手法をご提案しながら、チームとして力を合わせてお客様と一緒にパズルの完成図を描いていきます。
            </div>
          </div>
        </div>
        <div className="FLOW-3">
          <Heading
            className="heading-36"
            divClassName="heading-37"
            divClassNameOverride="heading-38"
            one="one"
            text="FLOW"
            text1="ご相談の流れ"
          />
          <div className="flexcontainer-48">
            <p className="text-33">
              <span className="text-wrapper-451">
                詳しいお話をお伺いした上で、御社のご要望に合わせてカスタマイズした最適なプランを
                <br />
              </span>
            </p>
            <p className="text-33">
              <span className="text-wrapper-451">
                ご提案・お見積りをさせていただきます
                <br />
              </span>
            </p>
            <p className="text-33">
              <span className="text-wrapper-451">
                （お見積りまでは費用はかかりません） まずはお問い合わせフォームまたはお電話にてご相談内容をお聞かせください。
              </span>
            </p>
          </div>
          <div className="processe-4">
            <Process
              className="process-18"
              frameClassNameOverride="process-19"
              image="/img/image-1-4.png"
              one="one"
              text="Process 01"
              text1="お問い合わせ"
            />
            <Process
              className="process-20"
              frameClassNameOverride="process-19"
              image="/img/image-1.png"
              one="one"
              text="Process 02"
              text1="オンライン面談"
            />
            <Process
              className="process-20"
              frameClassNameOverride="process-19"
              image="/img/image-1-1.png"
              one="one"
              text="Process 03"
              text1="情報資料のご提供"
            />
            <div className="process-21">
              <div className="frame-351">
                <img className="image-9" alt="Image" src="/img/image-1-2.png" />
              </div>
              <div className="frame-352">
                <div className="process-22">Process 04</div>
                <div className="flexcontainer-47">
                  <p className="text-i-48">
                    <span className="text-wrapper-452">
                      提案書・見積書
                      <br />
                    </span>
                  </p>
                  <p className="text-i-48">
                    <span className="text-wrapper-452">の提示</span>
                  </p>
                </div>
              </div>
            </div>
            <Process
              className="process-20"
              frameClassNameOverride="process-19"
              image="/img/image-1-3.png"
              one="one"
              text="Process 05"
              text1="契約締結・着手"
            />
          </div>
          <Button
            arrow38StyleOverrideClassName="button-18"
            className="button-17"
            color="one"
            device="PC"
            text="詳しく見る"
          />
        </div>
        <div className="BLOGS-3">
          <Heading className="heading-36" divClassNameOverride="heading-39" one="one" text="BLOG" text1="ブログ" />
          <div className="div-26">
            <div className="frame-353">
              <div className="frame-354">
                <div className="frame-355">
                  <NoimageWrapper className="noimage-10" noimage="/img/noimage.svg" />
                </div>
                <div className="text-wrapper-453">2024/00/00</div>
                <div className="text-wrapper-454">
                  見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                </div>
                <img className="vector-3" alt="Vector" src="/img/vector-10.svg" />
                <div className="text-wrapper-455">
                  #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                </div>
              </div>
            </div>
            <div className="frame-353">
              <div className="frame-354">
                <div className="frame-355">
                  <NoimageWrapper className="noimage-10" noimage="/img/noimage-1-2.svg" />
                </div>
                <div className="text-wrapper-453">2024/00/00</div>
                <div className="text-wrapper-454">
                  見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                </div>
                <img className="vector-3" alt="Vector" src="/img/vector-11-2.svg" />
                <div className="text-wrapper-455">
                  #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                </div>
              </div>
            </div>
            <div className="frame-353">
              <div className="frame-354">
                <div className="frame-355">
                  <NoimageWrapper className="noimage-10" noimage="/img/noimage-3.svg" />
                </div>
                <div className="text-wrapper-453">2024/00/00</div>
                <div className="text-wrapper-454">
                  見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
                </div>
                <img className="vector-3" alt="Vector" src="/img/vector-12.svg" />
                <div className="text-wrapper-455">
                  #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
                </div>
              </div>
            </div>
          </div>
          <Button
            arrow38StyleOverrideClassName="button-18"
            className="button-17"
            color="one"
            device="PC"
            text="ブログ一覧へ"
          />
        </div>
        <div className="RECRUITES-3">
          <Heading
            className="heading-36"
            divClassName="heading-40"
            divClassNameOverride="heading-41"
            one="one"
            text="RECRUIT"
            text1="採用情報"
          />
          <div className="flexcontainer-48">
            <p className="text-33">
              <span className="text-wrapper-451">
                経験を積み、幅広い対応ができるエンジニアになりたいという方。
                <br />
              </span>
            </p>
            <p className="text-33">
              <span className="text-wrapper-451">
                {" "}
                自分の能力を発揮できる場を求めている方。デジタルパズルでは一緒に働く仲間を募集しています。
              </span>
            </p>
          </div>
          <div className="div-26">
            <div className="frame-356">
              <div className="frame-355">
                <Noimage className="noimage-13" />
              </div>
              <div className="frame-357">
                <Frame className="frame-15-instance" divClassName="frame-358" one="one" />
                <div className="frame-359">
                  <div className="text-wrapper-456">2024/00/00</div>
                </div>
              </div>
              <img className="vector-3" alt="Vector" src="/img/vector-13.svg" />
              <div className="text-wrapper-454">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </div>
            <div className="frame-356">
              <div className="frame-355">
                <Noimage className="noimage-13" />
              </div>
              <div className="frame-357">
                <Frame className="frame-15-instance" divClassName="frame-358" one="one" />
                <div className="frame-359">
                  <div className="text-wrapper-456">2024/00/00</div>
                </div>
              </div>
              <img className="vector-3" alt="Vector" src="/img/vector-13.svg" />
              <div className="text-wrapper-454">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </div>
            <div className="frame-356">
              <div className="frame-355">
                <Noimage className="noimage-13" />
              </div>
              <div className="frame-357">
                <Frame className="frame-15-instance" divClassName="frame-358" one="one" />
                <div className="frame-359">
                  <div className="text-wrapper-456">2024/00/00</div>
                </div>
              </div>
              <img className="vector-3" alt="Vector" src="/img/vector-13.svg" />
              <div className="text-wrapper-454">
                見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
              </div>
            </div>
          </div>
          <Button
            arrow38StyleOverrideClassName="button-18"
            className="button-17"
            color="one"
            device="PC"
            text="採用一覧へ"
            to="/recruits-744"
          />
        </div>
        <div className="ABOUTUS-3">
          <Heading
            className="heading-36"
            divClassName="heading-42"
            divClassNameOverride="heading-43"
            one="one"
            text="ABOUT US"
            text1="企業情報"
          />
          <Link className="frame-360" to="/philosophy-744">
            <div className="white-button">
              <div className="frame-361">
                <div className="text-wrapper-457">企業理念</div>
              </div>
            </div>
            <div className="white-button">
              <div className="frame-361">
                <div className="text-wrapper-457">経営理念</div>
              </div>
            </div>
          </Link>
        </div>
        <div className="CONTACT-28">
          <div className="CONTACT-29">
            <div className="frame-362">
              <div className="group-60">
                <div className="overlap-group-15">
                  <div className="text-wrapper-458">お問い合わせ</div>
                  <div className="text-wrapper-459">CONTACT</div>
                </div>
              </div>
              <div className="group-61">
                <div className="flexcontainer-49">
                  <p className="text-i-49">
                    <span className="text-wrapper-460">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-49">
                    <span className="text-wrapper-460">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-16">
          <div className="frame-363">
            <div className="frame-364">
              <LogoWhite logoWhite="/img/logo-white-4-3.svg" logoWhiteClassName="logo-white-11" />
              <div className="flexcontainer-48">
                <p className="text-i-50">
                  <span className="text-wrapper-461">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-50">
                  <span className="text-wrapper-461">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-365">
              <div className="frame-364">
                <div className="frame-366">
                  <div className="text-wrapper-462">理念</div>
                  <div className="text-wrapper-463">- 企業理念</div>
                  <div className="text-wrapper-463"> - 経営理念</div>
                  <div className="text-wrapper-463"> - 社訓</div>
                </div>
                <div className="frame-366">
                  <div className="text-wrapper-462">会社概要</div>
                  <div className="text-wrapper-463">- 代表挨拶</div>
                  <div className="text-wrapper-463">- 概要</div>
                </div>
                <div className="frame-366">
                  <div className="text-wrapper-462">制作について</div>
                  <div className="text-wrapper-463">- 業務内容</div>
                  <div className="text-wrapper-463">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-364">
                <div className="text-wrapper-462">ブログ</div>
                <div className="text-wrapper-463">採用情報</div>
                <Link className="text-wrapper-464" to="/contact-1200">
                  よくある質問
                </Link>
                <div className="text-wrapper-463">情報セキュリティ基本方針</div>
                <Link className="text-wrapper-464" to="/privacypolicy-1200">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-465">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
