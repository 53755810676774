/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Polygon = ({ one, className, element = "/img/1-1.svg" }) => {
  return (
    <img
      className={`polygon one-10-${one} ${className}`}
      alt="Element"
      src={
        one === "two"
          ? "/img/1-2.svg"
          : one === "dark-blue"
          ? "/img/1-dark-blue.svg"
          : one === "white"
          ? "/img/1-white.svg"
          : element
      }
    />
  );
};

Polygon.propTypes = {
  one: PropTypes.oneOf(["two", "dark-blue", "white", "one"]),
  element: PropTypes.string,
};
