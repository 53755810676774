/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Noimage } from "../../icons/Noimage";
import "./style.css";

export const Blog = ({
  one,
  className,
  frameClassName,
  frameClassNameOverride,
  divClassName,
  vector = "/img/vector-1.svg",
  divClassNameOverride,
  icon = <Noimage className="noimage-53" />,
  to,
}) => {
  return (
    <Link className={`BLOG ${className}`} to={to}>
      <div className={`frame-15 ${frameClassName}`}>
        <div className={`frame-16 ${frameClassNameOverride}`}>{icon}</div>
        <div className="frame-17">
          <div className="text-wrapper-11">2024/00/00</div>
          <div className={`text-wrapper-12 ${divClassName}`}>
            見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
          </div>
          <img className="vector" alt="Vector" src={vector} />
          <div className="frame-18">
            <div className={`text-wrapper-13 ${divClassNameOverride}`}>
              #あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお　　#あいうえお
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

Blog.propTypes = {
  one: PropTypes.oneOf(["one"]),
  vector: PropTypes.string,
  to: PropTypes.string,
};
