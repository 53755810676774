/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Line } from "../../icons/Line";
import "./style.css";

export const Heading02 = ({ one, className, text = "行動指針", icon = <Line className="line-8" /> }) => {
  return (
    <div className={`heading-02 ${className}`}>
      <div className={`text-wrapper-23 one-12-${one}`}>{text}</div>
      {icon}
    </div>
  );
};

Heading02.propTypes = {
  one: PropTypes.oneOf(["SP", "one"]),
  text: PropTypes.string,
};
