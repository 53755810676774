import React from "react";
import { Link } from "react-router-dom";
import { ElementWrapper } from "../../components/ElementWrapper";
import { ImgWrapper } from "../../components/ImgWrapper";
import { Line2 } from "../../components/Line2";
import { LogoWhite } from "../../components/LogoWhite";
import { SpWhite } from "../../components/SpWhite";
import { Menu3 } from "../../icons/Menu3";
import "./style.css";

export const PrivacypolicyScreen = () => {
  return (
    <div className="privacypolicy-screen">
      <SpWhite className="header-50" groupClassName="header-51" icon={<Menu3 className="menu-16" />} />
      <div className="privacypolicy-2">
        <div className="midashi-16">
          <ElementWrapper
            className="heading-70"
            headingDivClassName="heading-71"
            headingDivClassNameOverride="heading-72"
            headingText="PRIVACY POLICY"
            headingText1="プライバシーポリシー"
            one="two"
          />
          <div className="breadcrumbs-17">
            <div className="frame-447">
              <Link className="text-wrapper-571" to="/home-320">
                TOP
              </Link>
              <ImgWrapper className="component-10930-12" img="/img/36.svg" />
              <div className="text-wrapper-572">プライバシーポリシー</div>
            </div>
          </div>
        </div>
        <div className="privacypolicys-2">
          <div className="frame-448">
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">お客様から取得する情報</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-5.svg" />
              </div>
              <div className="div-30">当社は、お客様から以下の情報を取得します。</div>
              <div className="frame-450">
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">氏名(ニックネームやペンネームも含む)</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">メールアドレス、開発、運用、保守</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">電話番号</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">住所</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">Cookie(クッキー)を用いて生成された識別情報</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">
                    当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">お客様の情報を利用する目的</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-6.svg" />
              </div>
              <div className="div-30">当社は、お客様から取得した情報を、以下の目的のために利用します。</div>
              <div className="frame-450">
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">当社サービスに関する登録の受付、お客様の本人確認、認証のため</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">お客様の当社サービスの利用履歴を管理するため</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">
                    当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため
                  </div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">お客様からのお問い合わせに対応するため</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">当社の規約や法令に違反する行為に対応するため</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">当社サービスの変更、提供中止、終了、契約解除をご連絡するため</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">当社規約の変更等を通知するため</div>
                </div>
              </div>
              <div className="flexcontainer-65">
                <p className="div-30">
                  <span className="text-wrapper-575">
                    以上の他、当社サービスの提供、維持、保護及び改善のため
                    <br />
                  </span>
                </p>
                <p className="div-30">
                  <span className="text-wrapper-575">広告の配信、表示及び効果測定のため</span>
                </p>
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">安全管理のために講じた措置</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-7.svg" />
              </div>
              <div className="div-30">
                当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">第三者提供</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-7.svg" />
              </div>
              <div className="div-30">
                当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第１６条第３項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。
                但し、次の場合は除きます。
              </div>
              <div className="frame-450">
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">個人データの取扱いを外部に委託する場合</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">当社や当社サービスが買収された場合</div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">
                    事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を別途公表します。）
                  </div>
                </div>
                <div className="bulletpoint-4">
                  <div className="group-82">
                    <div className="ellipse-8" />
                  </div>
                  <div className="text-wrapper-574">その他、法律によって合法的に第三者提供が許されている場合</div>
                </div>
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">アクセス解析ツール</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-9.svg" />
              </div>
              <div className="flexcontainer-65">
                <p className="text-39">
                  <span className="text-wrapper-576">
                    当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。
                    <br />
                  </span>
                </p>
                <p className="text-39">
                  <span className="text-wrapper-577">Google アナリティクス利用規約</span>
                </p>
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">プライバシーポリシーの変更</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-9.svg" />
              </div>
              <div className="div-30">
                当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。
              </div>
            </div>
            <div className="frame-449">
              <div className="heading-73">
                <div className="text-wrapper-573">お問い合わせ</div>
                <img className="line-pg-2" alt="Line pg" src="/img/line-pg-9.svg" />
              </div>
              <div className="flexcontainer-65">
                <p className="div-30">
                  <span className="text-wrapper-575">
                    お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のメールアドレスにご連絡ください。
                    <br />
                  </span>
                </p>
                <p className="div-30">
                  <span className="text-wrapper-575">
                    privacy＠disitalpuzzleco.jp
                    <br />
                  </span>
                </p>
                <p className="div-30">
                  <span className="text-wrapper-575">
                    この場合、必ず、運転免許証のご提示等当社が指定する方法により、ご本人からのご請求であることの確認をさせていただきます。
                  </span>
                </p>
              </div>
            </div>
            <div className="frame-451">
              <div className="flexcontainer-65">
                <p className="text-40">
                  <span className="text-wrapper-578">
                    事業者の名称
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-579">
                    デジタルパズル株式会社
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-579">
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-578">
                    代表者氏名
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-579">
                    堀川まゆ美
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-579">
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-578">
                    事業者の住所
                    <br />
                  </span>
                </p>
                <p className="text-40">
                  <span className="text-wrapper-579">
                    千葉県千葉市美浜区若葉３丁目１番１８号　幕張ベイパーク　クロスレジデンスＳ２－１
                  </span>
                </p>
              </div>
            </div>
            <Line2 line="/img/line-2.svg" />
            <div className="text-wrapper-580">2024年02月13日 制定</div>
          </div>
        </div>
        <div className="CONTACT-40">
          <div className="CONTACT-41">
            <div className="frame-452">
              <div className="group-83">
                <div className="overlap-group-21">
                  <div className="text-wrapper-581">お問い合わせ</div>
                  <div className="text-wrapper-582">CONTACT</div>
                </div>
              </div>
              <div className="group-84">
                <div className="flexcontainer-66">
                  <p className="text-i-62">
                    <span className="text-wrapper-575">
                      制作のご依頼・ご相談・ご質問など
                      <br />
                    </span>
                  </p>
                  <p className="text-i-62">
                    <span className="text-wrapper-575">お気軽にお問い合わせください。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-23">
          <div className="frame-453">
            <div className="frame-449">
              <LogoWhite logoWhite="/img/logo-white-33.svg" logoWhiteClassName="logo-white-32" to="/home-320" />
              <div className="flexcontainer-67">
                <p className="text-i-63">
                  <span className="text-wrapper-583">
                    千葉県千葉市美浜区若葉３丁目１番１８号 <br />
                  </span>
                </p>
                <p className="text-i-63">
                  <span className="text-wrapper-583">幕張ベイパーク　クロスレジデンスＳ２－１</span>
                </p>
              </div>
            </div>
            <div className="frame-454">
              <div className="frame-455">
                <div className="frame-456">
                  <Link className="text-wrapper-584" to="/philosophy-320">
                    理念
                  </Link>
                  <div className="text-wrapper-585">- 企業理念</div>
                  <div className="text-wrapper-585"> - 経営理念</div>
                  <div className="text-wrapper-585"> - 社訓</div>
                </div>
                <div className="frame-456">
                  <div className="text-wrapper-586">会社概要</div>
                  <div className="text-wrapper-585">- 代表挨拶</div>
                  <div className="text-wrapper-585">- 概要</div>
                </div>
                <div className="frame-456">
                  <div className="text-wrapper-586">制作について</div>
                  <div className="text-wrapper-585">- 業務内容</div>
                  <div className="text-wrapper-585">- ご相談の流れ</div>
                </div>
              </div>
              <div className="frame-455">
                <Link className="text-wrapper-584" to="/blogs-320">
                  ブログ
                </Link>
                <Link className="text-wrapper-587" to="/recruits-320">
                  採用情報
                </Link>
                <div className="text-wrapper-585">よくある質問</div>
                <Link className="text-wrapper-587" to="/information-security-basic-policy-320">
                  情報セキュリティ基本方針
                </Link>
                <Link className="text-wrapper-587" to="/privacypolicy-320">
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
          <div className="text-wrapper-588">©︎ Digital Puzzle Inc</div>
        </footer>
      </div>
    </div>
  );
};
